import { useState, useEffect } from 'react';
import { useQuery, useMutation, useSubscription } from '@apollo/client';
import moment from 'moment-timezone';
import { toast, ToastOptions } from 'react-semantic-toasts';
import { humanizeText } from '@bluefox/lib/humanize';
import {
  BillingInvoicesQuery,
  SaveInvoiceStatusMutation,
  InvoiceStatusSubscription,
  GetLastInvoiceQuery,
} from '@graphql/billing';
import {
  BillingInvoice,
  invoiceStatusMenuOptions,
  invoiceStatusOptions,
  InvoiceStatus,
} from '@bluefox/models/Billing';
import { RegenerateInvoiceMutation } from '@bluefox/graphql/billing';
import {
  Card,
  Icon,
  Container,
  Menu,
  Placeholder,
  Segment,
  Message,
  Button,
  Table,
  Label,
  Popup,
  Dropdown,
  Pagination,
  Confirm,
  Header,
} from 'semantic-ui-react';
import MainLayout from '@ui/MainLayout';
import DateTimePicker from '@bluefox/ui/DateTimePicker';
import InvoicesExcelExportModal from '@ui/InvoicesExcelExportModal';
import InvoicesListEditModal from '@ui/Billing/InvoicesListEditModal';
import Invoicemodal from '@ui/BillingReport/InvoiceModal';
import InvoicePaymentDateModal from '@ui/Billing/InvoicePaymentDateModal';
import InvoiceDescriptionModal from '@ui/Billing/InvoiceDescriptionModal';
import InvoiceCptCodesModal from '@ui/Billing/InvoiceCptCodesModal';
import { DateFormats } from '@bluefox/models/Dates';

interface PracticeOption {
  text: string;
  value: string;
}

interface LastInvoiceData {
  invoices: BillingInvoice[];
}

interface InvoicesData {
  aggregating: {
    aggregate: {
      count: number;
    };
  };
  invoices: BillingInvoice[];
  allPractices: [
    {
      id: string;
      name: string;
    },
  ];
}

const ENTRIES_PER_PAGE = 15;

const InvoicesListScreen = () => {
  const [page, setPage] = useState(0);
  const [criteria, setCriteria] = useState({});
  const [searchPractice, setSearchPractice] = useState<string>();
  const [searchStatus, setSearchStatus] = useState<string>();
  const [searchBillingMethod, setSearchBillingMethod] = useState<string>();
  const [searchFromDate, setSearchFromDate] = useState<Date | null | undefined>(
    null
  );
  const [searchToDate, setSearchToDate] = useState<Date | null | undefined>(
    null
  );
  const [searchFromPaymentDate, setSearchFromPaymentDate] = useState<
    Date | null | undefined
  >(null);
  const [searchToPaymentDate, setSearchToPaymentDate] = useState<
    Date | null | undefined
  >(null);

  const [practiceOptions, setPracticeOptions] = useState<PracticeOption[]>([]);

  const [selectedInvoice, setSelectedInvoice] = useState<BillingInvoice>();
  const [openPaymentDateModal, setOpenPaymentDateModal] = useState(false);
  const [openDescriptionModal, setOpenDescriptionModal] = useState(false);
  const [openInvoiceEditModal, setOpenInvoiceEditModal] = useState(false);
  const [openPdfModal, setOpenPdfModal] = useState(false);
  const [openCptCodesModal, setOpenCptCodesModal] = useState(false);

  const [invoicesStatusData, setInvoiceStatusData] = useState<
    string | undefined
  >();

  const handleOnStatusChange = (invoiceStatus: string) => {
    setInvoiceStatusData(invoiceStatus);
  };

  const { data, error, loading, refetch } = useQuery<InvoicesData>(
    BillingInvoicesQuery,
    {
      variables: {
        criteria,
        limit: ENTRIES_PER_PAGE,
        offset: !!page ? ENTRIES_PER_PAGE * (page - 1) : null,
      },
    }
  );

  const total = data?.aggregating.aggregate.count || 0;
  const totalPages = Math.ceil(total / ENTRIES_PER_PAGE);

  const handlePracticeValue = (value: string) => {
    const practiceValue = !!value && value !== 'all' ? value : undefined;
    setSearchPractice(practiceValue);
  };

  const handleStatusValue = (value: string) => {
    const statusValue = !!value && value !== 'all' ? value : undefined;
    setSearchStatus(statusValue);
  };

  const handleBillingMethodValue = (value: string) => {
    const billingMethodValue = !!value && value !== 'all' ? value : undefined;
    setSearchBillingMethod(billingMethodValue);
  };

  const closeModal = () => {
    setSelectedInvoice(undefined);
    setOpenPaymentDateModal(false);
    setOpenDescriptionModal(false);
    setOpenCptCodesModal(false);
    setInvoiceStatusData(undefined);
  };

  const handleSelectedInvoice = (invoice: BillingInvoice) => {
    setSelectedInvoice(invoice);
  };

  useEffect(() => {
    if (!data?.allPractices || data.allPractices.length < 1) return;

    setPracticeOptions(
      data.allPractices.map((p) => {
        return {
          text: p.name,
          value: p.id,
        };
      })
    );
  }, [data]);

  useEffect(() => {
    let _criteria = {};

    _criteria = {
      _and: [
        {
          ...(searchPractice
            ? { practice: { id: { _eq: searchPractice } } }
            : {}),
        },
        {
          ...(searchStatus ? { status: { _eq: searchStatus } } : {}),
        },
        {
          ...(searchBillingMethod
            ? { billMethod: { _eq: searchBillingMethod } }
            : {}),
        },
        {
          ...(searchFromDate || searchToDate
            ? {
                date: {
                  _gte: searchFromDate,
                  _lte: searchToDate ?? new Date(),
                },
              }
            : {}),
        },
        {
          ...(searchFromPaymentDate || searchToPaymentDate
            ? {
                paymentDate: {
                  _gte: searchFromPaymentDate,
                  _lte: searchToPaymentDate ?? moment().add(1, 'year'),
                },
              }
            : {}),
        },
      ],
    };

    setPage(0);
    setCriteria(_criteria);
  }, [
    searchPractice,
    searchStatus,
    searchBillingMethod,
    searchFromDate,
    searchToDate,
    searchFromPaymentDate,
    searchToPaymentDate,
  ]);

  return (
    <MainLayout
      path={[{ text: 'Billing', to: '/billing' }, { text: 'Invoices List' }]}
    >
      <Container>
        <Card fluid style={{ marginTop: '1rem' }}>
          <Card.Content>
            <Card.Header
              as={'h3'}
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <div>
                <Icon
                  name="file alternate outline"
                  style={{ marginRight: '0.6rem' }}
                />
                Invoices List
              </div>
              <InvoicesExcelExportModal criteria={criteria} />
            </Card.Header>
            <Card.Description>
              <Menu borderless style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Menu.Menu style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <Menu.Item>
                    <Dropdown
                      style={{ minWidth: '15rem' }}
                      placeholder="Filter by practice"
                      fluid
                      selection
                      search
                      onChange={(e, data) => {
                        handlePracticeValue(data.value as string);
                      }}
                      options={[
                        { key: 'all', text: 'All', value: 'all' },
                        ...practiceOptions,
                      ]}
                    />
                  </Menu.Item>
                  <Menu.Item>
                    <Dropdown
                      style={{ minWidth: '15rem' }}
                      placeholder="Filter by status"
                      fluid
                      selection
                      onChange={(e, data) => {
                        handleStatusValue(data.value as string);
                      }}
                      options={[
                        { key: 'all', text: 'All', value: 'all' },
                        ...invoiceStatusMenuOptions,
                      ]}
                    />
                  </Menu.Item>
                  <Menu.Item>
                    <Dropdown
                      style={{ minWidth: '15rem' }}
                      placeholder="Filter by billing method"
                      fluid
                      selection
                      onChange={(e, data) => {
                        handleBillingMethodValue(data.value as string);
                      }}
                      options={[
                        { key: 'all', text: 'All', value: 'all' },
                        { key: 'auto', text: 'Auto', value: 'auto' },
                        { key: 'manual', text: 'Manual', value: 'manual' },
                      ]}
                    />
                  </Menu.Item>
                </Menu.Menu>
                <Menu.Menu style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <Menu.Item>
                    <label style={{ width: '15rem' }}>
                      <b>Filter by date range:</b>
                    </label>
                    <DateTimePicker
                      forFilter
                      placeholderText="From"
                      selected={searchFromDate}
                      onChange={(d) => {
                        setSearchFromDate(d ? (d as Date) : undefined);
                      }}
                      onSelect={(value) =>
                        setSearchFromDate(value ? (value as Date) : undefined)
                      }
                      onClear={() => setSearchFromDate(undefined)}
                      maxDate={new Date()}
                      dateFormat={DateFormats.DATE}
                      showYearDropdown
                      showMonthDropdown
                      scrollableYearDropdown
                      dropdownMode="select"
                      isClearable
                    />
                  </Menu.Item>
                  <Menu.Item>
                    <DateTimePicker
                      forFilter
                      placeholderText="To"
                      selected={searchToDate}
                      onChange={(d) => {
                        setSearchToDate(d ? (d as Date) : undefined);
                      }}
                      onSelect={(value) =>
                        setSearchToDate(value ? (value as Date) : undefined)
                      }
                      onClear={() => setSearchToDate(undefined)}
                      maxDate={new Date()}
                      dateFormat={DateFormats.DATE}
                      showYearDropdown
                      showMonthDropdown
                      scrollableYearDropdown
                      dropdownMode="select"
                      isClearable
                    />
                  </Menu.Item>
                </Menu.Menu>
                <Menu.Menu style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <Menu.Item>
                    <label style={{ width: '15rem' }}>
                      <b>Filter by payment date range:</b>
                    </label>
                    <DateTimePicker
                      forFilter
                      placeholderText="From"
                      selected={searchFromPaymentDate}
                      onChange={(d) => {
                        setSearchFromPaymentDate(d ? (d as Date) : undefined);
                      }}
                      onSelect={(value) =>
                        setSearchFromPaymentDate(
                          value ? (value as Date) : undefined
                        )
                      }
                      onClear={() => setSearchFromPaymentDate(undefined)}
                      maxDate={new Date()}
                      dateFormat={DateFormats.DATE}
                      showYearDropdown
                      showMonthDropdown
                      scrollableYearDropdown
                      dropdownMode="select"
                      isClearable
                    />
                  </Menu.Item>
                  <Menu.Item>
                    <DateTimePicker
                      forFilter
                      placeholderText="To"
                      selected={searchToPaymentDate}
                      onChange={(d) => {
                        setSearchToPaymentDate(d ? (d as Date) : undefined);
                      }}
                      onSelect={(value) =>
                        setSearchToPaymentDate(
                          value ? (value as Date) : undefined
                        )
                      }
                      onClear={() => setSearchToPaymentDate(undefined)}
                      dateFormat={DateFormats.DATE}
                      showYearDropdown
                      showMonthDropdown
                      scrollableYearDropdown
                      dropdownMode="select"
                      isClearable
                    />
                  </Menu.Item>
                </Menu.Menu>
              </Menu>
              {error ? (
                <Message error>{error.message}</Message>
              ) : (
                <Table selectable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Practice</Table.HeaderCell>
                      <Table.HeaderCell>Invoice Date</Table.HeaderCell>
                      <Table.HeaderCell>Amount</Table.HeaderCell>
                      <Table.HeaderCell>Billing Method</Table.HeaderCell>
                      <Table.HeaderCell>Status</Table.HeaderCell>
                      <Table.HeaderCell>Error</Table.HeaderCell>
                      <Table.HeaderCell>Payment Date</Table.HeaderCell>
                      <Table.HeaderCell>due Date</Table.HeaderCell>
                      <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {loading ? (
                      <Table.Row>
                        <Table.Cell colSpan={10}>
                          <Segment basic>
                            <Placeholder fluid>
                              <Placeholder.Header>
                                <Placeholder.Line />
                                <Placeholder.Line />
                              </Placeholder.Header>
                            </Placeholder>
                          </Segment>
                        </Table.Cell>
                      </Table.Row>
                    ) : !!data?.invoices.length ? (
                      data.invoices.map((invoice) => {
                        return (
                          <InvoicesListRow
                            key={invoice.id}
                            data={invoice}
                            onSave={refetch}
                            openPaymentDatemodal={() => {
                              handleSelectedInvoice(invoice);
                              setOpenPaymentDateModal(true);
                            }}
                            openDescriptionmodal={() => {
                              handleSelectedInvoice(invoice);
                              setOpenDescriptionModal(true);
                            }}
                            openInvoiceEditModal={() => {
                              handleSelectedInvoice(invoice);
                              setOpenInvoiceEditModal(true);
                            }}
                            openPdfModal={() => {
                              handleSelectedInvoice(invoice);
                              setOpenPdfModal(true);
                            }}
                            openCptCodesModal={() => {
                              handleSelectedInvoice(invoice);
                              setOpenCptCodesModal(true);
                            }}
                            onChangeStatusData={handleOnStatusChange}
                          />
                        );
                      })
                    ) : (
                      <Table.Row>
                        <Table.Cell colSpan={10}>
                          <Message>No invoices found.</Message>
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </Table.Body>
                  <Table.Footer>
                    <Table.Row>
                      <Table.HeaderCell>Total: {total}</Table.HeaderCell>
                      <Table.HeaderCell colSpan={10} textAlign="right">
                        <Pagination
                          activePage={page || 1}
                          disabled={!total || total < ENTRIES_PER_PAGE}
                          boundaryRange={0}
                          siblingRange={1}
                          onPageChange={(e, { activePage }) =>
                            setPage(activePage as number)
                          }
                          totalPages={totalPages}
                        />
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Footer>
                </Table>
              )}
            </Card.Description>
          </Card.Content>
        </Card>
      </Container>

      {/*-----------------------------PAYMENT DATE MODAL-----------------------------*/}
      <InvoicePaymentDateModal
        open={openPaymentDateModal}
        onOpen={() => setOpenPaymentDateModal(openPaymentDateModal)}
        onClose={closeModal}
        invoice={selectedInvoice}
        onSave={refetch}
        invoiceStatus={invoicesStatusData}
      />

      {/*-----------------------------DESCRIPTION MODAL-----------------------------*/}
      <InvoiceDescriptionModal
        open={openDescriptionModal}
        onOpen={() => setOpenDescriptionModal(openDescriptionModal)}
        onClose={closeModal}
        invoice={selectedInvoice}
        onSave={refetch}
      />

      {/*-----------------------------SEND INVOICE MODAL-----------------------------*/}
      <InvoicesListEditModal
        onClose={() => {
          setSelectedInvoice(undefined);
          setOpenInvoiceEditModal(false);
        }}
        open={openInvoiceEditModal}
        invoice={selectedInvoice}
        onSave={refetch}
      />

      {/*-----------------------------INVOICE PDF MODAL-----------------------------*/}
      <Invoicemodal
        openWithoutTrigger={openPdfModal}
        invoiceId={selectedInvoice?.id}
        invoiceDate={moment(selectedInvoice?.date).format(DateFormats.DATE)}
        //claimsData={claimsData}
        //claimIds={includedClaimIds}
        //cptCodes={includedClaimCptCodes}
        onClose={() => {
          setSelectedInvoice(undefined);
          setOpenPdfModal(false);
        }}
        //pdfActive={isPdf}
      />

      {/* MODAL CPT CODES */}
      {selectedInvoice?.id && (
        <InvoiceCptCodesModal
          open={openCptCodesModal}
          invoiceId={selectedInvoice?.id}
          onClose={closeModal}
        />
      )}
    </MainLayout>
  );
};

//------------------------------------INVOICE LIST ROW------------------------------------//

interface InvoicesListRowProps {
  data: BillingInvoice;
  onSave: () => void;
  openPaymentDatemodal: (selected: boolean) => void;
  openDescriptionmodal: (selected: boolean) => void;
  openInvoiceEditModal: (selected: boolean) => void;
  openPdfModal: (selected: boolean) => void;
  openCptCodesModal: (selected: boolean) => void;
  onChangeStatusData: (invoiceStatus: string) => void;
}

const InvoicesListRow = ({
  data,
  onSave,
  openPaymentDatemodal,
  openDescriptionmodal,
  openInvoiceEditModal,
  openPdfModal,
  openCptCodesModal,
  onChangeStatusData,
}: InvoicesListRowProps) => {
  const [windowWidth, setWindowWidth] = useState(0);

  const [statusToSave, setStatusToSave] = useState<string>();
  const [showConfirm, setShowConfirm] = useState(false);
  const [openRegenerateInvoiceConfirm, setOpenRegenerateInvoiceConfirm] =
    useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [saveInvoiceStatus] = useMutation(SaveInvoiceStatusMutation);

  const { data: subscriptionData } = useSubscription(
    InvoiceStatusSubscription,
    {
      variables: {
        invoiceId: data.id,
      },
      skip: !data,
    }
  );

  const [RegenerateInvoice] = useMutation(RegenerateInvoiceMutation);

  const handleSaveInvoiceStatus = (invoiceStatus: string) => {
    saveInvoiceStatus({
      variables: {
        invoiceId: data.id,
        status: invoiceStatus,
      },
    })
      .then((r) => {
        toast({
          title: 'Status saved successfully',
          type: 'success',
          time: 1000,
        });
        onSave();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  const handleRegenerateInvoice = async () => {
    try {
      setIsLoading(true);
      const response = await RegenerateInvoice({
        variables: {
          invoiceId: data.id,
        },
      });

      const toastData: ToastOptions =
        response.data?.reGenerateInvoice.code === 200
          ? { type: 'success', title: 'Invoice regenerated successfully' }
          : { type: 'error', title: 'An error has ocurred' };

      setIsLoading(false);

      toast({
        ...toastData,
        time: toastData.type === 'success' ? 1000 : 5000,
      });
      onSave();
      setOpenRegenerateInvoiceConfirm(false);
    } catch (error) {
      toast({
        title: `Callback error: ${error}`,
        type: 'error',
        time: 5000,
      });

      setIsLoading(false);
    }
  };

  const handleOnChangeStatus = (invoiceStatus: string) => {
    setStatusToSave(invoiceStatus);

    if (invoiceStatus === InvoiceStatus.DISCARDED) {
      setShowConfirm(true);
      onSave();
      return;
    }

    if (invoiceStatus === InvoiceStatus.PAID && data.billMethod === 'manual') {
      openPaymentDatemodal(true);
      onChangeStatusData(invoiceStatus);
      return;
    }

    handleSaveInvoiceStatus(invoiceStatus);

    onSave();
  };

  useEffect(() => {
    if (!subscriptionData) return;
    onSave();
  }, [subscriptionData]);

  const getWindowWidth = () => {
    window.addEventListener('resize', () => setWindowWidth(window.innerWidth));
  };

  useEffect(() => {
    getWindowWidth();
  }, []);

  return (
    <Table.Row>
      <Table.Cell>{data.practice.name}</Table.Cell>
      <Table.Cell>{moment(data.date).format(DateFormats.DATE)}</Table.Cell>
      <Table.Cell>{`$${data.amount}`}</Table.Cell>
      <Table.Cell>
        <Label
          basic
          size="small"
          content={humanizeText(data.billMethod, { capitalize: 'first' })}
        />
      </Table.Cell>
      <Table.Cell>
        <Dropdown
          fluid
          selection
          options={invoiceStatusOptions(data.status)}
          value={subscriptionData?.invoice.status}
          onChange={(e, data) => {
            handleOnChangeStatus(data.value?.toString() || '');
          }}
          loading={!subscriptionData}
        />
      </Table.Cell>
      <Table.Cell>
        {data.status === InvoiceStatus.ERROR ||
        (data.status === InvoiceStatus.ERROR_STRIPE && data?.message?.error) ? (
          <Popup
            on="click"
            trigger={<Button size="mini" content="Show Error" />}
            position="top right"
            content={data?.message?.error}
          />
        ) : (
          '-'
        )}
      </Table.Cell>
      <Table.Cell>
        {data.paymentDate
          ? moment(data.paymentDate).format(DateFormats.DATE)
          : '-'}
      </Table.Cell>
      <Table.Cell>
        {data.dueDate ? moment(data.dueDate).format(DateFormats.DATE) : '-'}
      </Table.Cell>
      <Table.Cell>
        <Dropdown
          icon={null}
          pointing={windowWidth <= 1465 && windowWidth > 770 ? 'right' : false}
          trigger={<Button size="mini" icon="ellipsis horizontal" />}
        >
          <Dropdown.Menu>
            <Dropdown.Item
              content="Export PDF"
              icon="file pdf outline"
              onClick={() => openPdfModal(true)}
              disabled
            />
            <Dropdown.Item
              content="Edit payment date"
              icon="calendar check outline"
              onClick={() => openPaymentDatemodal(true)}
            />
            <Dropdown.Item
              content="Edit email memo"
              icon="file alternate outline"
              onClick={() => openDescriptionmodal(true)}
            />
            {data?.metadata?.invoiceUrl && (
              <Dropdown.Item
                href={data?.metadata?.invoiceUrl}
                target="_blank"
                content="Invoice URL"
                icon="linkify"
              />
            )}
            {data.billMethod === 'manual' &&
            data.metadata?.invoiceUrl &&
            (data.status === InvoiceStatus.PENDING ||
              data.status === InvoiceStatus.ERROR) ? (
              <Dropdown.Item
                content="Send"
                icon="send"
                onClick={() => {
                  openInvoiceEditModal(true);
                }}
              />
            ) : null}
            {data.status === InvoiceStatus.DISCARDED ||
            (data.status === InvoiceStatus.ERROR &&
              !data?.metadata?.invoiceUrl) ? (
              <Dropdown.Item
                content="Regenerate Invoice in Stripe"
                icon="redo"
                onClick={() => {
                  setOpenRegenerateInvoiceConfirm(true);
                }}
              />
            ) : null}
            <Dropdown.Item
              content="Show CPT Codes"
              icon="circle"
              onClick={() => {
                openCptCodesModal(true);
              }}
            />
          </Dropdown.Menu>
        </Dropdown>
      </Table.Cell>
      <Confirm
        size="tiny"
        open={showConfirm}
        onCancel={() => setShowConfirm(false)}
        onConfirm={() => {
          if (statusToSave) handleSaveInvoiceStatus(statusToSave);
          setShowConfirm(false);
        }}
        confirmButton={<Button negative content="Ok" />}
        content={
          <Header style={{ display: 'flex', alignItems: 'center' }}>
            <Icon name="warning sign" color="red" /> Setting the invoice as
            discarded will discard the invoice in Stripe and it will no longer
            be editable. Also, claims and cpt codes involved in the invoice will
            change their statuses back to "paid" and will be available for
            another NEW Invoice. Press ok to confirm.
          </Header>
        }
      />
      <Confirm
        size="mini"
        open={openRegenerateInvoiceConfirm}
        onCancel={() => setOpenRegenerateInvoiceConfirm(false)}
        onConfirm={handleRegenerateInvoice}
        content={
          <Header
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Icon name="warning sign" color="red" /> Are you sure you want to
            regenerate invoice in Stripe?
          </Header>
        }
        confirmButton={
          <Button content="Yes" disabled={isLoading} loading={isLoading} />
        }
      />
    </Table.Row>
  );
};

export default InvoicesListScreen;
