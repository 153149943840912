import { useState, useEffect, useMemo } from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';

import {
  Card,
  Icon,
  Menu,
  Message,
  Placeholder,
  Segment,
  Table,
  Container,
  Dropdown,
  Pagination,
  Input,
} from 'semantic-ui-react';

import MainLayout from '@ui/MainLayout';
import DateTimePicker from '@bluefox/ui/DateTimePicker';
import { DateFormats } from '@bluefox/models/Dates';
import { VaccinationsToReportInterface } from '@bluefox/models/integrations/Develo';
import VaccinationsToReportRow from './VaccinationsToReportRow';
import { PrescribersQuery } from '@bluefox/graphql/providers';
import { VaccineAdministratorsQuery } from '@graphql/mappings';

import {
  AllPracticesWithDevelo,
  DeveloVaccinationsToReportQuery,
  ProvidersQuery,
} from '@bluefox/graphql/integrations/develo';
import {
  Notification,
  NotificationType,
  VaxSyncIntegrationNotificationContent,
} from '@bluefox/models/Notification';
import { GetNotificationsByEntityRefIdQuery } from '@graphql/communications';
import VaxSyncIntegrationNotificationModal, {
  VaxSyncIntegrationNotificationModalData,
} from '@ui/Notifications/VaxSyncIntegrationNotification/VaxSyncIntegrationNotificationModal';
import { FormValues } from '@ui/Notifications/VaxSyncIntegrationNotification/VaxSyncIntegrationNotificationForm';
import { useNotifications } from '@ui/Notifications/useNotifications';

interface ProviderData {
  account: {
    firstName: string;
    lastName: string;
    email: string;
  };
}
interface ProvidersData {
  providers: ProviderData[];
}
interface PracticeAccount {
  title: string;
}

interface VaccineAdministrator {
  email: string;
  firstName: string;
  lastName: string;
  practiceAccounts: PracticeAccount[];
}

interface VaccineAdministratorsData {
  accounts: VaccineAdministrator[];
}

interface PracticeOption {
  text: string;
  value: string;
}

interface ProviderOption {
  text: string;
  value: string;
}

interface VaccinationsToReportData {
  aggregating: {
    aggregate: {
      count: number;
    };
  };
  develoVaccinationsToReport: VaccinationsToReportInterface[];
}

interface Practice {
  id: string;
  name: string;
}

const ENTRIES_PER_PAGE = 15;

const VaccinationsToReport = () => {
  const [practiceOptions, setPracticeOptions] = useState<PracticeOption[]>([]);
  const [providerOptions, setProviderOptions] = useState<ProviderOption[]>([]);
  const [prescriberOptions, setPrescriberOptions] = useState<ProviderOption[]>(
    []
  );
  const [practiceIds, setPracticeIds] = useState<string[] | undefined>([]);
  const [searchPractice, setSearchPractice] = useState<string>();
  const [searchStatus, setSearchStatus] = useState<string>();
  const [searchIisStatus, setSearchIisStatus] = useState<string>();
  const [searchFromDate, setSearchFromDate] = useState<Date | null | undefined>(
    null
  );
  const [searchToDate, setSearchToDate] = useState<Date | null | undefined>(
    null
  );
  const [criteria, setCriteria] = useState<object>({});
  const [page, setPage] = useState(0);

  const [searchPatient, setSearchPatient] = useState<string>('');
  const [searchProvider, setSearchProvider] = useState<string>();
  const [searchPrescriber, setSearchPrescriber] = useState<string>();

  const { data: practicesData } = useQuery(AllPracticesWithDevelo);

  const [sendNotificationModalData, setSendNotificationModalData] =
    useState<VaxSyncIntegrationNotificationModalData>();
  const [notificationSentIds, setNotificationSentIds] = useState<string[]>([]);
  const [formValues, setFormValues] = useState<FormValues>({} as FormValues);

  const [DeveloActionsLazyQuery, { data, loading, refetch }] =
    useLazyQuery<VaccinationsToReportData>(DeveloVaccinationsToReportQuery, {
      variables: {
        criteria,
        limit: ENTRIES_PER_PAGE,
        offset: !!page ? ENTRIES_PER_PAGE * (page - 1) : 0,
      },
      onCompleted: () => {},
    });

  const [getProviders] = useLazyQuery<ProvidersData>(ProvidersQuery, {
    onCompleted: (data) => {
      if (!data || data.providers.length === 0) return;

      setProviderOptions(
        data.providers.map(({ account: acc }) => ({
          text: `${acc.firstName} ${acc.lastName}`,
          value: acc.email,
        }))
      );
    },
  });

  const [getPrescribers] = useLazyQuery<ProvidersData>(PrescribersQuery, {
    onCompleted: (data) => {
      if (!data || data.providers.length === 0) return;

      setPrescriberOptions(
        data.providers.map(({ account: acc }) => ({
          text: `${acc.firstName} ${acc.lastName}`,
          value: acc.email,
        }))
      );
    },
  });

  const { sendNotificationHandler, editNotificationHandler } =
    useNotifications();

  const {
    loading: notificationSentIdsLoading,
    refetch: notificationSentIdsRefetch,
  } = useQuery<{
    communication_notifications: Notification[];
  }>(GetNotificationsByEntityRefIdQuery, {
    variables: {
      entityRefIds: data?.develoVaccinationsToReport.map((dvtr) => dvtr.id),
    },
    skip:
      !data?.develoVaccinationsToReport ||
      !data?.develoVaccinationsToReport.length,
    onError: (error) => {
      setNotificationSentIds([]);
    },
    onCompleted: (data) => {
      const ids = data.communication_notifications?.map(
        (notification) => notification.entityRefId as string
      );
      setNotificationSentIds(ids);
    },
  });

  const rawEmails = useMemo(
    () =>
      data?.develoVaccinationsToReport.map((item) => {
        return item.metadata.provider?.account.email
          ? item.metadata.provider?.account.email
          : '';
      }),
    [data]
  );

  const emails = useMemo(
    () => rawEmails?.filter((item, idx) => rawEmails.indexOf(item) === idx),
    [rawEmails]
  );

  const { data: accountsData, refetch: refetchAccountsData } =
    useQuery<VaccineAdministratorsData>(VaccineAdministratorsQuery, {
      variables: {
        emails,
      },
      skip: !emails,
    });

  const total = data?.aggregating.aggregate.count || 0;
  const totalPages = useMemo(
    () => Math.ceil(total / ENTRIES_PER_PAGE),
    [total, ENTRIES_PER_PAGE]
  );

  useEffect(() => {
    if (!practiceIds) return;

    getPrescribers({
      variables: {
        practiceIds,
      },
    });

    getProviders({
      variables: {
        practiceIds,
      },
    });
  }, [practiceIds]);

  useEffect(() => {
    if (!data && !accountsData) return;
    refetch();
    refetchAccountsData();
  }, [data, accountsData]);

  const handleStatusValue = (value: string) => {
    const statusValue =
      !!value && value !== 'allStatus' ? value?.toString() : undefined;
    setSearchStatus(statusValue || '');
  };

  const handleIisStatusValue = (value: string) => {
    const iisStatusValue =
      !!value && value !== 'allStatus' ? value?.toString() : undefined;
    setSearchIisStatus(iisStatusValue || '');
  };

  const cleanProviderData = () => {
    setProviderOptions([]);
    setSearchProvider('');
    setPrescriberOptions([]);
    setSearchPrescriber('');
  };

  const handlePracticeValue = (value: string) => {
    const practiceIds = practicesData?.allPractices.map((p: Practice) => p.id);
    const practiceValue = !!value ? value : undefined;
    setSearchPractice(practiceValue);
    setPracticeIds(practiceValue ? [practiceValue] : practiceIds);
    cleanProviderData();
  };

  const handlePrescriberValue = (value: string) => {
    const prescriberValue =
      !!value && value !== 'allPrescribers' ? value : undefined;
    setSearchPrescriber(prescriberValue);
  };

  const handleProviderValue = (value: string) => {
    const providerValue =
      !!value && value !== 'allProviders' ? value : undefined;
    setSearchProvider(providerValue);
  };

  useEffect(() => {
    if (!practicesData) return;

    setPracticeOptions(
      practicesData.allPractices.map((p: Practice) => {
        return {
          text: p.name,
          value: p.id,
        };
      })
    );
  }, [practicesData]);

  useEffect(() => {
    let _criteria = {};
    let _practiceId = undefined;
    let _searchStatus = undefined;
    let _searchIisStatus = undefined;
    let _searchDateRange = undefined;
    let _searchPatient = undefined;
    let _searchProvider = {};
    let _searchPrescriber = {};

    if (searchPractice) {
      _practiceId = { _in: practiceIds };
    }

    if (searchStatus) {
      _searchStatus = { _eq: searchStatus };
    }

    if (searchIisStatus) {
      _searchIisStatus = { _eq: searchIisStatus };
    }

    if (searchFromDate || searchToDate) {
      _searchDateRange = {
        _gte: searchFromDate,
        _lte: searchToDate ?? new Date(),
      };
    }

    if (searchPatient) {
      _searchPatient = {
        _or: [
          { mrn: { _ilike: `%${searchPatient}%` } },
          {
            patientData: {
              _or: [
                { firstName: { _ilike: `%${searchPatient}%` } },
                { lastName: { _ilike: `%${searchPatient}%` } },
              ],
            },
          },
        ],
      };
    }

    if (searchPrescriber) {
      _searchPrescriber = {
        prescriber: { account: { email: searchPrescriber } },
      };
    }

    if (searchProvider) {
      _searchProvider = { provider: { account: { email: searchProvider } } };
    }

    _criteria = {
      practiceId: _practiceId,
      status: _searchStatus,
      iisStatus: _searchIisStatus,
      createdAt: _searchDateRange,
      practicePatient: _searchPatient,
      metadata: {
        _contains: { ..._searchProvider, ..._searchPrescriber },
      },
    };

    if (!!searchPractice) {
      DeveloActionsLazyQuery();
    }

    setCriteria(_criteria);
  }, [
    searchPractice,
    searchStatus,
    searchIisStatus,
    searchFromDate,
    searchToDate,
    searchPatient,
    searchProvider,
    searchPrescriber,
    DeveloActionsLazyQuery,
  ]);

  return (
    <MainLayout
      path={[
        { text: 'Develo Integration', to: '/integration-develo' },
        { text: 'Vaccinations To Report' },
      ]}
    >
      <Container fluid>
        <Card fluid style={{ marginTop: '1rem' }}>
          <Card.Content>
            <Card.Header as={'h3'}>
              <Icon name="list" style={{ marginRight: '0.6rem' }} />
              Vaccinations To Report
            </Card.Header>
            <Card.Description>
              <Menu borderless style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Menu.Menu style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <Menu.Item>
                    <Dropdown
                      style={{ minWidth: '15rem' }}
                      placeholder="Filter by practice"
                      fluid
                      search
                      selection
                      onChange={(e, data) => {
                        handlePracticeValue(data.value?.toString() || '');
                      }}
                      options={[...practiceOptions]}
                    />
                  </Menu.Item>
                  <Menu.Item>
                    <Dropdown
                      style={{ minWidth: '15rem' }}
                      placeholder="Filter by Prescriber"
                      fluid
                      selection
                      onChange={(e, data) => {
                        handlePrescriberValue(data.value?.toString() || '');
                      }}
                      options={[
                        { text: 'All Prescribers', value: 'allPrescribers' },
                        ...prescriberOptions,
                      ]}
                      disabled={!practiceIds?.length}
                    />
                  </Menu.Item>
                  <Menu.Item>
                    <Dropdown
                      style={{ minWidth: '15rem' }}
                      placeholder="Filter by Given By"
                      fluid
                      selection
                      onChange={(e, data) => {
                        handleProviderValue(data.value?.toString() || '');
                      }}
                      options={[
                        { text: 'All Providers', value: 'allProviders' },
                        ...providerOptions,
                      ]}
                      disabled={!practiceIds?.length}
                    />
                  </Menu.Item>
                  <Menu.Item>
                    <Dropdown
                      style={{ minWidth: '15rem' }}
                      placeholder="Filter by status"
                      fluid
                      selection
                      onChange={(e, data) => {
                        handleStatusValue(data.value?.toString() || '');
                      }}
                      options={[
                        { text: 'All status', value: 'allStatus' },
                        { text: 'Completed', value: 'completed' },
                        { text: 'Pending', value: 'pending' },
                        { text: 'Error', value: 'error' },
                        { text: 'Finished', value: 'finished' },
                      ]}
                    />
                  </Menu.Item>
                  <Menu.Item>
                    <Dropdown
                      style={{ minWidth: '15rem' }}
                      placeholder="Filter by IIS status"
                      fluid
                      selection
                      onChange={(e, data) => {
                        handleIisStatusValue(data.value?.toString() || '');
                      }}
                      options={[
                        { text: 'All status', value: 'allStatus' },
                        { text: 'Pending', value: 'pending' },
                        { text: 'Auto', value: 'auto' },
                        { text: 'Canid', value: 'canid' },
                        { text: 'Practice', value: 'practice' },
                      ]}
                    />
                  </Menu.Item>

                  <Menu.Item>
                    <Input
                      style={{ minWidth: '15rem' }}
                      placeholder="Search patient"
                      value={searchPatient}
                      onChange={(_, { value }) => {
                        setSearchPatient(value);
                      }}
                    />
                  </Menu.Item>
                </Menu.Menu>
                <Menu.Menu style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <Menu.Item>
                    <label style={{ marginRight: '0.5rem', width: '8rem' }}>
                      <b>Search by date range:</b>
                    </label>
                    <DateTimePicker
                      placeholderText="From..."
                      selected={searchFromDate}
                      onChange={(d) => {
                        setSearchFromDate(d ? (d as Date) : undefined);
                      }}
                      onSelect={(value) =>
                        setSearchFromDate(value ? (value as Date) : undefined)
                      }
                      onClear={() => setSearchFromDate(undefined)}
                      maxDate={new Date()}
                      dateFormat={DateFormats.DATE}
                      showYearDropdown
                      showMonthDropdown
                      scrollableYearDropdown
                      dropdownMode="select"
                      isClearable
                    />
                  </Menu.Item>
                  <Menu.Item>
                    <DateTimePicker
                      placeholderText="To..."
                      selected={searchToDate}
                      onChange={(d) => {
                        setSearchToDate(d ? (d as Date) : undefined);
                      }}
                      onSelect={(value) =>
                        setSearchToDate(value ? (value as Date) : undefined)
                      }
                      onClear={() => setSearchToDate(undefined)}
                      maxDate={new Date()}
                      dateFormat={DateFormats.DATE}
                      showYearDropdown
                      showMonthDropdown
                      scrollableYearDropdown
                      dropdownMode="select"
                      isClearable
                    />
                  </Menu.Item>
                </Menu.Menu>
              </Menu>
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Practice</Table.HeaderCell>
                    <Table.HeaderCell>Patient</Table.HeaderCell>
                    <Table.HeaderCell>Date of Service</Table.HeaderCell>
                    <Table.HeaderCell>Vaccine</Table.HeaderCell>
                    <Table.HeaderCell>Prescriber</Table.HeaderCell>
                    <Table.HeaderCell>Given By</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Route - Site</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Error</Table.HeaderCell>
                    <Table.HeaderCell>Action</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">
                      Status (EMR)
                    </Table.HeaderCell>
                    <Table.HeaderCell>IIS status</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {loading ? (
                    <Table.Row>
                      <Table.Cell colSpan={11}>
                        <Segment basic>
                          <Placeholder fluid>
                            <Placeholder.Header>
                              <Placeholder.Line />
                              <Placeholder.Line />
                            </Placeholder.Header>
                          </Placeholder>
                        </Segment>
                      </Table.Cell>
                    </Table.Row>
                  ) : !!data?.develoVaccinationsToReport.length &&
                    !!accountsData?.accounts.length ? (
                    data.develoVaccinationsToReport.map((oa) => {
                      const finalData = accountsData?.accounts.find(
                        (elem) =>
                          elem.email === oa.metadata.provider?.account.email
                      );

                      const newItem = {
                        ...oa,
                        firstName: finalData?.firstName,
                        lastName: finalData?.lastName,
                        title: finalData?.practiceAccounts[0].title,
                      };

                      return (
                        <VaccinationsToReportRow
                          key={newItem.id}
                          data={newItem}
                          onSave={refetch}
                          notificationSentIdsLoading={
                            notificationSentIdsLoading
                          }
                          notificationSentIds={notificationSentIds}
                          onSendNotification={() => {
                            setFormValues({
                              version: 2,
                            });
                            const patientData = newItem?.metadata?.patient;
                            setSendNotificationModalData({
                              fullPatienName: `${patientData?.lastName} ${patientData?.firstName}`,
                              givenAt: newItem.metadata
                                .givenAt as unknown as string,
                              mrn: patientData?.mrn,
                              practiceId: newItem.practiceId,
                              practicePatientId: newItem.practicePatientId,
                              entityRefId: newItem.id,
                              vaccinationId: newItem.vaccinationId,
                              vaccineName: oa.metadata.vaccine?.name || '-',
                              inventoryVfc: oa.metadata.inventory?.vfc,
                              lot: oa.metadata.inventory?.lot,
                            });
                          }}
                        />
                      );
                    })
                  ) : (
                    <Table.Row>
                      <Table.Cell colSpan={11}>
                        <Message>No Vaccinations To Report Found</Message>
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>
                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell>Total: {total}</Table.HeaderCell>
                    <Table.HeaderCell colSpan={11} textAlign="right">
                      <Pagination
                        disabled={!total || total < ENTRIES_PER_PAGE}
                        defaultActivePage={1}
                        boundaryRange={0}
                        siblingRange={1}
                        onPageChange={(e, { activePage }) =>
                          setPage(activePage as number)
                        }
                        totalPages={totalPages}
                      />
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            </Card.Description>
          </Card.Content>
        </Card>
        <VaxSyncIntegrationNotificationModal
          open={!!sendNotificationModalData}
          fullPatienName={sendNotificationModalData?.fullPatienName}
          givenAt={sendNotificationModalData?.givenAt}
          mrn={sendNotificationModalData?.mrn}
          practiceId={sendNotificationModalData?.practiceId}
          practicePatientId={sendNotificationModalData?.practicePatientId}
          notificationType={NotificationType.develo}
          entityRefId={sendNotificationModalData?.entityRefId}
          vaccinationId={sendNotificationModalData?.vaccinationId}
          vaccineName={sendNotificationModalData?.vaccineName}
          inventoryVfc={sendNotificationModalData?.inventoryVfc}
          lot={sendNotificationModalData?.lot}
          onClose={() => {
            setFormValues({});
            setSendNotificationModalData(undefined);
          }}
          formValues={formValues}
          setFormValues={setFormValues}
          onCreate={async (notification: Notification) => {
            setFormValues({});
            setSendNotificationModalData(undefined);
            await sendNotificationHandler(notification);
            try {
              await notificationSentIdsRefetch?.();
            } catch (error) {}
          }}
          onEdit={async (
            notification: Notification,
            content: VaxSyncIntegrationNotificationContent,
            newStatusLogItem: any
          ) => {
            await editNotificationHandler(
              notification,
              content,
              newStatusLogItem
            );
          }}
        />
      </Container>
    </MainLayout>
  );
};

export default VaccinationsToReport;
