import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { toast } from 'react-semantic-toasts';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import {
  PracticeSettingsQuery,
  SetBillablePracticeMutation,
  GetBillablePracticeQuery,
  GetPracticeSiteVisits,
  InsertPracticeSiteVisit,
  UpdatePracticeSiteVisit,
  UpdatePracticeSettingsMutation,
  PracticesQuery,
} from '@graphql/practices';
import {
  BillingInvoicesByPracticeQuery,
  BillingReportQuery,
  ProcedureAmountsQuery,
} from '@graphql/billing';
import { PracticeSettings, ChannelToReport } from '@bluefox/models/Practice';
import {
  Segment,
  Header,
  Button,
  Label,
  Icon,
  Modal,
  Confirm,
  Form,
  Table,
  Grid,
  Message,
  Placeholder,
  Pagination,
  Dropdown,
} from 'semantic-ui-react';
import MainLayout from '@ui/MainLayout';
import { usePractice } from '@bluefox/contexts/Practice';
import { Link } from 'react-router-dom';
import PracticeFormModal from '@ui/PracticeFormModal';
import { OrdersPracticeProfile } from '@bluefox/graphql/inventoryOrders';
import { OrderStatuses } from '@bluefox/models/InventoryOrders';
import moment from 'moment-timezone';
import { DateFormats } from '@bluefox/models/Dates';
import { Vaccine } from '@bluefox/models/Vaccine';
import { InventoryOrders } from '@bluefox/models/InventoryOrders';
import { humanizeText } from '@bluefox/lib/humanize';
import PracticeProfileInfoField from '@ui/PracticeProfileInfoField';
import DateTimePicker from '@bluefox/ui/DateTimePicker';
import { BillingClaim } from '@bluefox/models/Billing';
import TicketsList from '@ui/Tickets/TicketsList';
import { UpdatePracticeInitialCredit } from '@graphql/organizations';
import {
  balanceMovementReasonsOptions,
  balanceMovementTypesOptions,
} from '@bluefox/models/BalanceMovements';
import BalanceMovementForm from '@ui/BalanceMovementForm';

interface BillingReportData {
  billingReport: BillingClaim[];
  totalAmountCalculated: {
    aggregate: {
      sum: {
        totalAmount: number;
      };
    };
  };
}
interface BillingInvoiceData {
  id: string;
  invoiceNo: string;
  date: Date;
  amount: number;
  cptCodesCount: {
    aggregate: {
      count: number;
    };
  };
}

interface BillingInvoicesData {
  invoices: BillingInvoiceData[];
  aggregating: {
    aggregate: {
      count: number;
    };
  };
}

interface PracticeSettingsData {
  practice: {
    settings: PracticeSettings;
    suspended: boolean;
  };
}

interface Cpt {
  cpt: string;
}

interface ProcedureAmountsData {
  cptCodes: Cpt[];
}

const PracticeProfile = () => {
  const practice = usePractice();

  const [openSettings, setOpenSettings] = useState(false);
  const [showBillablePracticeConfirm, setShowBillablePracticeConfirm] =
    useState(false);
  const [showSuspendedPracticeConfirm, setShowSuspendedPracticeConfirm] =
    useState(false);
  const [isBillable, setIsBillable] = useState(false);
  const [guardianEnabled, setGuardianEnabled] = useState(false);
  const [idleUserLogoutEnabled, setIdleUserLogoutEnabled] = useState(false);
  const [inventoryAdjustmentEnabled, setInventoryAdjustmentEnabled] =
    useState(false);
  const [vaccineInventoryRequestEnabled, setVaccineInventoryRequestEnabled] =
    useState(false);
  const [georgia3231FormEnabled, setGeorgia3231FormEnabled] = useState(false);
  const [illinoisPhysicalFormEnabled, setIllinoisPhysicalFormEnabled] =
    useState(false);
  const [florida680FormEnabled, setFlorida680FormEnabled] = useState(false);
  const [
    coloradoCertificateOfImmunizationFormEnabled,
    setColoradoCertificateOfImmunizationFormEnabled,
  ] = useState(false);
  const [suspendedEnabled, setSuspendedEnabled] = useState(false);
  const [showVaxListToBillerEnabled, setShowVaxListToBillerEnabled] =
    useState(false);
  const [
    showBillingDashboardInPracticePortalEnabled,
    setShowBillingDashboardInPracticePortalEnabled,
  ] = useState(false);

  const [practiceSettings, setPracticeSettings] = useState<PracticeSettings>(
    {}
  );

  const [practiceSettingsCriteria, setPracticeSettingsCriteria] =
    useState<PracticeSettings>({});
  const [
    showChangeFeaturesConfirmationModal,
    setShowChangeFeaturesConfirmationModal,
  ] = useState(false);

  const [botUrl, setBotUrl] = useState<string>('');
  const [botEmr, setBotEmr] = useState('');
  const [botEnabled, setBotEnabled] = useState(false);
  const [retrieveAppointmentsEnabled, setRetrieveAppointmentsEnabled] =
    useState(false);
  const [athenaEnabled, setAthenaEnabled] = useState(false);
  const [athenaSubscriptionEnabled, setAthenaSubscriptionEnabled] =
    useState(false);
  const [athenaReportEnabled, setAthenaReportEnabled] = useState(false);
  const [botReportEnabled, setBotReportEnabled] = useState(false);

  const [nextVisitModalOpen, setNextVisitModalOpen] = useState(false);
  const [updateNextVisitModalOpen, setUpdateNextVisitModalOpen] =
    useState(false);
  const [addCreditConfirmationModalOpen, setAddCreditConfirmationModalOpen] =
    useState(false);
  const [addCreditModalOpen, setAddCreditModalOpen] = useState(false);
  const [nextVisit, setNextVisit] = useState<Date | undefined>(undefined);
  const [updatedNextVisit, setUpdatedNextVisit] = useState<Date | undefined>(
    undefined
  );
  const [addCredit, setAddCredit] = useState(0);
  const creditUsedLastInvoice = 0;
  const [develoEnabled, setDeveloEnabled] = useState(false);
  const [develoReportEnabled, setDeveloReportEnabled] = useState(false);
  const [opEnabled, setOpEnabled] = useState(false);
  const [updateVaccinationsCsvEnabled, setUpdateVaccinationsCsvEnabled] =
    useState(false);
  const [currentlyUsedIntegration, setCurrentlyUsedIntegration] = useState('');
  const [notifyImmunizations, setNotifyImmunizations] =
    useState<boolean>(false);
  const [channelToReportImmunizations, setChannelToReportImmunizations] =
    useState<string>();
  const [celebrationConsentFormEnabled, setCelebrationConsentFormEnabled] =
    useState(false);
  const [borrowingEnabled, setBorrowingEnabled] = useState(false);
  const [notificationEnabled, setNotificationEnabled] =
    useState<boolean>(false);

  const Integrations = {
    Athena: 'athena',
    Develo: 'develo',
    ECW: 'ecw',
    OP: 'op',
  };

  const ChannelsToReport = {
    Small: 'imm-small-practices',
    Medium: 'imm-medium-practices',
    Big: 'imm-big-practices',
    RealTime1: 'real-time-1',
    RealTime2: 'real-time-2',
  };

  const { data: settingsData, refetch: refetchPracticeSettings } =
    useQuery<PracticeSettingsData>(PracticeSettingsQuery, {
      variables: {
        id: practice?.id,
      },
      onCompleted: (data) => {
        setSuspendedEnabled(data.practice.suspended);
        if (data.practice.settings.athena?.enabled) {
          setCurrentlyUsedIntegration(Integrations.Athena);
        } else if (data.practice.settings.develo?.enabled) {
          setCurrentlyUsedIntegration(Integrations.Develo);
        } else if (data.practice.settings.bot?.enabled) {
          setCurrentlyUsedIntegration(Integrations.ECW);
        } else if (data.practice.settings.op?.enabled) {
          setCurrentlyUsedIntegration(Integrations.OP);
        } else {
          setCurrentlyUsedIntegration('');
        }
      },
    });

  const { data: billablePracticeData, refetch: refetchBillablePracticeData } =
    useQuery(GetBillablePracticeQuery, {
      variables: {
        practiceId: practice.id,
      },
    });

  const { data: billingInvoicesData } = useQuery<BillingInvoicesData>(
    BillingInvoicesByPracticeQuery,
    {
      variables: {
        practiceId: practice.id,
        limit: 1,
      },
    }
  );

  const { data: siteVisitsData, refetch: refetchSiteVisitsData } = useQuery(
    GetPracticeSiteVisits,
    {
      variables: {
        practiceId: practice.id,
      },
    }
  );

  const { data: procedureAmountsData } = useQuery<ProcedureAmountsData>(
    ProcedureAmountsQuery,
    {
      variables: {
        type: 'Inventory',
      },
    }
  );

  const [getBillingReport, { data: billingReportData }] =
    useLazyQuery<BillingReportData>(BillingReportQuery);

  const [SavePracticeSiteVisit] = useMutation(InsertPracticeSiteVisit);
  const [SaveUpdatedPracticeSiteVisit] = useMutation(UpdatePracticeSiteVisit);
  const [updatePracticeInitialCredit] = useMutation(
    UpdatePracticeInitialCredit
  );

  const handleSavePracticeSiteVisit = (
    visit: Date | undefined,
    id: String,
    handler: String
  ) => {
    handler === 'new'
      ? SavePracticeSiteVisit({
          variables: {
            date: visit,
            practiceId: practice.id,
          },
        })
          .then(() => {
            toast({
              title: 'Next visit saved successfully',
              type: 'success',
              time: 1000,
            });
            setNextVisitModalOpen(false);
            refetchSiteVisitsData();
          })
          .catch((e) => {
            toast({
              title: `Callback error: ${e}`,
              type: 'error',
              time: 5000,
            });
          })
      : SaveUpdatedPracticeSiteVisit({
          variables: {
            id,
            date: visit,
          },
        })
          .then(() => {
            toast({
              title: 'Next visit updated successfully',
              type: 'success',
              time: 1000,
            });
            setUpdateNextVisitModalOpen(false);
            refetchSiteVisitsData();
          })
          .catch((e) => {
            toast({
              title: `Callback error: ${e}`,
              type: 'error',
              time: 5000,
            });
          });
  };

  const handleInsertPracticeCreditBalance = (amount: Number) => {
    updatePracticeInitialCredit({
      variables: {
        initialCredit: amount as number,
        id: practice?.id,
      },
    })
      .then(() => {
        toast({
          title: 'Initial Credit Updated successfully',
          type: 'success',
          time: 1000,
        });
        setAddCreditModalOpen(false);
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  const [SaveBillableSetting] = useMutation(SetBillablePracticeMutation);

  const handleUpdateBillablePractice = () => {
    SaveBillableSetting({
      variables: {
        practiceId: practice.id,
        isBillable: suspendedEnabled ? false : isBillable,
        suspended: suspendedEnabled,
      },
      refetchQueries: [
        {
          query: PracticesQuery,
        },
      ],
    })
      .then(() => {
        toast({
          title: 'Settings saved successfully',
          type: 'success',
          time: 1000,
        });
        refetchPracticeSettings();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  const [updatePracticeSettings] = useMutation(UpdatePracticeSettingsMutation);

  const handleUpdatePracticeSettings = () => {
    updatePracticeSettings({
      variables: {
        practiceId: practice.id,
        criteria: practiceSettingsCriteria,
      },
    })
      .then(() => {
        toast({
          title: 'Settings saved successfully',
          type: 'success',
          time: 1000,
        });
        refetchPracticeSettings();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  useEffect(() => {
    if (!settingsData) return;
    setPracticeSettings(settingsData.practice.settings);
  }, [settingsData]);

  useEffect(() => {
    if (!practiceSettings) return;
    setGuardianEnabled(practiceSettings.guardian?.enabled ?? false);

    setIdleUserLogoutEnabled(
      practiceSettings.features?.idleUserLogout?.enabled ?? false
    );

    setInventoryAdjustmentEnabled(
      practiceSettings.features?.remoteInventoryAdjustment?.enabled ?? false
    );

    setVaccineInventoryRequestEnabled(
      practiceSettings.inventory?.addVaccine ?? false
    );

    setGeorgia3231FormEnabled(
      practiceSettings.forms?.georgia?.form3231?.enabled ?? false
    );

    setIllinoisPhysicalFormEnabled(
      practiceSettings.forms?.illinois?.physicalForm?.enabled ?? false
    );

    setFlorida680FormEnabled(
      practiceSettings.forms?.florida?.form680?.enabled ?? false
    );

    setColoradoCertificateOfImmunizationFormEnabled(
      practiceSettings.forms?.colorado?.certificateOfImmunization?.enabled ??
        false
    );

    setBotUrl(practiceSettings.bot?.url ?? '');
    setBotEmr(practiceSettings.bot?.emr ?? '');
    setBotEnabled(practiceSettings.bot?.enabled ?? false);
    setAthenaEnabled(practiceSettings.athena?.enabled ?? false);
    setAthenaSubscriptionEnabled(
      practiceSettings.athena?.subscription ?? false
    );
    setAthenaReportEnabled(practiceSettings.athena?.report ?? false);
    setBotReportEnabled(practiceSettings.bot?.report ?? false);
    setRetrieveAppointmentsEnabled(
      practiceSettings.bot?.retrieveAppointments?.enabled ?? false
    );
    setShowVaxListToBillerEnabled(
      practiceSettings.billing?.showVaxListToBiller ?? false
    );
    setShowBillingDashboardInPracticePortalEnabled(
      practiceSettings.billing?.showDashboardInPracticePortal ?? false
    );
    setDeveloEnabled(practiceSettings.develo?.enabled ?? false);
    setDeveloReportEnabled(practiceSettings.develo?.report ?? false);
    setOpEnabled(practiceSettings.op?.enabled ?? false);
    setUpdateVaccinationsCsvEnabled(
      practiceSettings.op?.updateVaccinationsCsv?.enabled ?? false
    );
    setNotifyImmunizations(practiceSettings.immunizations?.notifyNew || false);
    setChannelToReportImmunizations(
      practiceSettings.immunizations?.channel || undefined
    );
    setCelebrationConsentFormEnabled(
      practiceSettings.forms?.florida?.consentForm?.enabled ?? false
    );
    setBorrowingEnabled(
      practiceSettings.borrowingOnPracticePortal?.enabled || false
    );
    setNotificationEnabled(practiceSettings.notifications?.enabled || false);
  }, [practiceSettings]);

  useEffect(() => {
    if (!billablePracticeData) return;
    setIsBillable(billablePracticeData.practice.isBillable);
  }, [billablePracticeData]);

  useEffect(() => {
    setPracticeSettingsCriteria({
      guardian: {
        enabled: guardianEnabled,
      },
      bot: {
        ...settingsData?.practice?.settings?.bot,
        emr: botEmr,
        url: botUrl,
        enabled:
          currentlyUsedIntegration === Integrations.ECW ? botEnabled : false,
        report:
          currentlyUsedIntegration === Integrations.ECW && botEnabled
            ? botReportEnabled
            : false,
        retrieveAppointments: {
          enabled:
            currentlyUsedIntegration === Integrations.ECW &&
            retrieveAppointmentsEnabled
              ? retrieveAppointmentsEnabled
              : false,
        },
        facilityId: settingsData?.practice?.settings.bot?.facilityId,
        addCodeToEcw:
          settingsData?.practice.settings.bot?.addCodeToEcw || false,
      },
      athena: {
        ...settingsData?.practice?.settings?.athena,
        enabled:
          currentlyUsedIntegration === Integrations.Athena
            ? athenaEnabled
            : false,
        subscription:
          currentlyUsedIntegration === Integrations.Athena && athenaEnabled
            ? athenaSubscriptionEnabled
            : false,
        report:
          currentlyUsedIntegration === Integrations.Athena && athenaEnabled
            ? athenaReportEnabled
            : false,
        departmentId:
          settingsData?.practice.settings.athena?.departmentId || '',
        practiceId: settingsData?.practice.settings.athena?.practiceId || '',
      },
      features: {
        remoteInventoryAdjustment: {
          enabled: inventoryAdjustmentEnabled,
        },
        idleUserLogout: {
          enabled: idleUserLogoutEnabled,
        },
      },
      forms: {
        georgia: {
          form3231: {
            enabled: georgia3231FormEnabled,
          },
        },
        colorado: {
          certificateOfImmunization: {
            enabled: coloradoCertificateOfImmunizationFormEnabled,
          },
        },
        florida: {
          consentForm: {
            enabled: celebrationConsentFormEnabled,
          },
          form680: {
            enabled: florida680FormEnabled,
          },
        },
        illinois: {
          physicalForm: {
            enabled: illinoisPhysicalFormEnabled,
          },
        },
      },
      billing: {
        ...settingsData?.practice.settings.billing,
        showVaxListToBiller: showVaxListToBillerEnabled,
        showDashboardInPracticePortal:
          showBillingDashboardInPracticePortalEnabled,
      },
      develo: {
        enabled:
          currentlyUsedIntegration === Integrations.Develo
            ? develoEnabled
            : false,
        report:
          currentlyUsedIntegration === Integrations.Develo && develoEnabled
            ? develoReportEnabled
            : false,
      },
      op: {
        enabled:
          currentlyUsedIntegration === Integrations.OP ? opEnabled : false,
        updateVaccinationsCsv: {
          enabled:
            currentlyUsedIntegration === Integrations.OP && opEnabled
              ? updateVaccinationsCsvEnabled
              : false,
        },
      },
      inventory: {
        addVaccine: vaccineInventoryRequestEnabled,
      },
      immunizations: {
        notifyNew: notifyImmunizations,
        channel: notifyImmunizations ? channelToReportImmunizations : undefined,
      },
      borrowingOnPracticePortal: {
        enabled: borrowingEnabled,
      },
      notifications: {
        enabled: notificationEnabled,
      },
    });
  }, [
    guardianEnabled,
    athenaEnabled,
    athenaSubscriptionEnabled,
    athenaReportEnabled,
    botUrl,
    botEmr,
    botEnabled,
    botReportEnabled,
    inventoryAdjustmentEnabled,
    georgia3231FormEnabled,
    illinoisPhysicalFormEnabled,
    florida680FormEnabled,
    coloradoCertificateOfImmunizationFormEnabled,
    idleUserLogoutEnabled,
    showVaxListToBillerEnabled,
    showBillingDashboardInPracticePortalEnabled,
    develoEnabled,
    opEnabled,
    updateVaccinationsCsvEnabled,
    develoReportEnabled,
    vaccineInventoryRequestEnabled,
    notifyImmunizations,
    channelToReportImmunizations,
    celebrationConsentFormEnabled,
    retrieveAppointmentsEnabled,
    borrowingEnabled,
    currentlyUsedIntegration,
    Integrations.ECW,
    Integrations.Athena,
    Integrations.Develo,
    Integrations.OP,
    settingsData?.practice.settings.bot?.facilityId,
    settingsData?.practice.settings.bot?.addCodeToEcw,
    settingsData?.practice.settings.athena,
    settingsData?.practice.settings.billing,
    notificationEnabled,
  ]);

  useEffect(() => {
    if (!procedureAmountsData) return;

    const criteriaObj = {
      vaccinations: {
        practiceId: { _eq: practice.id },
      },
      status: { _in: ['paid', 'partially_paid', 'partially_billed'] },
      cptCodes: {
        cptCode: { _in: procedureAmountsData.cptCodes.map((c) => c.cpt) },
        paidAmount: { _gt: 0 },
        invoice: { _is_null: true },
      },
    };

    getBillingReport({
      variables: {
        criteria: criteriaObj,
        criteriaCptCodes: {
          claim: criteriaObj,
          cptCode: { _in: procedureAmountsData.cptCodes.map((c) => c.cpt) },
          paidAmount: { _gt: 0 },
          invoice: { _is_null: true },
        },
      },
    });
  }, [procedureAmountsData]);

  type InventoryOrderVaccineType = Pick<
    Vaccine,
    'saleNdc' | 'manufacturer' | 'name' | 'id'
  >;

  type InventoryOrderType = Omit<InventoryOrders, 'vaccine' | 'vaccineId'> & {
    vaccine: InventoryOrderVaccineType;
  };

  interface InventoryOrderDataType {
    aggregating: {
      aggregate: {
        count: number;
      };
    };
    orders: InventoryOrderType[];
    allPractices: [
      {
        id: string;
        name: string;
      },
    ];
  }

  const [privateCriteria, setPrivateCriteria] = useState<object>({});
  const [privatePage, setPrivatePage] = useState(0);
  const [vfcCriteria, setVfcCriteria] = useState<object>({});
  const [vfcPage, setVfcPage] = useState(0);

  const ENTRIES_PER_PAGE = 5;

  const { data: privateOrders, loading: privateLoading } =
    useQuery<InventoryOrderDataType>(OrdersPracticeProfile, {
      variables: {
        criteria: privateCriteria,
        limit: ENTRIES_PER_PAGE,
        offset: !!privatePage ? ENTRIES_PER_PAGE * (privatePage - 1) : 0,
      },
    });

  const { data: vfcOrders, loading: vfcLoading } =
    useQuery<InventoryOrderDataType>(OrdersPracticeProfile, {
      variables: {
        criteria: vfcCriteria,
        limit: ENTRIES_PER_PAGE,
        offset: !!vfcPage ? ENTRIES_PER_PAGE * (vfcPage - 1) : 0,
      },
    });

  useEffect(() => {
    setPrivateCriteria({
      practiceId: { _eq: practice.id },
      vfc: { _eq: false },
      _or: [
        { status: { _eq: OrderStatuses.ORDERED } },
        { status: { _eq: OrderStatuses.RECEIVED } },
        { status: { _eq: OrderStatuses.CANCELLED } },
        { status: { _eq: OrderStatuses.CLOSED } },
      ],
    });
    setPrivatePage(0);
  }, []);

  useEffect(() => {
    setVfcCriteria({
      practiceId: { _eq: practice?.id },
      vfc: { _eq: true },
      _or: [
        { status: { _eq: OrderStatuses.ORDERED } },
        { status: { _eq: OrderStatuses.RECEIVED } },
        { status: { _eq: OrderStatuses.CANCELLED } },
      ],
    });

    setVfcPage(0);
  }, []);

  useEffect(() => {
    if (!siteVisitsData) return;
    setUpdatedNextVisit(
      moment
        .tz(siteVisitsData?.site_visits[0]?.date, practice.timezone)
        .toDate()
    );
  }, [siteVisitsData]);

  const daysLeftTo = (date: Date | undefined) => {
    const dueDate = moment(date);
    return dueDate.diff(moment(), 'days');
  };

  const privateOrdersTotal = privateOrders?.aggregating.aggregate.count || 0;
  const totalPrivatePages = Math.ceil(privateOrdersTotal / ENTRIES_PER_PAGE);
  const vfcOrdersTotal = vfcOrders?.aggregating.aggregate.count || 0;
  const totalVfcPages = Math.ceil(vfcOrdersTotal / ENTRIES_PER_PAGE);

  return (
    <MainLayout
      path={[
        { text: 'Practices', to: '/practices' },
        { text: practice?.name ?? '', to: `/practices/${practice.handler}` },
        { text: 'Practice Profile' },
      ]}
    >
      <Segment>
        <Header as="h1" style={{ textTransform: 'capitalize' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: '4rem',
              margin: '1rem 0 2rem 0',
            }}
          >
            {practice?.name ?? 'Practice Profile'}
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <PracticeFormModal
              trigger={<Button content="Edit Practice" icon="edit" />}
              onSave={() => {
                refetchBillablePracticeData();
                refetchPracticeSettings();
              }}
            />
            <Button
              content="Settings"
              icon="setting"
              onClick={() => setOpenSettings(true)}
            />
          </div>
        </Header>
      </Segment>
      <Segment>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <StyledBtnRow>
            <StyledMenuBtnContainer>
              <Button
                as={Link}
                to={`/practices/${practice.handler}/users`}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'right',
                  boxShadow: 'none',
                  float: 'right',
                  height: '5.3rem',
                }}
                basic
              >
                <Icon
                  name="users"
                  size="big"
                  style={{ margin: '0 auto 1rem' }}
                />
                Users
              </Button>
            </StyledMenuBtnContainer>
            <StyledMenuBtnContainer>
              <Button
                as={Link}
                to={`/practices/${practice.handler}/patients`}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'right',
                  boxShadow: 'none',
                  float: 'right',
                  height: '5.3rem',
                }}
                basic
              >
                <Icon
                  name="stethoscope"
                  size="big"
                  style={{ margin: '0 auto 1rem' }}
                />
                Patients
              </Button>
            </StyledMenuBtnContainer>
            <StyledMenuBtnContainer>
              <Button
                as={Link}
                to={`/practices/${practice.handler}/claims`}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'right',
                  boxShadow: 'none',
                  float: 'right',
                  height: '5.3rem',
                }}
                basic
              >
                <Icon
                  name="money bill alternate outline"
                  size="big"
                  style={{ margin: '0 auto 1rem' }}
                />
                Claims
              </Button>
            </StyledMenuBtnContainer>
            <StyledMenuBtnContainer>
              <Button
                as={Link}
                to={`/practices/${practice.handler}/claims-to-upload-to-emr`}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'right',
                  boxShadow: 'none',
                  float: 'right',
                  height: '5.3rem',
                }}
                basic
              >
                <Icon
                  name="money"
                  size="big"
                  style={{ margin: '0 auto 1rem' }}
                />
                Claims EMR
              </Button>
            </StyledMenuBtnContainer>
            <StyledMenuBtnContainer>
              <Button
                as={Link}
                to={`/practices/${practice.handler}/insurances`}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'right',
                  boxShadow: 'none',
                  float: 'right',
                  height: '5.3rem',
                }}
                basic
              >
                <Icon
                  name="dollar"
                  size="big"
                  style={{ margin: '0 auto 1rem' }}
                />
                Insurances
              </Button>
            </StyledMenuBtnContainer>
            <StyledMenuBtnContainer>
              <Button
                as={Link}
                to={`/practices/${practice.handler}/inventory`}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'right',
                  boxShadow: 'none',
                  float: 'right',
                  height: '5.3rem',
                }}
                basic
              >
                <Icon
                  name="tasks"
                  size="big"
                  style={{ margin: '0 auto 1rem' }}
                />
                Inventory
              </Button>
            </StyledMenuBtnContainer>
            <StyledMenuBtnContainer>
              <Button
                as={Link}
                to={`/practices/${practice.handler}/inventory-orders`}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'right',
                  boxShadow: 'none',
                  float: 'right',
                  height: '5.3rem',
                }}
                basic
              >
                <Icon
                  name="cart"
                  size="big"
                  style={{ margin: '0 auto 1rem' }}
                />
                Orders
              </Button>
            </StyledMenuBtnContainer>
            <StyledMenuBtnContainer>
              <Button
                as={Link}
                to={`/practices/${practice.handler}/mappings`}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'right',
                  boxShadow: 'none',
                  float: 'right',
                  height: '5.3rem',
                }}
                basic
              >
                <Icon
                  name="map outline"
                  size="big"
                  style={{ margin: '0 auto 1rem' }}
                />
                Mappings
              </Button>
            </StyledMenuBtnContainer>

            <StyledMenuBtnContainer>
              <Button
                as={Link}
                to={`/practices/${practice.handler}/inconsistentVaccinationsHistory`}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'right',
                  boxShadow: 'none',
                  float: 'right',
                  height: '5.3rem',
                }}
                basic
              >
                <Icon
                  name="history"
                  size="big"
                  style={{ margin: '0 auto 1rem' }}
                />
                IVH
              </Button>
            </StyledMenuBtnContainer>

            {settingsData?.practice?.settings?.bot?.enabled ||
            settingsData?.practice?.settings?.athena?.enabled ? null : (
              <StyledMenuBtnContainer>
                <Button
                  as={Link}
                  to={`/practices/${practice.handler}/vax-sync`}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'right',
                    boxShadow: 'none',
                    float: 'right',
                    height: '5.3rem',
                  }}
                  basic
                >
                  <Icon
                    name="syringe"
                    size="big"
                    style={{ margin: '0 auto 1rem' }}
                  />
                  Vax Sync
                </Button>
              </StyledMenuBtnContainer>
            )}

            {settingsData?.practice?.settings?.op?.updateVaccinationsCsv
              ?.enabled && (
              <StyledMenuBtnContainer>
                <Button
                  as={Link}
                  to={`/practices/${practice.handler}/updateVaccinationsCsv`}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'right',
                    boxShadow: 'none',
                    float: 'right',
                    height: '5.3rem',
                  }}
                  basic
                >
                  <Icon
                    name="syringe"
                    size="big"
                    style={{ margin: '0 auto 1rem' }}
                  />
                  Update Vaccinations CSV
                </Button>
              </StyledMenuBtnContainer>
            )}
            <StyledMenuBtnContainer>
              <Button
                as={Link}
                to={`/practices/${practice.handler}/borrowing-summary`}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'right',
                  boxShadow: 'none',
                  float: 'right',
                  height: '5.3rem',
                }}
                basic
              >
                <Icon
                  name="list alternate outline"
                  size="big"
                  style={{ margin: '0 auto 1rem' }}
                />
                Borrowing Summary
              </Button>
            </StyledMenuBtnContainer>

            {practice?.handler === 'canid' && (
              <StyledMenuBtnContainer>
                <Button
                  as={Link}
                  to={`/practices/${practice.handler}/creditCards`}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'right',
                    boxShadow: 'none',
                    float: 'right',
                    height: '5.3rem',
                  }}
                  basic
                >
                  <Icon
                    name="credit card"
                    size="big"
                    style={{ margin: '0 auto 1rem' }}
                  />
                  Credit Cards
                </Button>
              </StyledMenuBtnContainer>
            )}
          </StyledBtnRow>
        </div>
      </Segment>
      <Grid stackable columns={2}>
        <Grid.Column>
          <Segment>
            <Message color="blue">
              <Header textAlign="center">Practice Info</Header>
            </Message>
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}
            >
              <PracticeProfileInfoField
                title="Address:"
                value={`${practice?.profile?.address?.street}, 
                  ${practice?.profile?.address?.city} - 
                  ${practice?.profile?.address?.state}, 
                  ${practice?.profile?.address?.zip}(${practice?.profile?.address?.zipExtension})`}
                titleWidth={30}
              />
              <PracticeProfileInfoField
                title="Work Phone:"
                value={practice?.profile?.phone?.work ?? '-'}
                titleWidth={30}
              />
              <PracticeProfileInfoField
                title="Mobile Phone:"
                value={practice?.profile?.phone?.mobile ?? '-'}
                titleWidth={30}
              />
              <PracticeProfileInfoField
                title="Fax:"
                value={practice?.profile?.phone?.fax ?? '-'}
                titleWidth={30}
              />
              <PracticeProfileInfoField
                title="Email:"
                value={practice?.profile?.paymentInformation?.email ?? '-'}
                titleWidth={30}
              />
              <PracticeProfileInfoField
                title="Canid Email:"
                value={practice?.profile?.internalEmail ?? '-'}
                titleWidth={30}
              />
              <PracticeProfileInfoField
                title="NPI:"
                value={practice?.npi ?? '-'}
                titleWidth={30}
              />
              <PracticeProfileInfoField
                title="Tax ID:"
                value={practice?.profile?.federalTaxId ?? '-'}
                titleWidth={30}
              />
              <PracticeProfileInfoField
                title="Practice Portal Link:"
                value={
                  <a
                    style={{ color: 'blue' }}
                    href={`https://app.canid.io/${practice.handler}`}
                    target="blank"
                  >
                    {`https://app.canid.io/${practice.handler}`}
                  </a>
                }
                titleWidth={30}
              />
              <PracticeProfileInfoField
                title="EMR Link:"
                value={
                  practice?.profile?.emrUrl ? (
                    <a
                      style={{ color: 'blue' }}
                      href={practice?.profile?.emrUrl}
                      target="blank"
                    >
                      {practice?.profile?.emrUrl}
                    </a>
                  ) : (
                    '-'
                  )
                }
                titleWidth={30}
              />
            </div>
          </Segment>
        </Grid.Column>
        <Grid.Column>
          <Segment>
            <Message color="purple">
              <Header textAlign="center">Billing</Header>
            </Message>
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}
            >
              <PracticeProfileInfoField
                title="Billable?:"
                value={
                  billablePracticeData?.practice?.isBillable === true ? (
                    <>
                      <Icon color="green" name="check" />
                      Billable
                    </>
                  ) : (
                    <>
                      <Icon color="red" name="x" />
                      {suspendedEnabled
                        ? 'Not Billable - Suspended Practice'
                        : 'Not Billable'}
                    </>
                  )
                }
                titleWidth={40}
              />
              <PracticeProfileInfoField
                title="Date of Last Invoice:"
                value={
                  billingInvoicesData?.invoices[0]?.date
                    ? moment(billingInvoicesData?.invoices[0]?.date).format(
                        DateFormats.DATE
                      )
                    : '-'
                }
                titleWidth={40}
              />
              <PracticeProfileInfoField
                title="Amount of Last Invoice:"
                value={
                  billingInvoicesData?.invoices[0]?.amount
                    ? `$${billingInvoicesData?.invoices[0]?.amount?.toFixed(2)}`
                    : '-'
                }
                titleWidth={40}
              />
              <PracticeProfileInfoField
                title="Up To Date Invoice Amount:"
                value={
                  billingReportData?.totalAmountCalculated?.aggregate?.sum
                    ?.totalAmount &&
                  billingReportData?.totalAmountCalculated?.aggregate?.sum
                    ?.totalAmount != null
                    ? `$${billingReportData?.totalAmountCalculated?.aggregate?.sum?.totalAmount.toFixed(
                        2
                      )}`
                    : '-'
                }
                titleWidth={40}
              />
              <PracticeProfileInfoField
                title="Credit Used in Last Invoice:"
                value={`$${
                  creditUsedLastInvoice ? creditUsedLastInvoice : '-'
                }`}
                titleWidth={40}
              />
              <PracticeProfileInfoField
                title="Remaining Credit Balance:"
                value={(() => {
                  if (!practice?.organization?.credit) return '$0.00';
                  if (practice.organization.credit < 0) {
                    return (
                      <div style={{ color: 'red' }}>
                        -${Math.abs(practice.organization.credit).toFixed(2)}
                      </div>
                    );
                  } else {
                    return `$${practice.organization.credit.toFixed(2)}`;
                  }
                })()}
                titleWidth={40}
              />
              <PracticeProfileInfoField
                title="Link to Last Invoice:"
                value={
                  <Link
                    to={`/billing/practice/${practice.id}/report`}
                    target="_blank"
                    style={{ color: 'blue' }}
                  >
                    {humanizeText(practice.handler, { capitalize: 'all' })}
                    {' Invoice Report '}
                    {<Icon name="external alternate" />}
                  </Link>
                }
                titleWidth={40}
              />

              <BalanceMovementForm practiceId={practice.id} />

              <Modal open={addCreditConfirmationModalOpen}>
                <Modal.Content>
                  Are you sure you want to add credit to this practice?
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    style={{
                      float: 'right',
                      marginBottom: '1rem',
                    }}
                    content="Yes"
                    primary
                    onClick={() => {
                      handleInsertPracticeCreditBalance(addCredit);
                      setAddCreditConfirmationModalOpen(false);
                      setAddCreditModalOpen(false);
                    }}
                    icon="save"
                  />
                  <Button
                    type="button"
                    content="No"
                    icon="cancel"
                    style={{ float: 'right' }}
                    onClick={() => setAddCreditConfirmationModalOpen(false)}
                  />
                </Modal.Actions>
              </Modal>
            </div>
          </Segment>
        </Grid.Column>
      </Grid>
      <Segment>
        <Message color="yellow">
          <Header textAlign="center" as="h2">
            Site Visits
          </Header>
          <Header textAlign="center" as="h2">
            Cadence: Monthly
          </Header>
        </Message>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
          <PracticeProfileInfoField
            title="Last Visit:"
            value={
              siteVisitsData?.site_visits?.length > 1
                ? moment(siteVisitsData?.site_visits[1]?.date).format(
                    DateFormats.DATE
                  )
                : '-'
            }
            titleWidth={25}
          />
          <PracticeProfileInfoField
            title="Next Visit:"
            value={
              siteVisitsData?.site_visits?.length > 0 ? (
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  {moment(siteVisitsData?.site_visits[0]?.date).format(
                    DateFormats.DATE
                  )}{' '}
                  (
                  {daysLeftTo(siteVisitsData?.site_visits[0]?.date) == 0
                    ? 'today'
                    : daysLeftTo(siteVisitsData?.site_visits[0]?.date) > 0
                    ? `in ${
                        daysLeftTo(siteVisitsData?.site_visits[0]?.date) + 1
                      } days`
                    : `${
                        daysLeftTo(siteVisitsData?.site_visits[0]?.date) * -1
                      } day/s ago, please schedule next visit`}
                  )
                  <Modal
                    closeIcon
                    trigger={<Icon name="edit" />}
                    onClose={() => setUpdateNextVisitModalOpen(false)}
                    onOpen={() => setUpdateNextVisitModalOpen(true)}
                    open={updateNextVisitModalOpen}
                  >
                    <Modal.Content>
                      <Form
                        onSubmit={() => {
                          handleSavePracticeSiteVisit(
                            updatedNextVisit,
                            siteVisitsData?.site_visits[0]?.id,
                            'update'
                          );
                        }}
                      >
                        <Form.Field>
                          <label>Update Next Visit Date</label>
                          <DateTimePicker
                            tz={practice.timezone}
                            minDate={new Date()}
                            selected={updatedNextVisit}
                            onChange={(d) =>
                              setUpdatedNextVisit(d ? (d as Date) : undefined)
                            }
                            scrollableYearDropdown
                            showYearDropdown
                            showMonthDropdown
                            dropdownMode="select"
                            isClearable
                            data-automation-id="order-form-update-next-visit-date"
                          />
                        </Form.Field>
                        <Modal.Actions>
                          <Button
                            style={{
                              float: 'right',
                              marginBottom: '1rem',
                            }}
                            content="Save"
                            primary
                            icon="save"
                            type="submit"
                          />
                          <Button
                            type="button"
                            content="Cancel"
                            icon="cancel"
                            style={{ float: 'right' }}
                            onClick={() => setUpdateNextVisitModalOpen(false)}
                          />
                        </Modal.Actions>
                      </Form>
                    </Modal.Content>
                  </Modal>
                </div>
              ) : (
                '-'
              )
            }
            titleWidth={25}
          />
          <Modal
            closeIcon
            trigger={<Button content="Add Next Visit" primary />}
            onClose={() => setNextVisitModalOpen(false)}
            onOpen={() => setNextVisitModalOpen(true)}
            open={nextVisitModalOpen}
          >
            <Modal.Content>
              <Form
                onSubmit={() => {
                  handleSavePracticeSiteVisit(nextVisit, '', 'new');
                }}
              >
                <Form.Field>
                  <label>Next Visit Date</label>
                  <DateTimePicker
                    minDate={new Date()}
                    selected={nextVisit}
                    onChange={(d) => setNextVisit(d ? (d as Date) : undefined)}
                    scrollableYearDropdown
                    showYearDropdown
                    showMonthDropdown
                    dropdownMode="select"
                    isClearable
                    data-automation-id="order-form-next-visit-date"
                  />
                </Form.Field>
                <Modal.Actions>
                  <Button
                    style={{
                      float: 'right',
                      marginBottom: '1rem',
                    }}
                    content="Save"
                    primary
                    icon="save"
                    type="submit"
                  />
                  <Button
                    type="button"
                    content="Cancel"
                    icon="cancel"
                    style={{ float: 'right' }}
                    onClick={() => setNextVisitModalOpen(false)}
                  />
                </Modal.Actions>
              </Form>
            </Modal.Content>
          </Modal>
        </div>
      </Segment>

      <Segment>
        <Message color="green">
          <Header textAlign="center" as="h2">
            Open Tickets
          </Header>
        </Message>
        <TicketsList />
      </Segment>

      <Segment>
        <Message color="teal">
          <Header textAlign="center" as="h2">
            Private Orders Pending Confirmation
          </Header>
        </Message>

        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Vaccine</Table.HeaderCell>
              <Table.HeaderCell>Order #</Table.HeaderCell>
              <Table.HeaderCell>Date Placed</Table.HeaderCell>
              <Table.HeaderCell>Expected Arrival</Table.HeaderCell>
              <Table.HeaderCell>Lot #</Table.HeaderCell>
              <Table.HeaderCell>Expire Date</Table.HeaderCell>
              <Table.HeaderCell>Doses</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {privateLoading ? (
              <Table.Row>
                <Table.Cell colSpan={7}>
                  <Segment basic>
                    <Placeholder>
                      <Placeholder.Header>
                        <Placeholder.Line />
                        <Placeholder.Line />
                      </Placeholder.Header>
                    </Placeholder>
                  </Segment>
                </Table.Cell>
              </Table.Row>
            ) : !!privateOrders?.orders.length ? (
              privateOrders.orders.map((o, key) => {
                return (
                  <Table.Row key={key}>
                    <Table.Cell width={3}>
                      <b>{o.vaccine.name}</b> | {o.vaccine.manufacturer} |{' '}
                      {o.vaccine.saleNdc}
                    </Table.Cell>
                    <Table.Cell>{o.orderNumber}</Table.Cell>
                    <Table.Cell>
                      {moment(o.date).format(DateFormats.DATE)}
                    </Table.Cell>
                    <Table.Cell>
                      {moment(o.estimatedShippingDate).format(DateFormats.DATE)}
                    </Table.Cell>
                    <Table.Cell>{o.lot}</Table.Cell>
                    <Table.Cell>
                      {o.inventoryExpiration &&
                      daysLeftTo(o.inventoryExpiration) <= 7 ? (
                        <>
                          {moment(o.inventoryExpiration).format(
                            DateFormats.DATE
                          )}{' '}
                          <Label size="tiny" color="red" basic>
                            {daysLeftTo(o.inventoryExpiration) < 0
                              ? 'Expired'
                              : `${daysLeftTo(
                                  o.inventoryExpiration
                                )} days left`}
                          </Label>
                        </>
                      ) : null}
                    </Table.Cell>
                    <Table.Cell>
                      <>
                        {o.doses}{' '}
                        <Label size="tiny">{o.packagesAmount} vials</Label>
                      </>
                    </Table.Cell>
                  </Table.Row>
                );
              })
            ) : (
              <Table.Row>
                <Table.Cell colSpan={7}>
                  <Message>No orders found.</Message>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell>Total: {privateOrdersTotal}</Table.HeaderCell>
              <Table.HeaderCell colSpan={7} textAlign="right">
                <Pagination
                  activePage={privatePage || 1}
                  disabled={
                    !privateOrdersTotal ||
                    privateOrdersTotal <= ENTRIES_PER_PAGE
                  }
                  boundaryRange={0}
                  siblingRange={1}
                  onPageChange={(e, { activePage }) =>
                    setPrivatePage(activePage as number)
                  }
                  totalPages={totalPrivatePages}
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </Segment>
      <Segment>
        <Message color="orange">
          <Header textAlign="center" as="h2">
            VFC Orders Pending Confirmation
          </Header>
        </Message>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Vaccine</Table.HeaderCell>
              <Table.HeaderCell>Order #</Table.HeaderCell>
              <Table.HeaderCell>Date Placed</Table.HeaderCell>
              <Table.HeaderCell>Expected Arrival</Table.HeaderCell>
              <Table.HeaderCell>Lot #</Table.HeaderCell>
              <Table.HeaderCell>Expire Date</Table.HeaderCell>
              <Table.HeaderCell>Doses</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {vfcLoading ? (
              <Table.Row>
                <Table.Cell colSpan={7}>
                  <Segment basic>
                    <Placeholder>
                      <Placeholder.Header>
                        <Placeholder.Line />
                        <Placeholder.Line />
                      </Placeholder.Header>
                    </Placeholder>
                  </Segment>
                </Table.Cell>
              </Table.Row>
            ) : !!vfcOrders?.orders.length ? (
              vfcOrders.orders.map((o, key) => {
                return (
                  <Table.Row key={key}>
                    <Table.Cell width={3}>
                      <b>{o.vaccine.name}</b> | {o.vaccine.manufacturer} |{' '}
                      {o.vaccine.saleNdc}
                    </Table.Cell>
                    <Table.Cell>{o.orderNumber}</Table.Cell>
                    <Table.Cell>
                      {moment(o.date).format(DateFormats.DATE)}
                    </Table.Cell>
                    <Table.Cell>
                      {moment(o.estimatedShippingDate).format(DateFormats.DATE)}
                    </Table.Cell>
                    <Table.Cell>{o.lot}</Table.Cell>
                    <Table.Cell>
                      {o.inventoryExpiration &&
                      daysLeftTo(o.inventoryExpiration) <= 7 ? (
                        <>
                          {moment(o.inventoryExpiration).format(
                            DateFormats.DATE
                          )}{' '}
                          <Label size="tiny" color="red" basic>
                            {daysLeftTo(o.inventoryExpiration) < 0
                              ? 'Expired'
                              : `${daysLeftTo(
                                  o.inventoryExpiration
                                )} days left`}
                          </Label>
                        </>
                      ) : null}
                    </Table.Cell>
                    <Table.Cell>
                      <>
                        {o.doses}{' '}
                        <Label size="tiny">{o.packagesAmount} vials</Label>
                      </>
                    </Table.Cell>
                  </Table.Row>
                );
              })
            ) : (
              <Table.Row>
                <Table.Cell colSpan={7}>
                  <Message>No orders found.</Message>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell>Total: {vfcOrdersTotal}</Table.HeaderCell>
              <Table.HeaderCell colSpan={7} textAlign="right">
                <Pagination
                  activePage={vfcPage || 1}
                  disabled={
                    !vfcOrdersTotal || vfcOrdersTotal <= ENTRIES_PER_PAGE
                  }
                  boundaryRange={0}
                  siblingRange={1}
                  onPageChange={(e, { activeVfcPage }) =>
                    setVfcPage(activeVfcPage as number)
                  }
                  totalPages={totalVfcPages}
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </Segment>
      <Modal
        size="tiny"
        onClose={() => {
          setOpenSettings(false);
          setIsBillable(billablePracticeData.practice.isBillable);
          setGuardianEnabled(
            settingsData?.practice?.settings?.guardian?.enabled ?? false
          );
        }}
        onOpen={() => setOpenSettings(openSettings)}
        open={openSettings}
        closeIcon
      >
        <Modal.Header>
          <Icon name="setting" style={{ marginRight: '0.6rem' }} />
          Practice Settings
        </Modal.Header>
        <Modal.Content scrolling>
          <Table selectable>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  <label>
                    <b>Enable idle user log out after 20 minutes</b>
                  </label>
                </Table.Cell>
                <Table.Cell textAlign="right">
                  <Form.Radio
                    toggle
                    checked={idleUserLogoutEnabled}
                    onChange={(_, { checked }) => {
                      setIdleUserLogoutEnabled(!!checked);
                      setShowChangeFeaturesConfirmationModal(true);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <label>
                    <b>Suspend Practice</b>
                  </label>
                </Table.Cell>
                <Table.Cell textAlign="right">
                  <Form.Radio
                    toggle
                    checked={suspendedEnabled}
                    onChange={(_, { checked }) => {
                      setSuspendedEnabled(!!checked);
                      setShowSuspendedPracticeConfirm(true);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <Segment>
            <Header as="h4" color="blue">
              Forms
            </Header>
            <Table selectable>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    <label>
                      <b>Enable Celebration Consent Form</b>
                    </label>
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    <Form.Radio
                      toggle
                      checked={celebrationConsentFormEnabled}
                      onChange={(_, { checked }) => {
                        setCelebrationConsentFormEnabled(!!checked);
                        setShowChangeFeaturesConfirmationModal(true);
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Segment>
          <Segment>
            <Header as="h4" color="blue">
              IIS
            </Header>
            <Table selectable>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    <label>
                      <b>Require guardian info</b>
                    </label>
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    <Form.Radio
                      toggle
                      checked={guardianEnabled}
                      onChange={(_, { checked }) => {
                        setGuardianEnabled(!!checked);
                        setShowChangeFeaturesConfirmationModal(true);
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <label>
                      <b>Enable Colorado Certificate of Immunization Form</b>
                    </label>
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    <Form.Radio
                      toggle
                      checked={coloradoCertificateOfImmunizationFormEnabled}
                      onChange={(_, { checked }) => {
                        setColoradoCertificateOfImmunizationFormEnabled(
                          !!checked
                        );
                        setShowChangeFeaturesConfirmationModal(true);
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <label>
                      <b>Enable Georgia 3231 Form</b>
                    </label>
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    <Form.Radio
                      toggle
                      checked={georgia3231FormEnabled}
                      onChange={(_, { checked }) => {
                        setGeorgia3231FormEnabled(!!checked);
                        setShowChangeFeaturesConfirmationModal(true);
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <label>
                      <b>Enable Illinois Physical form</b>
                    </label>
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    <Form.Radio
                      toggle
                      checked={illinoisPhysicalFormEnabled}
                      onChange={(_, { checked }) => {
                        setIllinoisPhysicalFormEnabled(!!checked);
                        setShowChangeFeaturesConfirmationModal(true);
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <label>
                      <b>Enable Florida 680 Form</b>
                    </label>
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    <Form.Radio
                      toggle
                      checked={florida680FormEnabled}
                      onChange={(_, { checked }) => {
                        setFlorida680FormEnabled(!!checked);
                        setShowChangeFeaturesConfirmationModal(true);
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Segment>
          <Segment>
            <Header as="h4" color="blue">
              Inventory
            </Header>
            <Table selectable>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    <label>
                      <b>Enable Inventory Adjustment</b>
                    </label>
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    <Form.Radio
                      toggle
                      checked={inventoryAdjustmentEnabled}
                      onChange={(_, { checked }) => {
                        setInventoryAdjustmentEnabled(!!checked);
                        setShowChangeFeaturesConfirmationModal(true);
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <label>
                      <b>Enable To Add Vaccines Inventory</b>
                    </label>
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    <Form.Radio
                      toggle
                      checked={vaccineInventoryRequestEnabled}
                      onChange={(_, { checked }) => {
                        setVaccineInventoryRequestEnabled(!!checked);
                        setShowChangeFeaturesConfirmationModal(true);
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Segment>
          <Segment>
            <Header as="h4" color="blue">
              Billing
            </Header>
            <Table selectable>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    <label>
                      <b>Billable</b>
                    </label>
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    <Form.Radio
                      toggle
                      checked={isBillable}
                      onChange={(_, { checked }) => {
                        setIsBillable(!!checked);
                        setShowBillablePracticeConfirm(true);
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <label>
                      <b>Show Vaccination List to Biller</b>
                    </label>
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    <Form.Radio
                      toggle
                      checked={showVaxListToBillerEnabled}
                      onChange={(_, { checked }) => {
                        setShowVaxListToBillerEnabled(!!checked);
                        setShowChangeFeaturesConfirmationModal(true);
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <label>
                      <b>Show Billing Dashboard in Practice Portal</b>
                    </label>
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    <Form.Radio
                      toggle
                      checked={showBillingDashboardInPracticePortalEnabled}
                      onChange={(_, { checked }) => {
                        setShowBillingDashboardInPracticePortalEnabled(
                          !!checked
                        );
                        setShowChangeFeaturesConfirmationModal(true);
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Segment>
          <Segment>
            <Header as="h4" color="blue">
              Integrations
            </Header>
            <Dropdown
              style={{ minWidth: '15rem' }}
              placeholder="Select Currently Used Integration"
              fluid
              selection
              value={currentlyUsedIntegration}
              onChange={(e, { value }) => {
                setCurrentlyUsedIntegration(value as string);
              }}
              options={[
                {
                  text: 'Select Integration',
                  value: '',
                },
                {
                  text: 'Athena',
                  value: Integrations.Athena,
                },
                {
                  text: 'Develo',
                  value: Integrations.Develo,
                },
                {
                  text: 'ECW',
                  value: Integrations.ECW,
                },
                {
                  text: 'Office Practicum',
                  value: Integrations.OP,
                },
              ]}
            />
            {currentlyUsedIntegration === Integrations.Athena && (
              <Table selectable>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                      <label>
                        <b>Enable</b>
                      </label>
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      <Form.Radio
                        toggle
                        checked={athenaEnabled}
                        onChange={(_, { checked }) => {
                          setAthenaEnabled(!!checked);
                          setShowChangeFeaturesConfirmationModal(true);
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      <label>
                        <b>Report</b>
                      </label>
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      <Form.Radio
                        toggle
                        checked={athenaReportEnabled}
                        onChange={(_, { checked }) => {
                          setAthenaReportEnabled(!!checked);
                          setShowChangeFeaturesConfirmationModal(true);
                        }}
                        disabled={!athenaEnabled}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      <label>
                        <b>Subscription</b>
                      </label>
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      <Form.Radio
                        toggle
                        checked={athenaSubscriptionEnabled}
                        onChange={(_, { checked }) => {
                          setAthenaSubscriptionEnabled(!!checked);
                          setShowChangeFeaturesConfirmationModal(true);
                        }}
                        disabled={!athenaEnabled}
                      />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            )}
            {currentlyUsedIntegration === Integrations.Develo && (
              <Table selectable>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                      <label>
                        <b>Enable</b>
                      </label>
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      <Form.Radio
                        toggle
                        checked={develoEnabled}
                        onChange={(_, { checked }) => {
                          setDeveloEnabled(!!checked);
                          setShowChangeFeaturesConfirmationModal(true);
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      <label>
                        <b>Report</b>
                      </label>
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      <Form.Radio
                        toggle
                        checked={develoReportEnabled}
                        onChange={(_, { checked }) => {
                          setDeveloReportEnabled(!!checked);
                          setShowChangeFeaturesConfirmationModal(true);
                        }}
                        disabled={!develoEnabled}
                      />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            )}
            {currentlyUsedIntegration === Integrations.ECW && (
              <Table selectable>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                      <label>
                        <b>Enable</b>
                      </label>
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      <Form.Radio
                        toggle
                        checked={botEnabled}
                        onChange={(_, { checked }) => {
                          setBotEnabled(!!checked);
                          setShowChangeFeaturesConfirmationModal(true);
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      <label>
                        <b>Report</b>
                      </label>
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      <Form.Radio
                        toggle
                        checked={botReportEnabled}
                        onChange={(_, { checked }) => {
                          setBotReportEnabled(!!checked);
                          setShowChangeFeaturesConfirmationModal(true);
                        }}
                        disabled={!botEnabled}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      <label>
                        <b>Enable Appointments Sync</b>
                      </label>
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      <Form.Radio
                        toggle
                        checked={retrieveAppointmentsEnabled}
                        onChange={(_, { checked }) => {
                          setRetrieveAppointmentsEnabled(!!checked);
                          setShowChangeFeaturesConfirmationModal(true);
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            )}
            {currentlyUsedIntegration === Integrations.OP && (
              <Table selectable>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                      <label>
                        <b>Enable</b>
                      </label>
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      <Form.Radio
                        toggle
                        checked={opEnabled}
                        onChange={(_, { checked }) => {
                          setOpEnabled(!!checked);
                          setShowChangeFeaturesConfirmationModal(true);
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      <label>
                        <b>Enable Upload Vac CSV</b>
                      </label>
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      <Form.Radio
                        toggle
                        checked={updateVaccinationsCsvEnabled}
                        onChange={(_, { checked }) => {
                          setUpdateVaccinationsCsvEnabled(!!checked);
                          setShowChangeFeaturesConfirmationModal(true);
                        }}
                        disabled={!opEnabled}
                      />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            )}
          </Segment>
          <Segment>
            <Header as="h4" color="blue">
              Slack Notifications
            </Header>
            <Table selectable>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    <label>
                      <b>Enable</b>
                    </label>
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    <Form.Radio
                      toggle
                      checked={notifyImmunizations}
                      onChange={(_, { checked }) => {
                        setNotifyImmunizations(!!checked);
                        setShowChangeFeaturesConfirmationModal(true);
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <label>
                      <b>Channel</b>
                    </label>
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    <Dropdown
                      style={{ minWidth: '15rem' }}
                      placeholder="Select Channel to Report"
                      fluid
                      selection
                      value={channelToReportImmunizations}
                      onChange={(e, { value }) => {
                        setChannelToReportImmunizations(value as string);
                        setShowChangeFeaturesConfirmationModal(true);
                      }}
                      options={[
                        {
                          text: 'Small',
                          value: ChannelsToReport.Small,
                        },
                        {
                          text: 'Medium',
                          value: ChannelsToReport.Medium,
                        },
                        {
                          text: 'Big',
                          value: ChannelsToReport.Big,
                        },
                        {
                          text: 'Real-Time 1',
                          value: ChannelsToReport.RealTime1,
                        },
                        {
                          text: 'Real-Time 2',
                          value: ChannelsToReport.RealTime2,
                        },
                      ]}
                      disabled={!notifyImmunizations}
                    />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Segment>
          <Segment>
            <Header as="h4" color="blue">
              Borrowing
            </Header>
            <Table selectable>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    <label>
                      <b>Enable</b>
                    </label>
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    <Form.Radio
                      toggle
                      checked={borrowingEnabled}
                      onChange={(_, { checked }) => {
                        setBorrowingEnabled(!!checked);
                        setShowChangeFeaturesConfirmationModal(true);
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Segment>
          <Segment>
            <Header as="h4" color="blue">
              Notifications
            </Header>
            <Table selectable>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    <label>
                      <b>Enable</b>
                    </label>
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    <Form.Radio
                      toggle
                      checked={notificationEnabled}
                      onChange={(_, { checked }) => {
                        setNotificationEnabled(!!checked);
                        setShowChangeFeaturesConfirmationModal(true);
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Segment>
        </Modal.Content>
      </Modal>

      <Confirm
        open={showChangeFeaturesConfirmationModal}
        content={
          <StyledConfirm>
            <Icon
              name="warning sign"
              size="big"
              color="red"
              style={{ marginRight: '1rem' }}
            />
            <p>Are you sure you want to change this setting?</p>
          </StyledConfirm>
        }
        cancelButton="No"
        confirmButton="Yes"
        size="mini"
        onCancel={() => {
          setShowChangeFeaturesConfirmationModal(false);

          const _practiceSettings = { ...practiceSettings };

          setPracticeSettings(_practiceSettings);
        }}
        onConfirm={() => {
          handleUpdatePracticeSettings();
          setShowChangeFeaturesConfirmationModal(false);
        }}
      />

      <Confirm
        open={showBillablePracticeConfirm}
        content={
          <StyledConfirm>
            <Icon
              name="warning sign"
              size="big"
              color="red"
              style={{ marginRight: '1rem' }}
            />
            <p>Are you sure you want to change this setting?</p>
          </StyledConfirm>
        }
        cancelButton="No"
        confirmButton="Yes"
        size="mini"
        onCancel={() => {
          setShowBillablePracticeConfirm(false);
          setIsBillable(!isBillable);
        }}
        onConfirm={() => {
          if (!suspendedEnabled) {
            handleUpdateBillablePractice();
          } else {
            setIsBillable(!isBillable);
            toast({
              title: `Cannot set to billable due to Suspended Practice`,
              type: 'error',
              time: 5000,
            });
          }
          setShowBillablePracticeConfirm(false);
        }}
      />

      <Confirm
        open={showSuspendedPracticeConfirm}
        content={
          <StyledConfirm>
            <Icon
              name="warning sign"
              size="big"
              color="red"
              style={{ marginRight: '1rem' }}
            />
            <p>Are you sure you want to change this setting?</p>
          </StyledConfirm>
        }
        cancelButton="No"
        confirmButton="Yes"
        size="mini"
        onCancel={() => {
          setShowSuspendedPracticeConfirm(false);
          setSuspendedEnabled(!suspendedEnabled);
        }}
        onConfirm={() => {
          handleUpdateBillablePractice();
          setShowSuspendedPracticeConfirm(false);
        }}
      />
    </MainLayout>
  );
};

const StyledBtnRow = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const StyledMenuBtnContainer = styled.div`
  width: 8rem;
  display: flex;
  justify-content: center;
`;

const StyledConfirm = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 2rem 1rem 2rem;
  background-color: #fff6f6;
`;

export default PracticeProfile;
