import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { mappingOptions } from '@bluefox/models/integrations/Ecw';
import {
  Card,
  Icon,
  Menu,
  Button,
  Container,
  Dropdown,
} from 'semantic-ui-react';
import MainLayout from '@ui/MainLayout';

interface MappingOptionData {
  target: {
    innerText: string;
  };
}

const EcwIntegrationScreen = () => {
  const history = useHistory();

  const handleMappingTypeValue = (value: string) => {
    history.push(
      `/integration-ecw/${value.split(' ').join('-').toLowerCase()}-mapping`
    );
  };

  return (
    <MainLayout path={[{ text: 'ECW Integration' }]}>
      <Container fluid>
        <Card fluid style={{ marginTop: '1rem' }}>
          <Card.Content>
            <Card.Header as={'h3'}>
              <Icon name="exchange" style={{ marginRight: '0.6rem' }} />
              ECW Integration
            </Card.Header>
            <Card.Description>
              <Menu borderless style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Menu.Item>
                  <Button
                    as={Link}
                    to="/integration-ecw/appointments"
                    primary
                    content="Appointments"
                  />
                </Menu.Item>
                <Menu.Item>
                  <Button
                    as={Link}
                    to="/integration-ecw/bot-reports"
                    primary
                    content="Bot Reports"
                  />
                </Menu.Item>
                <Menu.Item>
                  <StyledDropdown
                    multiple
                    button
                    placeholder="Mappings"
                    fluid
                    onChange={(data: MappingOptionData) => {
                      handleMappingTypeValue(data.target.innerText || '');
                    }}
                    options={mappingOptions}
                  />
                </Menu.Item>
              </Menu>
            </Card.Description>
          </Card.Content>
        </Card>
      </Container>
    </MainLayout>
  );
};

const StyledDropdown = styled(Dropdown)`
  min-width: 10rem;
  height: 2.5rem;
  padding: 0 1.2rem 0 0.6rem !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  color: #ffffff !important;
  background-color: #467597 !important;

  &:hover {
    background-color: #3a6889 !important;
  }
`;

export default EcwIntegrationScreen;
