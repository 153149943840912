import React, { useState, useRef } from 'react';
import { useLazyQuery } from '@apollo/client';
import {
  Container,
  Table,
  Label,
  Icon,
  Menu,
  Button,
  Header,
} from 'semantic-ui-react';
import moment from 'moment-timezone';
import { tsXLXS } from 'ts-xlsx-export';

import {
  InventoryStatus,
  VaccinesStockData,
  InventoryData,
} from '@bluefox/models/Inventory';

import { usePractice } from '@bluefox/contexts/Practice';

import { InventoryQuery } from '@graphql/inventory';

import MainLayout from '@ui/MainLayout';

import { DateFormats } from '@bluefox/models/Dates';
import AdjustmentDetails from '@screens/inventory/AdjustmentDetails';
import Papa from 'papaparse';
import { toast } from 'react-semantic-toasts';
import { InventoryExcelRowType } from './inventory';

interface InventoryComparisonRowType extends InventoryExcelRowType {
  EmrDoses?: number | null;
  EmrDosesRemaining?: number | null;
  Difference?: number | null;
}

interface CsvUploadValues {
  lot: string;
  vfc: string;
  doses: string;
  remaining: string;
}

interface CsvRecord {
  lot: string;
  vfc: boolean;
  doses: number;
  remaining: number;
}

const CompareInventoryScreen = () => {
  const practice = usePractice();

  const [inventorySelected, setInventorySelected] = useState<
    InventoryData | undefined
  >();

  const [csvUploadedData, setCsvUploadedData] = useState<CsvRecord[]>([]);

  const inputFile = useRef<HTMLInputElement>(null);

  const [InventoryLazyQuery, { data }] =
    useLazyQuery<VaccinesStockData>(InventoryQuery);

  const handleUploadCsv = () => {
    inputFile.current?.click();
  };

  const handleCompareCsv = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    const file = event.target.files[0];
    Papa.parse<CsvUploadValues>(file, {
      header: true,
      skipEmptyLines: true,
      encoding: 'UTF-8',
      complete: function (results) {
        try {
          const records: CsvRecord[] = results.data.map((row) => {
            const inventory = {
              lot: row.lot || '',
              vfc: row.vfc.toUpperCase() === 'Y',
              doses: parseInt(row.doses),
              remaining: parseInt(row.remaining),
            };
            return inventory;
          });
          setCsvUploadedData(records);
          InventoryLazyQuery({
            variables: {
              practiceId: practice.id,
              likeQuery: `%%`,
              status: {
                _in: [InventoryStatus.received, InventoryStatus.retired],
              },
              doses: {},
              vfc: {},
              typesFilter: {},
            },
          });
        } catch (error) {
          toast({
            title: `Callback error: ${error}`,
            type: 'error',
            time: 5000,
          });
        }
      },
    });
  };

  const handleLoadXlsx = () => {
    if (!data) return;
    const inventories: InventoryComparisonRowType[] = [];
    data.vaccines.forEach((vaccine) => {
      vaccine.inventory.forEach((inventory) => {
        const remaining = csvUploadedData.find(
          (emrDoses) =>
            emrDoses.lot === inventory.lot && emrDoses.vfc === inventory.vfc
        )?.remaining;
        const doses = csvUploadedData.find(
          (emrDoses) =>
            emrDoses.lot === inventory.lot && emrDoses.vfc === inventory.vfc
        )?.doses;
        inventories.push({
          Practice: practice.name,
          Vaccine: vaccine.name,
          Types: vaccine.types?.join(', ') || '',
          VFC: inventory.vfc ? 'VFC' : '',
          Lot: inventory.lot,
          orderedDoses: inventory.orderedDoses || null,
          vaccinationsCount: inventory.vaccinationsCount || null,
          Doses: inventory.doses,
          EmrDoses: doses || null,
          EmrDosesRemaining: remaining || null,
          Difference: remaining ? inventory.doses - remaining : null,
          Expiration: inventory.expiration,
          Status: inventory.status,
          Manufacturer: vaccine.manufacturer,
          CVX: vaccine.cvx,
          MVX: vaccine.mvx,
          saleNdc: vaccine.saleNdc || '',
          saleNdc10: vaccine.saleNdc10 || '',
          useNdc: vaccine.useNdc || '',
          useNdc10: vaccine.useNdc10 || '',
        });
      });
    });
    tsXLXS()
      .exportAsExcelFile(inventories)
      .saveAsExcelFile(
        `Canid - ${practice.name} Invetory (${moment().format(
          DateFormats.DATE_WITH_TIME_SECONDS
        )})`
      );
  };

  return (
    <MainLayout
      path={[
        { text: 'Practices', to: '/practices' },
        { text: practice.name, to: `/practices/${practice.handler}` },
        { text: 'Inventory', to: `/practices/${practice.handler}/inventory` },
        { text: 'Compare' },
      ]}
    >
      <Container>
        <Menu borderless>
          <Menu.Item position="right">
            <input
              type="file"
              ref={inputFile}
              style={{ display: 'none' }}
              accept=".csv"
              onChange={handleCompareCsv}
            />
            <Button
              icon="file excel"
              content="Upload CSV"
              onClick={handleUploadCsv}
              primary
            />
          </Menu.Item>
          <Menu.Item>
            <Button
              icon="file excel"
              content="Download CSV"
              onClick={handleLoadXlsx}
              color="green"
            />
          </Menu.Item>
        </Menu>
        <Table celled structured selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Vaccine</Table.HeaderCell>
              <Table.HeaderCell>Lot</Table.HeaderCell>
              <Table.HeaderCell>VFC</Table.HeaderCell>
              <Table.HeaderCell>Ordered</Table.HeaderCell>
              <Table.HeaderCell>Vaccinations</Table.HeaderCell>
              <Table.HeaderCell>Doses</Table.HeaderCell>
              <Table.HeaderCell>EMR Doses</Table.HeaderCell>
              <Table.HeaderCell>EMR Doses Remaining</Table.HeaderCell>
              <Table.HeaderCell>Difference</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data?.vaccines.map((v) => (
              <React.Fragment key={v.id}>
                <Table.Row>
                  <Table.Cell rowSpan={v.inventory.length + 1}>
                    <Header>
                      <Header.Content>
                        {v.name}
                        <Label size="mini">{v.saleNdc}</Label>
                        {v.types?.map((t) => (
                          <Label key={t} size="mini" color="orange">
                            {t}
                          </Label>
                        ))}
                      </Header.Content>
                      <Header.Subheader>{v.manufacturer}</Header.Subheader>
                    </Header>
                  </Table.Cell>
                </Table.Row>
                {v.inventory.map((i) => {
                  const notRetired = i.status !== InventoryStatus.retired;
                  const remaining = csvUploadedData.find(
                    (emrDoses) =>
                      emrDoses.lot === i.lot && emrDoses.vfc === i.vfc
                  )?.remaining;
                  const doses = csvUploadedData.find(
                    (emrDoses) =>
                      emrDoses.lot === i.lot && emrDoses.vfc === i.vfc
                  )?.doses;
                  return (
                    <Table.Row key={i.id} textAlign="center">
                      <Table.Cell>{i.lot}</Table.Cell>
                      <Table.Cell>
                        {i.vfc ? (
                          <Label color="orange" content="VFC" />
                        ) : (
                          <Label color="teal" content="Private" />
                        )}
                      </Table.Cell>
                      <Table.Cell>{i.orderedDoses}</Table.Cell>
                      <Table.Cell>{i.vaccinationsCount}</Table.Cell>
                      <Table.Cell
                        warning={notRetired && i.doses < 10 && i.doses > 4}
                        error={notRetired && i.doses < 5}
                      >
                        {notRetired && i.doses < 5 && <Icon name="attention" />}
                        {i.doses}
                      </Table.Cell>
                      <Table.Cell>{doses || '-'}</Table.Cell>
                      <Table.Cell>{remaining || '-'}</Table.Cell>
                      <Table.Cell>
                        {remaining ? i.doses - remaining : '-'}
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </React.Fragment>
            ))}
          </Table.Body>
        </Table>
      </Container>
      {inventorySelected && (
        <AdjustmentDetails
          data={inventorySelected}
          onModalClose={() => setInventorySelected(undefined)}
        />
      )}
    </MainLayout>
  );
};

export default CompareInventoryScreen;
