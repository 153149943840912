import { Message, Placeholder, Segment, Table } from 'semantic-ui-react';
import InvoiceListTableRow from './InvoiceListTableRow';
import { BillingInvoice, InvoiceStatus } from '@bluefox/models/Billing';
import { useEffect, useState } from 'react';

type Props = {
  invoices?: BillingInvoice[];
  loading: boolean;
  onStatusChanged?: (
    billingInvoice: BillingInvoice,
    invoiceStatus: InvoiceStatus
  ) => void;
  onShowPDF?: (billingInvoice: BillingInvoice) => void;
  onEditPaymentDate?: (billingInvoice: BillingInvoice) => void;
  onRegenerateInvoice: (billingInvoice: BillingInvoice) => void;
  onRefetch?: () => void;
};

const InvoiceListTable = ({
  loading,
  invoices,
  onStatusChanged,
  onShowPDF,
  onEditPaymentDate,
  onRegenerateInvoice,
  onRefetch,
}: Props) => {
  const [windowInnerWidth, setWindowInnerWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleWindowResize() {
      setWindowInnerWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <Table selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Practices</Table.HeaderCell>
          <Table.HeaderCell>Invoice Date</Table.HeaderCell>
          <Table.HeaderCell>Amount</Table.HeaderCell>
          <Table.HeaderCell>Billing Method</Table.HeaderCell>
          <Table.HeaderCell>Status</Table.HeaderCell>
          <Table.HeaderCell>Error</Table.HeaderCell>
          <Table.HeaderCell>Payment Date</Table.HeaderCell>
          <Table.HeaderCell>due Date</Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {loading ? (
          <Table.Row>
            <Table.Cell colSpan={10}>
              <Segment basic>
                <Placeholder fluid>
                  <Placeholder.Header>
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Header>
                </Placeholder>
              </Segment>
            </Table.Cell>
          </Table.Row>
        ) : invoices && invoices.length > 0 ? (
          invoices.map((invoice) => {
            return (
              <InvoiceListTableRow
                key={invoice.id}
                billingInvoice={invoice}
                windowInnerWidth={windowInnerWidth}
                onSave={onRefetch}
                onStatusChanged={(billingInvoice, invoiceStatus) =>
                  onStatusChanged?.(billingInvoice, invoiceStatus)
                }
                onShowPDF={(billingInvoice: BillingInvoice) => {
                  onShowPDF?.(billingInvoice);
                }}
                onEditPaymentDate={(billingInvoice) =>
                  onEditPaymentDate?.(billingInvoice)
                }
                onRegenerateInvoiceInStripe={(billingInvoice) => {
                  onRegenerateInvoice?.(billingInvoice);
                }}
              />
            );
          })
        ) : (
          <Table.Row>
            <Table.Cell colSpan={10}>
              <Message>No invoices found.</Message>
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
};

export default InvoiceListTable;
