import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-semantic-toasts';
import StatePicker from '@bluefox/ui/StatePicker';
import { Address } from '@bluefox/models/Address';
import {
  PracticePhone,
  AthenaSettings,
  BotSettings,
  PracticeSettings,
  ImmunizationsSettings,
  PracticeIis,
  Practice,
} from '@bluefox/models/Practice';
import { Button, Modal, Form, Tab, Icon, Menu } from 'semantic-ui-react';
import {
  PracticesQuery,
  SavePracticeMutation,
  PracticeSettingsQuery,
} from '@graphql/practices';
import { usePractice } from '@bluefox/contexts';
import { BillingStrategyMappingsQuery } from '@bluefox/graphql/mappings';
import { GetBillingClaimsMappingByPractice } from '@graphql/mappings';
import {
  Organizations,
  InsertOrganizationAndPractice,
} from '@graphql/organizations';
import moment from 'moment-timezone';
interface PracticeFormModalProps {
  trigger: React.ReactNode;
  onSave: () => void;
}

interface SettingsData {
  practice: {
    settings: PracticeSettings;
  };
}
interface PracticeMappings {
  mappings: {
    id: string;
  }[];
}

interface OrganizationData {
  id: string;
  name: string;
  handler: string;
}

interface OrganizationOptionsData {
  value: string;
  name: string;
  handler: string;
}

const PracticeFormModal = ({ trigger, onSave }: PracticeFormModalProps) => {
  const practice = usePractice();

  const paymentMethodOptions = [
    {
      value: 'auto',
      text: 'Auto',
    },
    {
      value: 'manual',
      text: 'Manual',
    },
  ];

  const { data: practiceSettings } = useQuery<SettingsData>(
    PracticeSettingsQuery,
    {
      variables: {
        id: practice.id,
      },
    }
  );
  const [open, setOpen] = useState(false);
  const [addingNewOrganization, setAddingNewOrganization] = useState(false);
  const [firstName, setFirstName] = useState(
    practice?.profile?.providerFirstName
  );
  const [lastName, setLastName] = useState<string>(
    practice?.profile?.providerLastName || ''
  );
  const [taxId, setTaxId] = useState<string>(
    practice?.profile?.federalTaxId || ''
  );
  const [npi, setNpi] = useState<string>(practice?.npi || '');
  const [orgValue, setOrgValue] = useState<string>(
    practice?.organization?.id || ''
  );

  const [handler, setHandler] = useState<string>(
    practice?.organization?.handler || ''
  );
  const [practiceName, setPracticeName] = useState<string>(
    practice?.name || ''
  );
  const [practiceHandler, setPracticeHandler] = useState<string>(
    practice?.handler || ''
  );
  const [address, setAddress] = useState<Address>(
    practice?.profile?.address
      ? (practice?.profile.address as Address)
      : {
          zip: '',
          city: '',
          state: '',
          street: '',
          zipExtension: '',
        }
  );
  const [iis, setIss] = useState<PracticeIis>({
    env: '',
    url: '',
  });
  const [athena, setAthena] = useState<AthenaSettings>({
    report: false,
    enabled: false,
    practiceId: '',
    departmentId: '',
    subscription: false,
  });
  const [botSettings, setBotSettings] = useState<BotSettings>({
    emr: '',
    url: '',
    report: false,
    enabled: false,
    addCodeToEcw: false,
    retrieveAppointments: {
      enabled: false,
    },
  });
  const [phone, setPhone] = useState<PracticePhone>(
    practice?.profile?.phone
      ? (practice?.profile.phone as PracticePhone)
      : {
          mobile: '',
          fax: '',
          work: '',
          other: '',
        }
  );
  const [immunizationsSettings, setImmunizationsSettings] =
    useState<ImmunizationsSettings>({ notifyNew: false });
  const [extensionFlag, setExtensionFlag] = useState<boolean>(false);
  const [notes, setNotes] = useState<string>(practice?.profile?.notes || '');
  const [email, setEmail] = useState<string>(practice?.profile?.email || '');
  const [internalEmail, setInternalEmail] = useState<string>(
    practice?.profile?.internalEmail || ''
  );
  const [emrUrl, setEmrUrl] = useState<string>(practice?.profile?.emrUrl || '');
  const [iisName, setIisName] = useState<string>(practice?.issName || '');
  const [iisAlias, setIisAlias] = useState<string>(practice?.iisAlias || '');
  const allTimeZones = moment.tz.names();
  const filteredTimeZones = allTimeZones.filter((timeZone) =>
    timeZone.includes('America/')
  );
  const [timeZone, setTimeZone] = useState<string>(practice?.timezone || '');
  const [practiceMappingId, setPracticeMappingId] = useState<
    string | undefined
  >(undefined);
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [organizationsOptions, setOrganizationsOptions] = useState([]);
  const generalInfoWarning =
    orgValue &&
    handler &&
    practiceName &&
    practiceHandler &&
    timeZone &&
    npi &&
    taxId &&
    firstName &&
    lastName;
  const contactInfoWarning =
    address.street &&
    address.city &&
    address.state &&
    address.zip &&
    phone.work &&
    address.zipExtension;

  const [savePractice] = useMutation(SavePracticeMutation);
  const [createOrganization] = useMutation(InsertOrganizationAndPractice);
  const handleKeyPress = (event: any) => {
    const allowedChars = /^[a-zA-Z0-9- ]+$/; // Expresión regular para letras, números y guiones
    if (!allowedChars.test(event.key)) {
      event.preventDefault(); // Evita que se muestre el caracter ingresado si no es válido
    }
  };

  const { data: practiceData } = useQuery<{ practices: Practice[] }>(
    PracticesQuery,
    {
      variables: {
        practiceName: practice.name,
      },
    }
  );

  const { data: practiceMapping } = useQuery<PracticeMappings>(
    GetBillingClaimsMappingByPractice,
    {
      variables: {
        practiceId: practice.id,
      },
      skip: practice.id === '',
    }
  );

  const { data: organizationsData, refetch: organizationsRefetch } =
    useQuery(Organizations);

  useEffect(() => {
    if (!organizationsData) return;
    setOrganizationsOptions(
      organizationsData.organizations.map((o: OrganizationData, i: number) => {
        return {
          key: i,
          value: o.id,
          text: o.name,
          handler: o.handler,
        };
      })
    );
  }, [organizationsData]);

  useEffect(() => {
    if (!practiceSettings) return;
    setAthena(
      practiceSettings?.practice.settings.athena || {
        report: false,
        enabled: false,
        practiceId: '',
        departmentId: '',
        subscription: false,
      }
    );
    setBotSettings(
      practiceSettings?.practice.settings.bot || {
        emr: '',
        url: '',
        report: false,
        enabled: false,
        addCodeToEcw: false,
        retrieveAppointments: {
          enabled: false,
        },
      }
    );
    setIss(
      practiceSettings?.practice.settings.iis || {
        env: '',
        url: '',
      }
    );
    setExtensionFlag(
      practiceSettings?.practice.settings.extensionFlag || false
    );
    setImmunizationsSettings(
      practiceSettings.practice.settings.immunizations || {
        notifyNew: false,
      }
    );
  }, [practiceSettings]);

  useEffect(() => {
    if (!practiceMapping || practiceMapping.mappings.length === 0) return;
    setPracticeMappingId(practiceMapping.mappings[0].id);
  }, [practiceMapping]);

  const handleMutationToExec = () => {
    return addingNewOrganization ? createOrganization : savePractice;
  };

  const buildMutationVariables = (practiceObject: any) => {
    if (!addingNewOrganization) {
      return {
        variables: {
          obj: practiceObject,
        },
        refetchQueries: [
          {
            query: PracticesQuery,
            variables: {
              practiceName: practice.name,
            },
          },
          {
            query: GetBillingClaimsMappingByPractice,
            variables: {
              practiceId: practice.id,
            },
          },
          {
            query: BillingStrategyMappingsQuery,
            variables: {
              practiceId: practice.id,
              strategy: 'practice',
              limit: 15,
              offset: 0,
            },
          },
        ],
      };
    }
    return {
      variables: {
        handler,
        name: orgValue,
        data: [practiceObject],
      },
    };
  };

  const handleSubmit = () => {
    setOpen(false);
    const mutationToExec = handleMutationToExec();
    const practiceObject = {
      ...(practice.id ? { id: practice.id } : {}),
      ...(!addingNewOrganization ? { organizationId: orgValue } : {}),
      handler: practiceHandler,
      name: practiceName,
      npi,
      timezone: timeZone,
      issName: iisName,
      iisAlias: iisAlias,
      profile: {
        ...practice.profile,
        federalTaxId: taxId,
        providerFirstName: firstName,
        providerLastName: lastName,
        paymentInformation:
          practiceData?.practices[0]?.profile?.paymentInformation,
        address: {
          zip: address.zip,
          zipExtension: address.zipExtension,
          city: address.city,
          state: address.state,
          street: address.street,
        },
        phone: {
          mobile: phone.mobile,
          fax: phone.fax,
          work: phone.work,
          other: phone.other,
        },
        email: email,
        internalEmail: internalEmail,
        notes: notes,
        emrUrl: emrUrl,
      },
      settings: {
        ...practiceSettings?.practice.settings,
        bot: {
          ...practiceSettings?.practice?.settings?.bot,
          emr: botSettings.emr,
          url: botSettings.url,
          report: botSettings.report,
          enabled: botSettings.enabled,
          addCodeToEcw: botSettings.addCodeToEcw,
          retrieveAppointments: {
            enabled: botSettings.retrieveAppointments?.enabled,
          },
        },
        iis: {
          env: iis.env,
          url: iis.url,
        },
        athena: {
          report: athena?.report,
          enabled: athena?.enabled,
          practiceId: athena?.practiceId,
          departmentId: athena?.departmentId,
          subscription: athena?.subscription,
        },
        extensionFlag: extensionFlag,
        immunizations: {
          notifyNew: immunizationsSettings.notifyNew,
        },
      },
      claimMappings: {
        data: [
          {
            ...(practiceMappingId ? { id: practiceMappingId } : {}),
            npi: npi,
            taxId: taxId,
            strategy: 'practice',
          },
        ],
        on_conflict: {
          constraint: 'claim_mappings_pkey',
          update_columns: ['npi', 'taxId'],
        },
      },
      suspended: false,
    };

    const mutationVariables = buildMutationVariables(practiceObject);

    mutationToExec(mutationVariables)
      .then((r) => {
        toast({
          title: practice.id
            ? 'Practice has been updated successfully'
            : 'Practice has been registered successfully',
          type: 'success',
          time: 1000,
        });
        onSave();
        !practice.organization?.id &&
          cleanAndClose(!!practice.organization?.id);
        setActiveTabIndex(0);
        organizationsRefetch();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  const optionsIssName = [
    { key: 'NYSIIS', text: 'NYSIIS', value: 'nysiis' },
    { key: 'CIR', text: 'CIR', value: 'cir' },
    { key: 'FLORIDA-SHOTS', text: 'FLORIDA-SHOTS', value: 'florida_shots' },
  ];

  const tabs = [
    {
      menuItem: (
        <Menu.Item key="generalInformation">
          <Icon name="hospital" />
          General information{' '}
          {!generalInfoWarning && <Icon name="attention" color="red" />}
        </Menu.Item>
      ),
      pane: (
        <Tab.Pane>
          <Form.Group>
            {addingNewOrganization ? (
              <Form.Input
                value={orgValue}
                fluid
                label="Organization Name"
                placeholder="Insert Organization Name"
                required
                onChange={(e, data) => {
                  setOrgValue(data.value);
                  !practice?.id &&
                    setHandler(data.value.toLowerCase().replaceAll(' ', ''));
                }}
                error={!orgValue}
                onKeyPress={handleKeyPress}
                width={3}
              />
            ) : (
              <Form.Dropdown
                value={orgValue}
                label="Organization Name"
                placeholder="Select an Organization"
                fluid
                selection
                required
                error={!orgValue}
                onChange={(e, data) => {
                  !practice?.id &&
                    setHandler(
                      (
                        organizationsOptions?.find(
                          (o: OrganizationOptionsData) => o.value === data.value
                        ) as unknown as OrganizationOptionsData
                      ).handler
                    );
                  setOrgValue(data.value as string);
                }}
                options={organizationsOptions}
                width={3}
              />
            )}

            <Form.Input
              value={handler}
              fluid
              label="Organization Handler"
              placeholder="Handler - Only letters, numbers and '-'"
              required
              error={!handler}
              onKeyPress={handleKeyPress}
              onChange={(_, { value }) => setHandler(value)}
              disabled={!!practice?.id}
              width={3}
            />

            {!practice.id && (
              <Form.Input
                label="Add New Organization"
                placeholder="Add New Organization"
              >
                <Form.Checkbox
                  onClick={(e) => {
                    e.preventDefault();
                    if (!addingNewOrganization) {
                      setAddingNewOrganization(true);
                      setOrgValue('');
                      setHandler('');
                    } else {
                      setAddingNewOrganization(false);
                    }
                  }}
                  primary
                  toggle
                  slider
                />
              </Form.Input>
            )}

            <Form.Input
              value={practiceName}
              fluid
              label="Practice Name"
              placeholder="Insert Practice Name"
              required
              onChange={(e, data) => {
                setPracticeName(data.value);
                !practice?.id &&
                  setPracticeHandler(
                    data.value.toLowerCase().replaceAll(' ', '')
                  );
              }}
              error={!practiceName}
              onKeyPress={handleKeyPress}
              width={3}
            />

            <Form.Input
              value={practiceHandler}
              fluid
              label="Practice Handler"
              placeholder="Handler - Only letters, numbers and '-'"
              required
              error={!practiceHandler}
              onKeyPress={handleKeyPress}
              onChange={(_, { value }) => setPracticeHandler(value)}
              disabled={!!practice?.id}
              width={3}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Dropdown
              label="Time Zone"
              placeholder="Select Time Zone"
              options={filteredTimeZones.map((timeZone) => ({
                key: timeZone,
                text: timeZone,
                value: timeZone,
              }))}
              search
              fluid
              value={timeZone}
              onChange={(e, data) => {
                setTimeZone(data.value as string);
              }}
              selection
              required
              error={!timeZone}
            />
            <Form.Input
              value={npi}
              onChange={(_, { value }) => setNpi(value)}
              fluid
              label="NPI"
              placeholder="NPI"
              required
              error={!npi}
            />
            <Form.Input
              value={taxId}
              onChange={(_, { value }) => setTaxId(value)}
              fluid
              label="Federal Tax ID"
              placeholder="Federal Tax ID"
              required
              error={!taxId}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              value={firstName}
              onChange={(_, { value }) => setFirstName(value)}
              fluid
              label="Provider First Name"
              placeholder="Provider First Name"
              required
              error={!firstName}
            />
            <Form.Input
              value={lastName}
              onChange={(_, { value }) => setLastName(value)}
              fluid
              label="Provider Last Name"
              placeholder="Provider Last Name"
              required
              error={!lastName}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input label="Extension Flag" placeholder="Extension Flag">
              <Form.Checkbox
                toggle
                onChange={(_, value) =>
                  setExtensionFlag(value.checked || false)
                }
                fluid
                checked={extensionFlag}
              />
            </Form.Input>
            <Form.Input
              value={emrUrl}
              onChange={(_, { value }) => setEmrUrl(value)}
              fluid
              label="EMR Url"
              placeholder="EMR Url"
            />
          </Form.Group>
        </Tab.Pane>
      ),
    },
    {
      menuItem: (
        <Menu.Item key="contactInformation">
          <Icon name="phone" />
          Contact Information{' '}
          {!contactInfoWarning && <Icon name="attention" color="red" />}
        </Menu.Item>
      ),
      pane: (
        <Tab.Pane>
          <Form.Group widths="equal">
            <Form.Input
              value={address.street}
              onChange={(_, { value }) =>
                setAddress({ ...address, street: value })
              }
              fluid
              label="Street"
              placeholder="Street"
              required
              error={!address.street}
            />
            <Form.Input
              value={address.city}
              onChange={(_, { value }) =>
                setAddress({ ...address, city: value })
              }
              fluid
              label="City"
              placeholder="City"
              required
              error={!address.city}
            />
            <Form.Field required error={!address.state}>
              <label>State</label>
              <StatePicker
                fluid
                value={address.state}
                onChange={(value) => setAddress({ ...address, state: value })}
              />
            </Form.Field>
            <Form.Input
              value={address.zip}
              onChange={(_, { value }) =>
                setAddress({ ...address, zip: value })
              }
              fluid
              label="Zip Code"
              placeholder="Zip Code"
              required
              error={!address.zip}
            />
            <Form.Input
              value={address.zipExtension}
              onChange={(_, { value }) =>
                setAddress({ ...address, zipExtension: value })
              }
              fluid
              label="Zip Extension"
              placeholder="Zip Extension"
              required
              error={!address.zipExtension}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              value={phone?.mobile}
              onChange={(_, { value }) => setPhone({ ...phone, mobile: value })}
              fluid
              label="Mobile Phone"
              placeholder="Mobile Phone"
            />
            <Form.Input
              value={phone?.fax}
              onChange={(_, { value }) => setPhone({ ...phone, fax: value })}
              fluid
              label="Fax"
              placeholder="Fax"
            />
            <Form.Input
              value={phone?.work}
              onChange={(_, { value }) => setPhone({ ...phone, work: value })}
              fluid
              label="Work Phone"
              placeholder="Fax"
              required
              error={!phone.work}
            />
            <Form.Input
              value={phone?.other}
              onChange={(_, { value }) => setPhone({ ...phone, other: value })}
              fluid
              label="Other Phone"
              placeholder="Other Phone"
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              value={email}
              onChange={(_, { value }) => setEmail(value)}
              fluid
              label="Organization Email"
              placeholder="Organization Email"
            />
            <Form.Input
              value={internalEmail}
              onChange={(_, { value }) => setInternalEmail(value)}
              fluid
              label="Internal Email"
              placeholder="Internal Email"
            />
          </Form.Group>
        </Tab.Pane>
      ),
    },
    {
      menuItem: (
        <Menu.Item key="iisInformation">
          <Icon name="info" />
          Immunization Information Systems{' '}
        </Menu.Item>
      ),
      pane: (
        <Tab.Pane>
          <Form.Group widths="equal">
            <Form.Dropdown
              value={iisName}
              options={optionsIssName}
              onChange={(e, data) => {
                setIisName(data.value as string);
              }}
              fluid
              selection
              placeholder="Iis Name"
              label="Iis Name"
            />
            <Form.Input
              value={iisAlias}
              onChange={(_, { value }) => {
                setIisAlias(value);
              }}
              fluid
              label="Iis Alias"
              placeholder="Iis Alias"
              //required
            />
            <Form.Input
              required={!!iisName}
              value={iis.env}
              onChange={(_, { value }) => {
                setIss({ ...iis, env: value });
              }}
              fluid
              label="Iis Env"
              placeholder="Iis Env"
              //required
            />
            <Form.Input
              required={!!iisName}
              value={iis.url}
              onChange={(_, { value }) => {
                setIss({ ...iis, url: value });
              }}
              fluid
              label="Iis Url"
              placeholder="Iis Url"
            />
          </Form.Group>
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        key: 'athenaConfiguration',
        icon: 'user md',
        content: 'Athena Configuration',
      },
      pane: (
        <Tab.Pane>
          <Form.Group widths="equal">
            <Form.Input
              value={athena.practiceId}
              onChange={(_, { value }) =>
                setAthena({ ...athena, practiceId: value })
              }
              fluid
              label="Practice ID"
              placeholder="Practice ID"
            />
            <Form.Input
              value={athena.departmentId}
              onChange={(_, { value }) =>
                setAthena({ ...athena, departmentId: value })
              }
              fluid
              label="Departament ID"
              placeholder="Departament ID"
            />
            <Form.Input label="Report">
              <Form.Checkbox
                toggle
                onChange={(_, value) =>
                  setAthena({ ...athena, report: value.checked || false })
                }
                fluid
                checked={athena.report}
              />
            </Form.Input>
            <Form.Input label="Enabled">
              <Form.Checkbox
                toggle
                onChange={(_, value) =>
                  setAthena({ ...athena, enabled: value.checked || false })
                }
                fluid
                checked={athena.enabled}
              />
            </Form.Input>
            <Form.Input label="Subscription">
              <Form.Checkbox
                toggle
                onChange={(_, value) =>
                  setAthena({ ...athena, subscription: value.checked || false })
                }
                fluid
                checked={athena.subscription}
              />
            </Form.Input>
          </Form.Group>
        </Tab.Pane>
      ),
    },
    {
      menuItem: (
        <Menu.Item key="botConfiguration">
          <Icon name="bug" />
          Bot Configuration{' '}
        </Menu.Item>
      ),
      pane: (
        <Tab.Pane>
          <Form.Group widths="equal">
            <Form.Input
              value={botSettings.emr}
              onChange={(_, { value }) =>
                setBotSettings({ ...botSettings, emr: value })
              }
              fluid
              label="EMR"
              placeholder="EMR"
            />
            <Form.Input
              value={botSettings.url}
              onChange={(_, { value }) =>
                setBotSettings({ ...botSettings, url: value })
              }
              fluid
              label="Url"
              placeholder="Url"
            />
            <Form.Input label="Report">
              <Form.Checkbox
                toggle
                onChange={(_, value) => {
                  setBotSettings({
                    ...botSettings,
                    report: value.checked || false,
                  });
                }}
                fluid
                checked={botSettings.report}
              />
            </Form.Input>
            <Form.Input label="Enabled">
              <Form.Checkbox
                toggle
                onChange={(_, value) =>
                  setBotSettings({
                    ...botSettings,
                    enabled: value.checked || false,
                  })
                }
                fluid
                checked={botSettings.enabled}
              />
            </Form.Input>
            <Form.Input label="Add Code To Ecw">
              <Form.Checkbox
                toggle
                onChange={(_, value) =>
                  setBotSettings({
                    ...botSettings,
                    addCodeToEcw: value.checked || false,
                  })
                }
                fluid
                checked={botSettings.addCodeToEcw}
              />
            </Form.Input>
            <Form.Input label="Retrieve Appointments">
              <Form.Checkbox
                toggle
                onChange={(_, value) =>
                  setBotSettings({
                    ...botSettings,
                    retrieveAppointments: {
                      enabled: value.checked || false,
                    },
                  })
                }
                fluid
                checked={botSettings.retrieveAppointments?.enabled}
              />
            </Form.Input>
          </Form.Group>
        </Tab.Pane>
      ),
    },

    {
      menuItem: {
        key: 'notes',
        icon: 'sticky note outline',
        content: 'Notes',
      },
      pane: (
        <Tab.Pane>
          <Form.Group widths="equal">
            <Form.TextArea
              value={notes}
              onChange={(_, { value }) => setNotes(value as string)}
              fluid
              label="Notes"
              placeholder="Notes"
            />
          </Form.Group>
        </Tab.Pane>
      ),
    },
  ];

  const handleTabChange = (activeIndex: number) => {
    setActiveTabIndex(activeIndex);
  };

  const cleanAndClose = (isEditing: boolean) => {
    setAddingNewOrganization(false);
    setExtensionFlag(isEditing ? practice.profile.extensionFlag : false);
    setOrgValue(
      isEditing && practice.organization ? practice.organization?.id : ''
    );
    setHandler(
      isEditing && practice.organization?.handler
        ? practice.organization?.handler
        : ''
    );
    setFirstName(isEditing ? practice.profile.providerFirstName : '');
    setLastName(
      isEditing && practice.profile.providerLastName
        ? practice.profile.providerLastName
        : ''
    );
    setTaxId(
      isEditing && practice.profile.federalTaxId
        ? practice.profile.federalTaxId
        : ''
    );
    setNpi(isEditing && practice.npi ? practice.npi : '');
    setPracticeName(isEditing ? practice.name : '');
    setPracticeHandler(isEditing ? practice.handler : '');
    setAddress({
      zip:
        isEditing && practice.profile.address
          ? practice.profile.address.zip
          : '',
      city:
        isEditing && practice.profile.address
          ? practice.profile.address.city
          : '',
      state:
        isEditing && practice.profile.address
          ? practice.profile.address.state
          : '',
      street:
        isEditing && practice.profile.address
          ? practice.profile.address.street
          : '',
      zipExtension:
        isEditing && practice.profile.address
          ? practice.profile.address.zipExtension
          : '',
    });
    setIss({
      env:
        isEditing && practiceSettings?.practice.settings.iis
          ? practiceSettings?.practice.settings.iis.env
          : '',
      url:
        isEditing && practiceSettings?.practice.settings.iis
          ? practiceSettings?.practice.settings.iis.url
          : '',
    });
    setAthena({
      report:
        isEditing && practiceSettings?.practice.settings.athena
          ? practiceSettings.practice.settings.athena.report
          : false,
      enabled:
        isEditing && practiceSettings?.practice.settings.athena
          ? practiceSettings.practice.settings.athena.enabled
          : false,
      practiceId:
        isEditing && practiceSettings?.practice.settings.athena
          ? practiceSettings.practice.settings.athena.practiceId
          : '',
      departmentId:
        isEditing && practiceSettings?.practice.settings.athena
          ? practiceSettings.practice.settings.athena.departmentId
          : '',
      subscription:
        isEditing && practiceSettings?.practice.settings.athena
          ? practiceSettings.practice.settings.athena.subscription
          : false,
    });
    setBotSettings({
      emr:
        isEditing && practiceSettings?.practice.settings.bot
          ? practiceSettings.practice.settings.bot.emr
          : '',
      url:
        isEditing && practiceSettings?.practice.settings.bot
          ? practiceSettings.practice.settings.bot.url
          : '',
      report:
        isEditing && practiceSettings?.practice.settings.bot
          ? practiceSettings.practice.settings?.bot?.report
          : false,
      enabled:
        isEditing && practiceSettings?.practice.settings.bot
          ? practiceSettings.practice.settings?.bot?.enabled
          : false,
      addCodeToEcw:
        isEditing && practiceSettings?.practice.settings.bot
          ? practiceSettings.practice.settings?.bot?.addCodeToEcw
          : false,
      retrieveAppointments: {
        enabled:
          isEditing && practiceSettings?.practice.settings.bot
            ? practiceSettings.practice.settings?.bot?.retrieveAppointments
                ?.enabled
            : false,
      },
    });
    setPhone({
      mobile:
        isEditing && practice.profile.phone
          ? practice.profile.phone.mobile
          : '',
      fax:
        isEditing && practice.profile.phone ? practice.profile.phone.fax : '',
      work:
        isEditing && practice.profile.phone ? practice.profile.phone.work : '',
      other:
        isEditing && practice.profile.phone ? practice.profile.phone.other : '',
    });
    setNotes(isEditing && practice.profile.notes ? practice.profile.notes : '');
    setEmail(isEditing && practice.profile.email ? practice.profile.email : '');
    setInternalEmail(
      isEditing && practice.profile.internalEmail
        ? practice.profile.internalEmail
        : ''
    );
    setEmrUrl(
      isEditing && practice.profile.emrUrl ? practice.profile.emrUrl : ''
    );
    setIisName(isEditing && practice.issName ? practice.issName : '');
    setIisAlias(isEditing && practice.iisAlias ? practice.iisAlias : '');
    setTimeZone(isEditing && practice.timezone ? practice.timezone : '');
    setActiveTabIndex(0);
    setOpen(false);
  };

  return (
    <Modal
      size="fullscreen"
      onClose={() =>
        practice.id ? cleanAndClose(!!practice.id) : setOpen(false)
      }
      onOpen={() => setOpen(true)}
      open={open}
      closeIcon
      trigger={trigger}
    >
      <Modal.Header>
        {practice.id ? 'Edit Practice' : 'Add Practice'}
      </Modal.Header>
      <Modal.Content>
        <Form onSubmit={handleSubmit}>
          <Modal.Description>
            <Tab
              panes={tabs}
              renderActiveOnly={false}
              activeIndex={activeTabIndex}
              onTabChange={(e, data) =>
                handleTabChange(data.activeIndex as number)
              }
            />
            <Modal.Actions>
              <Button
                style={{
                  float: 'right',
                  marginBottom: '1rem',
                }}
                content="Save"
                primary
                icon="save"
                type="submit"
                disabled={!generalInfoWarning || !contactInfoWarning}
              />
              <Button
                type="button"
                content="Cancel"
                icon="cancel"
                style={{ float: 'right' }}
                onClick={() => setOpen(false)}
              />
            </Modal.Actions>
          </Modal.Description>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default PracticeFormModal;
