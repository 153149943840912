import { gql } from '@apollo/client';

export const InconsistentVaccinationsQuery = gql`
  query InconsistentVaccinationsQuery(
    $criteria: vaccinations_bool_exp
    $limit: Int
    $offset: Int
  ) {
    vaccinations(where: $criteria, limit: $limit, offset: $offset) {
      id
      barcode
      givenAt
      notes
      vaccine {
        id
        name
        allowedAssociatedVaccinations
      }
      practice {
        id
        handler
        name
      }
      practicePatient {
        id
        patientData {
          firstName
          lastName
          birthdate
        }
      }
    }
    aggregating: vaccinations_aggregate(where: $criteria) {
      aggregate {
        count
      }
    }
  }
`;

export const HistoricInconsistentVaccinationsQuery = gql`
  query HistoricInconsistentVaccinationsQuery($practiceId: uuid) {
    vaccinations(
      where: {
        historic: { _eq: false }
        inconsistentVaccinationError: { _is_null: false }
        practiceId: { _eq: $practiceId }
      }
    ) {
      id
      barcode
      givenAt
      notes
      inconsistentVaccinationError
      vaccine {
        id
        name
      }
      practice {
        id
        handler
        name
      }
      practicePatient {
        id
        patientData {
          firstName
          lastName
          birthdate
        }
      }
    }
  }
`;

export const InconsistentVaccinationErrorEnumQuery = gql`
  query InconsistentVaccinationErrorEnumQuery {
    inconsistentVaccinationErrorsEnum: inconsistent_vaccination_errors_enum {
      value
      comment
    }
  }
`;

export const SetVaccinationInventoryMutation = gql`
  mutation SetVaccinationInventoryMutation(
    $vaccinationId: uuid!
    $inventoryId: uuid!
    $vaccineId: uuid
    $inconsistentVaccinationError: inconsistent_vaccination_errors_enum_enum
    $notes: jsonb
  ) {
    update_vaccinations_by_pk(
      pk_columns: { id: $vaccinationId }
      _set: {
        inventoryId: $inventoryId
        vaccineId: $vaccineId
        inconsistentVaccinationError: $inconsistentVaccinationError
        notes: $notes
      }
    ) {
      id
    }
  }
`;

export const SelfPayVaccinationsQuery = gql`
  query SelfPayVaccinationsQuery(
    $criteria: vaccinations_bool_exp!
    $vfcEligible: [Boolean!]
    $limit: Int
    $offset: Int
  ) {
    aggregating: vaccinations_aggregate(where: $criteria) {
      aggregate {
        count
      }
    }
    vaccinations(
      where: $criteria
      limit: $limit
      offset: $offset
      order_by: { practicePatient: { patientData: { fullName: asc } } }
    ) {
      id
      reviewedAt
      vaccine {
        id
        name
        types
        cvx
        selfPayPrice
        saleNdc
      }
      inventory {
        vfc
      }
      givenAt
      practicePatient {
        practice {
          id
          name
        }
        patientData {
          firstName
          lastName
          birthdate
          fullName
        }
        insurances(
          where: { primary: { _eq: true }, vfcEligible: { _in: $vfcEligible } }
        ) {
          memberId
          vfcEligible
          active
          company {
            name
          }
        }
      }
    }
    canidProfile: practices(where: { handler: { _eq: "canid" } }) {
      profile
    }
    allPractices: practices(
      distinct_on: id
      where: { isBillable: { _eq: true } }
    ) {
      id
      name
    }
  }
`;

export const SetVaccinationReviewedAtMutation = gql`
  mutation SetVaccinationReviewedAtMutation(
    $vaccinationId: uuid!
    $reviewedAt: timestamptz!
  ) {
    vaccination: update_vaccinations_by_pk(
      pk_columns: { id: $vaccinationId }
      _set: { reviewedAt: $reviewedAt }
    ) {
      id
      reviewedAt
    }
  }
`;

export const VaccinationsWithErrorsQuery = gql`
  query VaccinationsWithErrorsQuery {
    aggregating: vaccinations_aggregate(
      where: { immunizationReportError: { _contains: { type: "FATAL_ERROR" } } }
    ) {
      aggregate {
        count
      }
    }
    vaccinations(
      where: { immunizationReportError: { _contains: { type: "FATAL_ERROR" } } }
    ) {
      id
      practice {
        name
      }
      practicePatient {
        mrn
        patientData {
          firstName
          lastName
          birthdate
        }
      }
      givenAt
      vaccine {
        name
      }
      inventory {
        lot
      }
      immunizationReportError
    }
    aggregatingRemoved: vaccinations_removed_aggregate(
      where: { immunizationReportError: { _contains: { type: "FATAL_ERROR" } } }
    ) {
      aggregate {
        count
      }
    }
    vaccinationsRemoved: vaccinations_removed(
      where: { immunizationReportError: { _contains: { type: "FATAL_ERROR" } } }
    ) {
      id
      practice {
        name
      }
      practicePatient {
        mrn
        patientData {
          firstName
          lastName
          birthdate
        }
      }
      givenAt
      vaccine {
        name
      }
      inventory {
        lot
      }
      immunizationReportError
    }
  }
`;

export const ReportImmunizationMutation = gql`
  mutation ReportImmunizationMutation($vaccinationId: uuid!, $action: String!) {
    ReportImmunization(vaccinationId: $vaccinationId, action: $action) {
      body
      message
    }
  }
`;

export const UpdateVaccinationsCsv = gql`
  mutation MyMutation(
    $practiceId: uuid!
    $data: [onboarding_immunizations_insert_input!]!
  ) {
    insert_onboarding_jobs_one(
      object: {
        practiceId: $practiceId
        status: pending
        type: add_immunizations
        immunizations: { data: $data }
      }
    ) {
      id
    }
  }
`;

export const GetOnboardingJobs = gql`
  query GetOnboardingJobs(
    $criteria: onboarding_jobs_bool_exp
    $limit: Int
    $offset: Int
  ) {
    onboardingJobs: onboarding_jobs(
      where: $criteria
      limit: $limit
      offset: $offset
      order_by: { uploadNumber: desc }
    ) {
      id
      status
      createdAt
      uploadNumber
      immunizations_aggregate {
        aggregate {
          count
        }
      }
    }
    aggregating: onboarding_jobs_aggregate(where: $criteria) {
      aggregate {
        count
      }
    }
  }
`;

export const GetOnboardingJobDetails = gql`
  query GetOnboardingJobDetails(
    $criteria: onboarding_jobs_bool_exp
    $limit: Int
    $offset: Int
    $immunizationsCriteria: onboarding_immunizations_bool_exp
  ) {
    job: onboarding_jobs(where: $criteria) {
      status
      uploadNumber
      createdAt
      immunizations(
        where: $immunizationsCriteria
        limit: $limit
        offset: $offset
      ) {
        id
        idEmr
        patno
        status
        statusReason
        vaccineDate
        vaccineName
      }
      aggregating: immunizations_aggregate(where: $immunizationsCriteria) {
        aggregate {
          count
        }
      }
    }
  }
`;

export const GetOnboardingStatuses = gql`
  query GetOnboardingStatuses {
    statusEnum: onboarding_job_statuses {
      comment
      value
    }
    statusReasonEnum: onboarding_immunizations_status_reason_enum {
      comment
      value
    }
  }
`;

export const RemoveImmunizationsAndJob = gql`
  mutation RemoveImmunizationsAndJob($id: uuid) {
    update_onboarding_immunizations_many(
      updates: { where: { jobId: { _eq: $id } }, _set: { status: removed } }
    ) {
      affected_rows
    }
    update_onboarding_jobs(
      where: { id: { _eq: $id } }
      _set: { status: removed }
    ) {
      affected_rows
    }
  }
`;

export const VaccinationExistsQuery = gql`
  query VaccinationExistsQuery(
    $from: timestamptz!
    $to: timestamptz!
    $practicePatientId: [uuid!]!
    $vaccineIds: [uuid!]!
    $vaccinationId: uuid!
  ) {
    vaccination: vaccinations_aggregate(
      where: {
        id: { _neq: $vaccinationId }
        practicePatientId: { _in: $practicePatientId }
        vaccineId: { _in: $vaccineIds }
        givenAt: { _gte: $from, _lte: $to }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
