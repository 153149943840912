import { useQuery } from '@apollo/client';
import { VaccinesByNDC10sQuery } from '@bluefox/graphql/vaccines';
import { Vaccine } from '@bluefox/models/Vaccine';
import { useCallback, useEffect, useState } from 'react';

interface VaccineData {
  vaccines: Vaccine[];
}

export const useVaccinesByNdc10s = () => {
  const [ndcs, setNdcs] = useState<string[]>([]);
  const [vaccinesRecord, setVaccinesRecord] = useState<{
    [ndc: string]: Vaccine;
  }>({});

  const { data, loading } = useQuery<VaccineData>(VaccinesByNDC10sQuery, {
    variables: {
      ndcs,
    },
    skip: !ndcs.length,
  });

  const addEntry = useCallback(
    (ndc) => {
      setNdcs(Array.from(new Set([...ndcs, ndc])));
    },
    [ndcs]
  );

  const cleanEntries = () => {
    setVaccinesRecord({});
    setNdcs([]);
  };

  useEffect(() => {
    if (!data || !data.vaccines) return;
    const record = data.vaccines.reduce(
      (acc, vaccine) => ({
        ...acc,
        [vaccine.saleNdc10 ? vaccine.saleNdc10 : vaccine.useNdc10!]: vaccine,
      }),
      {}
    );
    setVaccinesRecord(record);
  }, [data]);

  return {
    loading,
    vaccinesRecord,
    addEntry,
    cleanEntries,
  };
};
