import React from 'react';
import { Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { Vaccine } from '@bluefox/models/Vaccine';
import {
  formatVaccineInventoryCptCodes,
  formatVaccineTypes,
} from '@bluefox/lib/formatters';

type Props = {
  vaccines?: Vaccine[];
};

const VaccinesTable = ({ vaccines }: Props) => {
  return (
    <Table striped>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Use National Drug Code</Table.HeaderCell>
          <Table.HeaderCell>Sale National Drug Code</Table.HeaderCell>
          <Table.HeaderCell>Types</Table.HeaderCell>
          <Table.HeaderCell>Inventory CPT Codes</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {vaccines && vaccines.length > 0 ? (
          vaccines?.map(
            ({
              id,
              name,
              useNdc,
              saleNdc,
              types,
              inventoryCptCodes,
            }: Vaccine) => (
              <Table.Row key={id}>
                <Table.Cell>
                  <Link to={`/vaccines/${id}`}>{name}</Link>
                </Table.Cell>
                <Table.Cell>{useNdc}</Table.Cell>
                <Table.Cell>{saleNdc}</Table.Cell>
                <Table.Cell>{formatVaccineTypes(types)}</Table.Cell>
                <Table.Cell>
                  {formatVaccineInventoryCptCodes(inventoryCptCodes)}
                </Table.Cell>
              </Table.Row>
            )
          )
        ) : (
          <Table.Row>
            <Table.Cell>No data</Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
};

export default VaccinesTable;
