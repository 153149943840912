import { useSubscription } from '@apollo/client';
import {
  formatDateStringToDateTimeZero,
  reformatYYYYhMMhDDStringtoMMsDDsYYYY,
  usDollarNoDigitsCurrency,
} from '@bluefox/lib/formatters';
import { humanizeText } from '@bluefox/lib/humanize';
import {
  BillingInvoice,
  invoiceStatusOptions,
  InvoiceStatus,
} from '@bluefox/models/Billing';
import { InvoiceStatusSubscription } from '@graphql/billing';
import { Button, Dropdown, Label, Popup, Table } from 'semantic-ui-react';

type Props = {
  billingInvoice: BillingInvoice;
  windowInnerWidth: number;
  onStatusChanged?: (
    billingInvoice: BillingInvoice,
    invoiceStatus: InvoiceStatus
  ) => void;
  onSave?: (billingInvoice: BillingInvoice) => void;
  onShowPDF?: (billingInvoice: BillingInvoice) => void;
  onEditPaymentDate?: (billingInvoice: BillingInvoice) => void;
  onRegenerateInvoiceInStripe?: (billingInvoice: BillingInvoice) => void;
};

const InvoiceListTableRow = ({
  billingInvoice,
  windowInnerWidth,
  onStatusChanged: onChangeStatus,
  onSave,
  onShowPDF,
  onEditPaymentDate,
  onRegenerateInvoiceInStripe,
}: Props) => {
  const { data: subscriptionData } = useSubscription(
    InvoiceStatusSubscription,
    {
      variables: {
        invoiceId: billingInvoice.id,
      },
      skip: !billingInvoice,
      onSubscriptionComplete() {
        onSave?.(billingInvoice);
      },
    }
  );

  return (
    <Table.Row>
      <Table.Cell>
        {billingInvoice?.practicesData
          ? billingInvoice?.practicesData
              ?.map((p) => p.name)
              .sort()
              .join(', ')
          : billingInvoice?.practice?.name}
      </Table.Cell>
      <Table.Cell>
        {billingInvoice?.date
          ? reformatYYYYhMMhDDStringtoMMsDDsYYYY(
              formatDateStringToDateTimeZero(
                billingInvoice?.date as unknown as string
              )
            )
          : '-'}
      </Table.Cell>
      <Table.Cell>
        {usDollarNoDigitsCurrency(billingInvoice?.amount)}
      </Table.Cell>
      <Table.Cell>
        <Label
          basic
          size="small"
          content={humanizeText(billingInvoice?.billMethod, {
            capitalize: 'first',
          })}
        />
      </Table.Cell>
      <Table.Cell>
        <Dropdown
          fluid
          selection
          options={invoiceStatusOptions(billingInvoice.status)}
          value={subscriptionData?.invoice.status}
          onChange={(_, { value }) => {
            onChangeStatus?.(billingInvoice, value as InvoiceStatus);
          }}
          loading={!subscriptionData}
        />
      </Table.Cell>
      <Table.Cell>
        {billingInvoice.status === InvoiceStatus.ERROR ||
        (billingInvoice.status === InvoiceStatus.ERROR_STRIPE &&
          billingInvoice?.message?.error) ? (
          <Popup
            on="click"
            trigger={<Button size="mini" content="Show Error" />}
            position="top right"
            content={billingInvoice?.message?.error}
          />
        ) : (
          '-'
        )}
      </Table.Cell>
      <Table.Cell>
        {billingInvoice.paymentDate
          ? reformatYYYYhMMhDDStringtoMMsDDsYYYY(
              billingInvoice.paymentDate as unknown as string
            )
          : '-'}
      </Table.Cell>
      <Table.Cell>
        {billingInvoice.dueDate
          ? reformatYYYYhMMhDDStringtoMMsDDsYYYY(
              billingInvoice.dueDate as unknown as string
            )
          : '-'}
      </Table.Cell>
      <Table.Cell>
        <Dropdown
          icon={null}
          pointing={
            windowInnerWidth <= 1465 && windowInnerWidth > 770 ? 'right' : false
          }
          trigger={<Button size="mini" icon="ellipsis horizontal" />}
        >
          <Dropdown.Menu>
            {billingInvoice?.pdfData && (
              <Dropdown.Item
                content="Export PDF"
                icon="file pdf outline"
                onClick={() => {
                  onShowPDF?.(billingInvoice);
                }}
              />
            )}
            <Dropdown.Item
              content="Edit payment date"
              icon="calendar check outline"
              onClick={() => {
                onEditPaymentDate?.(billingInvoice);
              }}
            />
            {billingInvoice?.metadata?.invoiceUrl && (
              <Dropdown.Item
                href={billingInvoice?.metadata?.invoiceUrl}
                target="_blank"
                content="Invoice URL"
                icon="linkify"
              />
            )}
            {(billingInvoice.status === InvoiceStatus.DISCARDED ||
              billingInvoice.status === InvoiceStatus.ERROR ||
              billingInvoice.status === InvoiceStatus.ERROR_STRIPE) &&
            !billingInvoice?.metadata?.invoiceUrl ? (
              <Dropdown.Item
                content="Regenerate Invoice in Stripe"
                icon="redo"
                onClick={() => {
                  onRegenerateInvoiceInStripe?.(billingInvoice);
                }}
              />
            ) : null}
          </Dropdown.Menu>
        </Dropdown>
      </Table.Cell>
    </Table.Row>
  );
};

export default InvoiceListTableRow;
