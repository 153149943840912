import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { VaccinationsByPracticeQuery } from '@bluefox/graphql/vaccinations';
import { ProvidersQuery } from '@bluefox/graphql/integrations/athena';
import {
  vaxSyncDropdownMenuOptions,
  VaccinationView,
} from '@bluefox/models/Vaccination';
import { UserTitles } from '@bluefox/models/Users';
import {
  Container,
  Icon,
  Card,
  Dropdown,
  Input,
  Menu,
  Label,
} from 'semantic-ui-react';
import { usePractice } from '@bluefox/contexts';
import MainLayout from '@ui/MainLayout';
import DateTimePicker from '@bluefox/ui/DateTimePicker';
import { DateFormats } from '@bluefox/models/Dates';
import VaxSyncTable from '@ui/VaxSync/vaxSyncTable';
import { whereLikeInput } from '@bluefox/graphql/utils';
import styled from 'styled-components';
import { COLORS_BY_NAME } from '@bluefox/constants';

const inventoryUsedOptions = [
  { text: 'VFC', value: 'vfc' },
  { text: 'Private', value: 'private' },
];

interface VaxSyncData {
  vaccinations: VaccinationView[];
  aggregating: {
    aggregate: {
      count: number;
    };
  };
}
interface ProviderData {
  title: UserTitles;
  account: {
    firstName: string;
    lastName: string;
    email: string;
  };
}
interface ProvidersData {
  providers: ProviderData[];
}
interface ProviderOption {
  text: string;
  value: string;
}

const possiblePrescribers = [
  UserTitles.MD,
  UserTitles.DO,
  UserTitles.NP,
  UserTitles.PA,
];

const ENTRIES_PER_PAGE = 15;

const VaxSyncScreen = () => {
  const practice = usePractice();

  const [criteria, setCriteria] = useState<object>({});
  const [searchPatient, setSearchPatient] = useState<string>();
  const [searchLotNumber, setSearchLotNumber] = useState<string>();
  const [searchEmr, setSearchEmr] = useState('pending');
  const [searchRegistry, setSearchRegistry] = useState('all');
  const [searchInventoryUsed, setSearchInventoryUsed] = useState<boolean[]>();
  const [searchFromDate, setSearchFromDate] = useState<Date | null | undefined>(
    null
  );
  const [searchToDate, setSearchToDate] = useState<Date | null | undefined>(
    null
  );
  const [searchProvider, setSearchProvider] = useState<string>();
  const [searchPrescriber, setSearchPrescriber] = useState<string>();
  const [providerOptions, setProviderOptions] = useState<ProviderOption[]>([]);
  const [prescriberOptions, setPrescriberOptions] = useState<ProviderOption[]>(
    []
  );

  const [page, setPage] = useState(0);

  const { data, loading, refetch } = useQuery<VaxSyncData>(
    VaccinationsByPracticeQuery,
    {
      variables: {
        criteria,
        limit: ENTRIES_PER_PAGE,
        offset: !!page ? ENTRIES_PER_PAGE * (page - 1) : 0,
      },
      skip:
        Object.keys(criteria).length === 0 ||
        (!!searchPatient && searchPatient.length < 3) ||
        (!!searchLotNumber && searchLotNumber.length < 3),
    }
  );

  const { data: providersData } = useQuery<ProvidersData>(ProvidersQuery, {
    variables: {
      practiceIds: [practice.id],
    },
    skip: !practice.id,
  });

  const total = data?.aggregating.aggregate.count || 0;
  const totalPages = Math.ceil(total / ENTRIES_PER_PAGE);

  const handleInventoryUsedValue = (value: string) => {
    switch (value) {
      case 'all':
        return setSearchInventoryUsed([true, false]);
      case 'vfc':
        return setSearchInventoryUsed([true]);
      case 'private':
        return setSearchInventoryUsed([false]);
    }
  };

  useEffect(() => {
    if (!providersData || providersData.providers.length === 0) return;

    setProviderOptions(
      providersData.providers
        .map(({ account: acc }: ProviderData) => ({
          text: `${acc.firstName} ${acc.lastName}`,
          value: acc.email,
        }))
        .sort((a, b) => {
          const nameA = a.text.toUpperCase();
          const nameB = b.text.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        })
    );

    setPrescriberOptions(
      providersData.providers
        .filter((p: ProviderData) => possiblePrescribers.includes(p.title))
        .map(({ account: acc }: ProviderData) => ({
          text: `${acc.firstName} ${acc.lastName}`,
          value: acc.email,
        }))
        .sort((a, b) => {
          const nameA = a.text.toUpperCase();
          const nameB = b.text.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        })
    );
  }, [providersData]);

  useEffect(() => {
    let _criteria = {};
    let patientData = undefined;
    let emr = undefined;
    let registry = undefined;
    let searchDateRange = undefined;
    let _searchProvider = {};
    let _searchPrescriber = {};
    let _lotNumber = undefined;
    let _inventoryUsed = undefined;

    if (searchPatient) {
      patientData = {
        _or: [
          { patientFirstName: { _ilike: whereLikeInput(searchPatient) } },
          { patientLastName: { _ilike: whereLikeInput(searchPatient) } },
          { mrn: { _eq: searchPatient } },
        ],
      };
    }

    if (searchEmr !== 'all') {
      if (searchEmr === 'pending') {
        emr = ['pending'];
      } else {
        emr = ['canid', 'practice'];
      }
    }

    if (searchRegistry !== 'all') {
      if (searchRegistry === 'pending') {
        registry = ['pending'];
      } else {
        registry = ['auto', 'manual'];
      }
    }

    if (searchFromDate || searchToDate) {
      searchDateRange = {
        _gte: searchFromDate,
        _lte: searchToDate ?? new Date(),
      };
    }
    if (searchProvider) {
      _searchProvider = {
        givenByEmail: { _eq: searchProvider },
      };
    }

    if (searchPrescriber) {
      _searchPrescriber = {
        prescriberEmail: { _eq: searchPrescriber },
      };
    }

    if (searchLotNumber) {
      _lotNumber = {
        lot: { _ilike: whereLikeInput(searchLotNumber) },
      };
    }

    if (searchInventoryUsed) {
      _inventoryUsed = {
        inventoryVfc: { _in: searchInventoryUsed },
      };
    }

    _criteria = {
      practiceId: { _eq: practice.id },
      ...patientData,
      emrUploadedBy: {
        _in: emr,
      },
      registryReportedBy: {
        _in: registry,
      },
      createdAt: searchDateRange,
      ..._searchProvider,
      ..._searchPrescriber,
      ..._lotNumber,
      ..._inventoryUsed,
    };

    setPage(1);
    setCriteria(_criteria);
  }, [
    searchPatient,
    searchLotNumber,
    searchEmr,
    searchRegistry,
    searchFromDate,
    searchToDate,
    searchProvider,
    searchPrescriber,
    practice.id,
    searchInventoryUsed,
  ]);

  return (
    <MainLayout
      path={[
        { text: 'Practices', to: '/practices' },
        { text: practice.name, to: `/practices/${practice.handler}` },
        { text: 'Vax Sync' },
      ]}
    >
      <Container fluid>
        <Card fluid>
          <Card.Content>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Card.Header as={'h3'}>
                <Icon name="syringe" style={{ marginRight: '0.6rem' }} />
                Vax Sync
              </Card.Header>
            </div>
            <Menu
              borderless
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <Menu.Menu
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
              >
                <Menu.Item
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}
                >
                  <p>
                    <b>Patient</b>
                  </p>
                  <Input
                    placeholder="Search patient..."
                    icon="search"
                    value={searchPatient}
                    onChange={(_, { value }) => setSearchPatient(value)}
                  />
                </Menu.Item>
                <Menu.Item
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}
                >
                  <StyledfilterTitle>
                    <b>EMR upload</b>
                  </StyledfilterTitle>
                  <Dropdown
                    placeholder="EMR upload"
                    selection
                    value={searchEmr}
                    onChange={(e, data) => {
                      const dataValue = data.value ?? 'all';
                      setSearchEmr(dataValue.toString());
                    }}
                    options={[
                      { text: 'All', value: 'all' },
                      ...vaxSyncDropdownMenuOptions,
                    ]}
                  />
                </Menu.Item>
                <Menu.Item style={{ display: 'flex', flexDirection: 'column' }}>
                  <div>
                    <StyledfilterTitle>
                      <b>Registry Report</b>
                    </StyledfilterTitle>
                    <Dropdown
                      placeholder="CIR report"
                      selection
                      onChange={(e, data) => {
                        const dataValue = data.value ?? 'all';
                        setSearchRegistry(dataValue.toString());
                      }}
                      options={[
                        { text: 'All', value: 'all' },
                        ...vaxSyncDropdownMenuOptions,
                      ]}
                    />
                  </div>
                </Menu.Item>
                <Menu.Item>
                  <div>
                    <StyledfilterTitle>
                      <b>Filter by Given By</b>
                    </StyledfilterTitle>
                    <Dropdown
                      style={{ minWidth: '15rem' }}
                      placeholder="Filter by Given By"
                      fluid
                      search
                      selection
                      onChange={(e, data) => {
                        setSearchProvider(data.value?.toString() || '');
                      }}
                      options={[
                        { text: 'All Providers', value: '' },
                        ...providerOptions,
                      ]}
                    />
                  </div>
                </Menu.Item>
                <Menu.Item>
                  <div>
                    <StyledfilterTitle>
                      <b>Filter by Prescriber</b>
                    </StyledfilterTitle>
                    <Dropdown
                      style={{ minWidth: '15rem' }}
                      placeholder="Filter by Prescriber"
                      fluid
                      search
                      selection
                      onChange={(e, data) => {
                        setSearchPrescriber(data.value?.toString() || '');
                      }}
                      options={[
                        { text: 'All Prescribers', value: '' },
                        ...prescriberOptions,
                      ]}
                    />
                  </div>
                </Menu.Item>
                <Menu.Item>
                  <div>
                    <StyledfilterTitle>
                      <b>Filter by Inventory used</b>
                    </StyledfilterTitle>
                    <Dropdown
                      style={{ minWidth: '15rem' }}
                      placeholder="Filter by Inventory used"
                      fluid
                      selection
                      onChange={(e, data) => {
                        handleInventoryUsedValue(data.value?.toString() || '');
                      }}
                      options={[
                        { text: 'All', value: 'all' },
                        ...inventoryUsedOptions,
                      ]}
                    />
                  </div>
                </Menu.Item>
                <Menu.Item
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}
                >
                  <p>
                    <b>Lot Number</b>
                  </p>
                  <Input
                    placeholder="Search lot number..."
                    icon="search"
                    value={searchLotNumber}
                    onChange={(_, { value }) => setSearchLotNumber(value)}
                  />
                </Menu.Item>
              </Menu.Menu>
              <Menu.Menu
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'flex-start',
                }}
              >
                <Menu.Item>
                  <div>
                    <p>
                      <b>From</b>
                    </p>
                    <DateTimePicker
                      placeholderText="From"
                      selected={searchFromDate}
                      onChange={(d) =>
                        setSearchFromDate(d ? (d as Date) : undefined)
                      }
                      onSelect={(value) =>
                        setSearchFromDate(value ? (value as Date) : undefined)
                      }
                      onClear={() => setSearchFromDate(undefined)}
                      maxDate={new Date()}
                      dateFormat={DateFormats.DATE}
                      showYearDropdown
                      showMonthDropdown
                      scrollableYearDropdown
                      dropdownMode="select"
                      isClearable
                    />
                  </div>
                </Menu.Item>
                <Menu.Item>
                  <div>
                    <p>
                      <b>To</b>
                    </p>
                    <DateTimePicker
                      placeholderText="To"
                      selected={searchToDate}
                      onChange={(d) =>
                        setSearchToDate(d ? (d as Date) : undefined)
                      }
                      onSelect={(value) =>
                        setSearchToDate(value ? (value as Date) : undefined)
                      }
                      onClear={() => setSearchToDate(undefined)}
                      maxDate={new Date()}
                      dateFormat={DateFormats.DATE}
                      showYearDropdown
                      showMonthDropdown
                      scrollableYearDropdown
                      dropdownMode="select"
                      isClearable
                    />
                  </div>
                </Menu.Item>
                <Menu.Menu position="right" style={{ width: '8.5rem' }}>
                  <Menu.Item
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    }}
                  >
                    <StyledActionReference>
                      <Label
                        circular
                        style={{
                          backgroundColor: COLORS_BY_NAME['Tea Green'],
                          border: `2px solid ${COLORS_BY_NAME['Japanese Laurel']}`,
                        }}
                      />
                      <b>Insert</b>
                    </StyledActionReference>
                    <StyledActionReference>
                      <Label
                        circular
                        style={{
                          backgroundColor: COLORS_BY_NAME['Very Pale Yellow'],
                          border: `2px solid ${COLORS_BY_NAME['Yellow Sun']}`,
                        }}
                      />
                      <b>Modified</b>
                    </StyledActionReference>
                    <StyledActionReference>
                      <Label
                        circular
                        style={{
                          backgroundColor: COLORS_BY_NAME['Snow'],
                          border: `2px solid ${COLORS_BY_NAME['Pale Chestnut']}`,
                        }}
                      />
                      <b>Remove</b>
                    </StyledActionReference>
                  </Menu.Item>
                </Menu.Menu>
              </Menu.Menu>
            </Menu>
            <VaxSyncTable
              data={data}
              loading={loading}
              refetch={refetch}
              total={total}
              totalPages={totalPages}
              page={page}
              setPage={setPage}
            />
          </Card.Content>
        </Card>
      </Container>
    </MainLayout>
  );
};

const StyledfilterTitle = styled.p`
  margin-bottom: 0 !important;
`;

const StyledActionReference = styled.div`
  width: 100%;
  margin: 0.2rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export default VaxSyncScreen;
