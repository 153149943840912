import { useQuery } from '@apollo/client';
import Pagination from '@bluefox/ui/Pagination';
import { UncollectedInvoicesQuery } from '@graphql/invoices';
import { Popup, Table } from 'semantic-ui-react';
import { useState } from 'react';
import { InvoiceStatus } from '@bluefox/models/Invoice';
import { dateStrToMMDDYYYY } from '@bluefox/lib/formatters';

interface UncollectedInvoicesData {
  id: string;
  organization: {
    name: string;
  };
  paymentDate: string;
  status: InvoiceStatus;
  message: {
    error: string;
  };
  createdAt: string;
}
const rowsPerPage = 10;

const today = new Date();
today.setMonth(today.getMonth() - 6);
const sixMonthsAgo = today.toISOString();

const UncollectedInvoices = () => {
  const [page, setPage] = useState(1);
  const { data } = useQuery(UncollectedInvoicesQuery, {
    variables: {
      limit: rowsPerPage,
      offset: !!page ? rowsPerPage * (page - 1) : 0,
      date: sixMonthsAgo,
    },
  });

  const total = data?.invoicesCount.aggregate.count || 0;
  const totalPages = Math.ceil(total / rowsPerPage);

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Organization Name</Table.HeaderCell>
          <Table.HeaderCell>Invoices Status</Table.HeaderCell>
          <Table.HeaderCell>Payment Date</Table.HeaderCell>
          <Table.HeaderCell>Invoice Date</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {data?.invoices.map((invoice: UncollectedInvoicesData) => (
          <Table.Row key={invoice.id}>
            <Table.Cell>{invoice.organization.name}</Table.Cell>
            <Table.Cell>
              {invoice.status === InvoiceStatus.error ||
              invoice.status === InvoiceStatus.error_stripe ? (
                <Popup
                  trigger={
                    <span style={{ color: 'black', fontWeight: 'bold' }}>
                      {invoice.status}
                    </span>
                  }
                  content={invoice.message.error}
                  position="top center"
                />
              ) : (
                invoice.status
              )}
            </Table.Cell>
            <Table.Cell>{dateStrToMMDDYYYY(invoice.paymentDate)}</Table.Cell>
            <Table.Cell>{dateStrToMMDDYYYY(invoice.createdAt)}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan={4}>
            <Pagination
              total={total}
              activePage={page}
              colSpan={4}
              onPageChange={(newActivePage) => setPage(newActivePage)}
              totalPages={totalPages}
              position="right"
            />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
};

export default UncollectedInvoices;
