import { gql } from '@apollo/client';

export const InsertNotificationMutation = gql`
  mutation InsertNotification(
    $notification: communication_notifications_insert_input!
  ) {
    insert_communication_notifications_one(object: $notification) {
      id
    }
  }
`;

export const UpdateNotificationContentMutation = gql`
  mutation UpdateNotificationContentMutation(
    $notificationId: uuid!
    $content: jsonb!
    $statusLogItem: jsonb!
  ) {
    update_communication_notifications_by_pk(
      pk_columns: { id: $notificationId }
      _set: { content: $content }
      _append: { statusLog: $statusLogItem }
    ) {
      id
      content
      statusLog
    }
  }
`;

export const GetNotificationsByEntityRefIdQuery = gql`
  query GetNotificationsByEntityRefId($entityRefIds: [uuid!]!) {
    communication_notifications(
      where: { entityRefId: { _in: $entityRefIds } }
    ) {
      entityRefId
    }
  }
`;
