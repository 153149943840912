import { ALL, allOption } from '@bluefox/lib/options';
import {
  InvoiceStatus,
  invoiceStatusMenuOptions,
} from '@bluefox/models/Billing';
import { DateFormats } from '@bluefox/models/Dates';
import DateTimePicker from '@bluefox/ui/DateTimePicker';
import OrganizationAndPracticeSelector, {
  SearchValuesProps as OrganizationAndPracticeSelectorValues,
} from '@bluefox/ui/OrganizationAndPracticeSelector';
import { Dispatch, SetStateAction, useState } from 'react';
import { Card, Form } from 'semantic-ui-react';

export type SearchValuesProps = {
  practicesIds?: string[];
  status?: InvoiceStatus;
  billingMethod?: 'auto' | 'manual';
  dateFrom?: Date;
  dateTo?: Date;
  paymentDateFrom?: Date;
  paymentDateTo?: Date;
};

type Props = {
  searchValues: SearchValuesProps;
  setSearchValues: Dispatch<SetStateAction<SearchValuesProps>>;
};

const SearchForm = ({ searchValues, setSearchValues }: Props) => {
  const [orgAndPractSearchValues, orgAndPractSetSearchValues] =
    useState<OrganizationAndPracticeSelectorValues>(
      {} as OrganizationAndPracticeSelectorValues
    );
  return (
    <Card fluid>
      <Card.Content>
        <Card.Description>
          <OrganizationAndPracticeSelector
            multiplePracticesSelect
            searchValues={orgAndPractSearchValues}
            setSearchValues={orgAndPractSetSearchValues}
            onChange={({ practicesIds }) =>
              setSearchValues((prevValue) => ({ ...prevValue, practicesIds }))
            }
          />
          <Form>
            <Form.Group widths="equal">
              <Form.Dropdown
                label="Filter by status"
                fluid
                selection
                defaultValue={ALL}
                onChange={(_, { value }) => {
                  const status =
                    (value as string) !== ALL
                      ? (value as InvoiceStatus)
                      : undefined;
                  setSearchValues((prevValue) => ({
                    ...prevValue,
                    status,
                  }));
                }}
                options={[...allOption, ...invoiceStatusMenuOptions]}
              />
              <Form.Dropdown
                label="Filter by billing method"
                fluid
                selection
                defaultValue={ALL}
                onChange={(_, { value }) => {
                  const billingMethod =
                    (value as string) !== ALL
                      ? (value as 'auto' | 'manual')
                      : undefined;
                  setSearchValues((prevValue) => ({
                    ...prevValue,
                    billingMethod,
                  }));
                }}
                options={[
                  ...allOption,
                  { key: 'auto', text: 'Auto', value: 'auto' },
                  { key: 'manual', text: 'Manual', value: 'manual' },
                ]}
              />
            </Form.Group>
            <Form.Group inline>
              <Form.Field>
                <label style={{ width: '11rem' }}>Filter by date range:</label>
              </Form.Field>
              <Form.Field>
                <DateTimePicker
                  forFilter
                  placeholderText="From:"
                  selected={searchValues.dateFrom}
                  onChange={(value) => {
                    setSearchValues((prevVal) => ({
                      ...prevVal,
                      dateFrom: value ? (value as Date) : undefined,
                    }));
                  }}
                  onSelect={(value) =>
                    setSearchValues((prevVal) => ({
                      ...prevVal,
                      dateFrom: value ? (value as Date) : undefined,
                    }))
                  }
                  onClear={() =>
                    setSearchValues((prevVal) => ({
                      ...prevVal,
                      dateFrom: undefined,
                    }))
                  }
                  dateFormat={DateFormats.DATE}
                  showYearDropdown
                  showMonthDropdown
                  scrollableYearDropdown
                  dropdownMode="select"
                  isClearable
                />
              </Form.Field>
              <Form.Field>
                <DateTimePicker
                  forFilter
                  placeholderText="To:"
                  selected={searchValues.dateTo}
                  onChange={(value) => {
                    setSearchValues((prevVal) => ({
                      ...prevVal,
                      dateTo: value ? (value as Date) : undefined,
                    }));
                  }}
                  onSelect={(value) =>
                    setSearchValues((prevVal) => ({
                      ...prevVal,
                      dateTo: value ? (value as Date) : undefined,
                    }))
                  }
                  onClear={() =>
                    setSearchValues((prevVal) => ({
                      ...prevVal,
                      dateTo: undefined,
                    }))
                  }
                  dateFormat={DateFormats.DATE}
                  showYearDropdown
                  showMonthDropdown
                  scrollableYearDropdown
                  dropdownMode="select"
                  isClearable
                />
              </Form.Field>
            </Form.Group>
            <Form.Group inline>
              <Form.Field>
                <label style={{ width: '11rem' }}>
                  Filter by payment date:
                </label>
              </Form.Field>
              <Form.Field>
                <DateTimePicker
                  forFilter
                  placeholderText="To:"
                  selected={searchValues.paymentDateFrom}
                  onChange={(value) => {
                    setSearchValues((prevVal) => ({
                      ...prevVal,
                      paymentDateFrom: value ? (value as Date) : undefined,
                    }));
                  }}
                  onSelect={(value) =>
                    setSearchValues((prevVal) => ({
                      ...prevVal,
                      paymentDateFrom: value ? (value as Date) : undefined,
                    }))
                  }
                  onClear={() =>
                    setSearchValues((prevVal) => ({
                      ...prevVal,
                      paymentDateFrom: undefined,
                    }))
                  }
                  dateFormat={DateFormats.DATE}
                  showYearDropdown
                  showMonthDropdown
                  scrollableYearDropdown
                  dropdownMode="select"
                  isClearable
                />
              </Form.Field>
              <Form.Field>
                <DateTimePicker
                  forFilter
                  placeholderText="To:"
                  selected={searchValues.paymentDateTo}
                  onChange={(value) => {
                    setSearchValues((prevVal) => ({
                      ...prevVal,
                      paymentDateTo: value ? (value as Date) : undefined,
                    }));
                  }}
                  onSelect={(value) =>
                    setSearchValues((prevVal) => ({
                      ...prevVal,
                      paymentDateTo: value ? (value as Date) : undefined,
                    }))
                  }
                  onClear={() =>
                    setSearchValues((prevVal) => ({
                      ...prevVal,
                      paymentDateTo: undefined,
                    }))
                  }
                  dateFormat={DateFormats.DATE}
                  showYearDropdown
                  showMonthDropdown
                  scrollableYearDropdown
                  dropdownMode="select"
                  isClearable
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Card.Description>
      </Card.Content>
    </Card>
  );
};

export default SearchForm;
