import { gql } from '@apollo/client';

export const MappingsQuery = gql`
  query EmrMappingsQuery($criteria: bot_mappings_bool_exp) {
    mappings: bot_mappings(where: $criteria) {
      id
      key
      metadata
      vaccineInfo
      practice {
        id
        name
      }
    }
    allPractices: practices(
      distinct_on: id
      where: { settings: { _contains: { bot: { emr: "ECW" } } } }
    ) {
      id
      name
    }
    aggregating: bot_mappings_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const ProcessEcwAppointmentManually = gql`
  mutation ProcessEcwAppointmentManually($appointmentId: uuid!) {
    processedAppointment: ProcessEcwAppointmentManually(
      appointmentId: $appointmentId
    ) {
      body
      code
      extensions
      message
    }
  }
`;
