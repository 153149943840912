import React, { useCallback, useMemo, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { ProcedureAmountCptQuery } from '@bluefox/graphql/procedureAmounts';
import {
  updateVaccine as updateVaccineMutation,
  getVaccine,
  GetVisPublication,
  InsertVisPublication,
  UpdateVisPublication,
} from '@bluefox/graphql/vaccines';
import { useHistory, useParams } from 'react-router-dom';
import { Dimmer, Loader } from 'semantic-ui-react';
import { Vaccine, VisPublication } from '@bluefox/models/Vaccine';
import { toast } from 'react-semantic-toasts';
import { useInformationModalAwait } from '@bluefox/ui/InformationModal';
import VaccineForm from './VaccineForm';

const VaccineEdit = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [disabled, setDisabled] = useState(false);
  const [vaccine, setVaccine] = useState<Vaccine>(
    undefined as unknown as Vaccine
  );
  const [visPublication, setVisPublication] = useState<VisPublication>(
    undefined as unknown as VisPublication
  );
  const covidString = 'COVID-19';
  const visPublicationStatuses = {
    HISTORIC: 'historic',
    CURRENT: 'current',
  };
  const stringEncodedStartNumber = '253';
  const stringEncodedSeparatorNumber = '11';
  const {
    loading: getProcedureAmountsLoading,
    error: getProcedureAmountsError,
    data: getProcedureAmountsData,
  } = useQuery(ProcedureAmountCptQuery);
  const [updateVaccine, { loading: updateVaccineLoading }] = useMutation(
    updateVaccineMutation
  );
  const [insertVisPublication] = useMutation(InsertVisPublication);
  const [updateVisPublication] = useMutation(UpdateVisPublication);
  const { loading: getVaccineLoading, error: getVaccineError } = useQuery(
    getVaccine,
    {
      variables: {
        id,
      },
      onCompleted: (data) => {
        setVaccine(data?.vaccines[0]);
        getVisPublication({
          variables: {
            where: {
              status: { _eq: 'current' },
              vaccineTypes: { _has_keys_all: data.vaccines[0].types },
            },
          },
        });
      },
      fetchPolicy: 'network-only',
    }
  );
  const [
    getVisPublication,
    { data: getVisPublicationData, loading: getVisPublicationLoading },
  ] = useLazyQuery(GetVisPublication, {
    onCompleted: (data) => {
      if (data.visPublications.length) {
        setVisPublication({
          id: data.visPublications[0].id,
          conceptName: data.visPublications[0].conceptName,
          publicationDate: data.visPublications[0].publicationDate,
          conceptCode: data.visPublications[0].conceptCode,
          cvx: data.visPublications[0].cvx,
        } as VisPublication);
      } else {
        setVisPublication(undefined as unknown as VisPublication);
      }
    },
    fetchPolicy: 'network-only',
  });

  const { render: renderInformationModal, open: openInformationModal } =
    useInformationModalAwait();
  const loading =
    getProcedureAmountsLoading ||
    getVaccineLoading ||
    updateVaccineLoading ||
    getVisPublicationLoading;
  const error = getProcedureAmountsError || getVaccineError;

  const makeVisPublicationInsertObject = useCallback(() => {
    const stringEncondedDate =
      visPublication.publicationDate &&
      visPublication.publicationDate.getFullYear().toString().slice(-2) +
        ('0' + (visPublication.publicationDate.getMonth() + 1)).slice(-2) +
        ('0' + visPublication.publicationDate.getDate()).slice(-2);
    const stringEncoded =
      stringEncodedStartNumber +
      visPublication.conceptCode +
      stringEncodedSeparatorNumber +
      stringEncondedDate;
    return {
      conceptCode: visPublication.conceptCode,
      conceptName: visPublication.conceptName,
      publicationDate: visPublication.publicationDate,
      stringEncoded,
      status: visPublicationStatuses.CURRENT,
      vaccineTypes: vaccine.types,
      cvx: vaccine?.types?.includes(covidString) ? [vaccine.cvx] : null,
    };
  }, [visPublication]);

  const handleSubmit = useCallback(async () => {
    setDisabled(true);

    try {
      await updateVaccine({
        variables: {
          ...vaccine,
        },
      });

      if (
        visPublication.publicationDate !==
        getVisPublicationData.visPublications[0]?.publicationDate
      ) {
        visPublication.id
          ? await updateVisPublication({
              variables: {
                updateId: visPublication.id,
                updateObject: {
                  status: visPublicationStatuses.HISTORIC,
                },
                insertObject: makeVisPublicationInsertObject(),
              },
            })
          : await insertVisPublication({
              variables: {
                insertObject: makeVisPublicationInsertObject(),
              },
            });
      }

      toast({
        title: 'Vaccine saved',
        type: 'success',
        time: 1000,
      });

      history.push(`/vaccines/${id}`);
    } catch (error) {
      const errMessage = (error as Error)?.message;
      switch (errMessage) {
        case 'Uniqueness violation. duplicate key value violates unique constraint "vaccines_code_key"':
          await openInformationModal(
            `Vaccine save failed. Error: 'Sale NDC11' value duplicated`,
            'Error'
          );
          break;
        case 'Uniqueness violation. duplicate key value violates unique constraint "vaccines_ndc10_key"':
          await openInformationModal(
            `Vaccine save failed. Error: 'Sale NDC10' value duplicated`,
            'Error'
          );
          break;
        default:
          await openInformationModal(
            `Vaccine save failed. Error: ${errMessage}`,
            'Error'
          );
      }
    } finally {
      setDisabled(false);
    }
    setDisabled(false);
  }, [
    history,
    id,
    openInformationModal,
    updateVaccine,
    vaccine,
    visPublication,
  ]);

  const cptCodes = useMemo(
    () =>
      getProcedureAmountsData?.procedure_amounts.map(
        (pa: Partial<{ cpt: string }>) => pa.cpt
      ),
    [getProcedureAmountsData?.procedure_amounts]
  );

  return (
    <>
      {renderInformationModal()}
      {loading && (
        <Dimmer active>
          <Loader />
        </Dimmer>
      )}
      {error && <p>Error : {error.message}</p>}
      {!loading && !error && cptCodes && vaccine && (
        <VaccineForm
          edit
          disabled={disabled}
          submitButtonLable="Save"
          vaccine={vaccine}
          setVaccine={setVaccine}
          visPublication={visPublication}
          setVisPublication={setVisPublication}
          onSubmit={handleSubmit}
          onCancel={() => history.push(`/vaccines/${id}`)}
          cptCodes={cptCodes}
        />
      )}
    </>
  );
};

export default VaccineEdit;
