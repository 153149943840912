import React, { useCallback, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  Container,
  Table,
  Label,
  Icon,
  Menu,
  Input,
  Button,
  Header,
  Dropdown,
  Popup,
} from 'semantic-ui-react';
import { Link, useParams } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment-timezone';
import { tsXLXS } from 'ts-xlsx-export';
import { InventoryStatusLog } from '@ui/InventoryStatusLog';

import { Inventory, InventoryStatus } from '@bluefox/models/Inventory';
import { Vaccine } from '@bluefox/models/Vaccine';

import { usePractice } from '@bluefox/contexts/Practice';

import { InventoryQuery } from '@graphql/inventory';

import VaccineTypesPicker from '@bluefox/ui/VaccineTypesPicker';
import InventoryFormModal from '@ui/InventoryFormModal';
import MainLayout from '@ui/MainLayout';
import InventoryStatusPicker from '@bluefox/ui/InventoryStatusPicker';
import FilterPopup from '@ui/FilterPopup';
import ThresholdInput, {
  Threshold,
  ThresholdOperator,
} from '@ui/ThresholdInput';
import InventoryBatchModal from '@ui/InventoryBatchModal';
import { DateFormats } from '@bluefox/models/Dates';
import AdjustmentDetails from '@screens/inventory/AdjustmentDetails';

interface VaccineWithInventory extends Vaccine {
  inventory: Inventory[];
}

interface VaccinesStockData {
  vaccines: VaccineWithInventory[];
}

interface InventoryScreenParams {
  inventoryId?: string;
}

interface InventoryData {
  vaccine: Vaccine;
  inventory: Inventory;
}

export interface InventoryExcelRowType {
  Practice: string;
  Vaccine: string;
  Types: string;
  VFC: string;
  Lot: string;
  Doses: number;
  Expiration: Date;
  Status: string;
  Manufacturer: string;
  CVX: string;
  MVX: string;
  saleNdc: string;
  saleNdc10: string;
  useNdc: string;
  useNdc10: string;
  vaccinationsCount: number | null;
  orderedDoses: number | null;
}

const InventoryScreen = () => {
  const practice = usePractice();
  const { inventoryId } = useParams<InventoryScreenParams>();

  const [searchVfc, setSearchVfc] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [statuses, setStatuses] = useState<InventoryStatus[]>([
    InventoryStatus.received,
  ]);
  const [dosesThreshold, setDosesThreshold] = useState<Threshold | undefined>();
  const [vaccineTypes, setVaccineTypes] = useState<string[]>([]);

  const [typesFilter, setTypesFilter] = useState({});
  const [inventorySelected, setInventorySelected] = useState<
    InventoryData | undefined
  >();

  const [vfcValue, setVfcValue] = useState('all');

  const { data, refetch, loading } = useQuery<VaccinesStockData>(
    InventoryQuery,
    {
      variables: {
        practiceId: practice.id,
        likeQuery: `%${searchQuery}%`,
        status: statuses.length
          ? { _in: statuses }
          : { _in: [InventoryStatus.received, InventoryStatus.retired] },
        doses: dosesThreshold
          ? { [dosesThreshold.operator]: dosesThreshold.operand }
          : {},
        vfc: searchVfc,
        typesFilter,
      },
      skip: !practice.id,
    }
  );

  const handleDosesThresholdInputChange = useCallback(
    (threshold) => setDosesThreshold(threshold),
    []
  );

  const handleVaccineTypesChange = useCallback(
    (types) => setVaccineTypes(types as string[]),
    []
  );

  const handleLoadXlsx = () => {
    if (!data) return;
    const inventories: InventoryExcelRowType[] = [];
    data.vaccines.forEach((vaccine) => {
      vaccine.inventory.forEach((inventory) =>
        inventories.push({
          Practice: practice.name,
          Vaccine: vaccine.name,
          Types: vaccine.types?.join(', ') || '',
          VFC: inventory.vfc ? 'VFC' : '',
          Lot: inventory.lot,
          orderedDoses: inventory.orderedDoses || null,
          vaccinationsCount: inventory.vaccinationsCount || null,
          Doses: inventory.doses,
          Expiration: inventory.expiration,
          Status: inventory.status,
          Manufacturer: vaccine.manufacturer,
          CVX: vaccine.cvx,
          MVX: vaccine.mvx,
          saleNdc: vaccine.saleNdc || '',
          saleNdc10: vaccine.saleNdc10 || '',
          useNdc: vaccine.useNdc || '',
          useNdc10: vaccine.useNdc10 || '',
        })
      );
    });
    tsXLXS()
      .exportAsExcelFile(inventories)
      .saveAsExcelFile(
        `Canid - ${practice.name} Invetory (${moment().format(
          DateFormats.DATE_WITH_TIME_SECONDS
        )})`
      );
  };

  const handleVfcValue = (value: string) => {
    switch (value) {
      case 'all':
        return setSearchVfc({});
      case 'vfc':
        return setSearchVfc({ _eq: true });
      case 'private':
        return setSearchVfc({ _eq: false });
      default:
        break;
    }
  };

  useEffect(() => {
    setTypesFilter(
      vaccineTypes.length
        ? {
            _has_keys_any: vaccineTypes,
          }
        : {}
    );
  }, [vaccineTypes]);

  return (
    <MainLayout
      path={[
        { text: 'Practices', to: '/practices' },
        { text: practice.name, to: `/practices/${practice.handler}` },
        { text: 'Inventory' },
      ]}
    >
      <Container fluid>
        <Menu borderless>
          <Menu.Item>
            <Input
              value={searchQuery}
              onChange={(_, { value }) => setSearchQuery(value)}
              icon="search"
              placeholder="Search..."
              data-automation-id="inventory-search"
            />
          </Menu.Item>
          <Menu.Item position="right">
            <Header>
              <Dropdown text="Actions" icon="dropdown" disabled={loading}>
                <Dropdown.Menu>
                  <InventoryFormModal
                    inventoryId={inventoryId}
                    onSave={() => refetch()}
                  >
                    <Dropdown.Item content="Add Entry" icon="box" />
                  </InventoryFormModal>
                  <InventoryBatchModal onSave={() => refetch()} />
                  <Dropdown.Item
                    icon="list"
                    content="Thresholds"
                    as={Link}
                    to={`/practices/${practice.handler}/inventory/thresholds`}
                  />
                  <Dropdown.Item
                    icon="boxes"
                    content="Inventory Control"
                    as={Link}
                    to={`/practices/${practice.handler}/inventory/control/${practice.id}`}
                  />
                  <Dropdown.Item
                    icon="list"
                    content="Inventory Adjustments"
                    as={Link}
                    to={`/practices/${practice.handler}/inventory/adjustments/${practice.id}`}
                  />
                  <Dropdown.Item
                    icon="dollar"
                    content="Contract Plans"
                    as={Link}
                    to={`/practices/${practice.handler}/inventory/contract-plans`}
                  />
                  <Dropdown.Item
                    icon="file excel"
                    onClick={handleLoadXlsx}
                    content="Export Xlsx"
                  />
                  <Dropdown.Item
                    icon="file excel"
                    content="Compare with EMR"
                    as={Link}
                    to={`/practices/${practice.handler}/inventory/compare`}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </Header>
          </Menu.Item>
        </Menu>
        <Table celled structured selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  Vaccine
                  <FilterPopup
                    header="Filter by Vaccine Types"
                    active={!!vaccineTypes.length}
                  >
                    <VaccineTypesPicker
                      placeholder="All Vaccine Types"
                      multiple
                      value={vaccineTypes}
                      onChange={handleVaccineTypesChange}
                    />
                  </FilterPopup>
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell>Lot</Table.HeaderCell>
              <Table.HeaderCell>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  VFC
                  <FilterPopup
                    header="Filter by VFC Status"
                    active={vfcValue !== 'all'}
                  >
                    <Dropdown
                      onChange={(_, { value }) => {
                        handleVfcValue(value as string);
                        setVfcValue(value as string);
                      }}
                      placeholder="VFC status"
                      selection
                      value={vfcValue}
                      options={[
                        { text: 'All', value: 'all', key: 'all' },
                        { text: 'VFC', value: 'vfc', key: 'vfc' },
                        { text: 'Private', value: 'private', key: 'private' },
                      ]}
                    />
                  </FilterPopup>
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell>Ordered</Table.HeaderCell>
              <Table.HeaderCell>Vaccinations</Table.HeaderCell>
              <Table.HeaderCell>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  Doses
                  <FilterPopup
                    header="Filter by Doses Threshold"
                    active={!!dosesThreshold}
                  >
                    <ThresholdInput
                      defaultOperator={ThresholdOperator._lte}
                      value={dosesThreshold}
                      onChange={handleDosesThresholdInputChange}
                      clearable
                    />
                  </FilterPopup>
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell>Expiration</Table.HeaderCell>
              <Table.HeaderCell>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  Status
                  <FilterPopup
                    header="Filter by Status"
                    active={!!statuses.length}
                  >
                    <InventoryStatusPicker
                      value={statuses}
                      onChange={({ statuses }) => setStatuses(statuses)}
                      placeholder="All statuses"
                      clearable
                      multiple
                    />
                  </FilterPopup>
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell>Logs</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data?.vaccines.map((v) => (
              <React.Fragment key={v.id}>
                <Table.Row>
                  <Table.Cell rowSpan={v.inventory.length + 1}>
                    <Header>
                      <Header.Content>
                        {v.name}
                        <Label size="mini">{v.saleNdc}</Label>
                        {v.types?.map((t) => (
                          <Label key={t} size="mini" color="orange">
                            {t}
                          </Label>
                        ))}
                      </Header.Content>
                      <Header.Subheader>{v.manufacturer}</Header.Subheader>
                    </Header>
                  </Table.Cell>
                </Table.Row>
                {v.inventory.map((i) => {
                  const monthsDiff = moment(i.expiration).diff(
                    new Date(),
                    'months'
                  );
                  const notRetired = i.status !== InventoryStatus.retired;
                  return (
                    <Table.Row key={i.id} textAlign="center">
                      <Table.Cell>{i.lot}</Table.Cell>
                      <Table.Cell>
                        {i.vfc ? (
                          <Label color="orange" content="VFC" />
                        ) : (
                          <Label color="teal" content="Private" />
                        )}
                      </Table.Cell>
                      <Table.Cell>{i.orderedDoses}</Table.Cell>
                      <Table.Cell>{i.vaccinationsCount}</Table.Cell>
                      <Table.Cell
                        warning={notRetired && i.doses < 10 && i.doses > 4}
                        error={notRetired && i.doses < 5}
                      >
                        {notRetired && i.doses < 5 && <Icon name="attention" />}
                        {i.doses}
                      </Table.Cell>
                      <Table.Cell
                        warning={notRetired && monthsDiff < 3 && monthsDiff > 1}
                        error={notRetired && monthsDiff < 1}
                      >
                        {notRetired && monthsDiff < 1 && (
                          <Icon name="attention" />
                        )}
                        {i.expiration} (<Moment fromNow>{i.expiration}</Moment>)
                      </Table.Cell>
                      <Table.Cell>{i.status}</Table.Cell>
                      <Table.Cell>
                        <InventoryStatusLog
                          data={i.statusLog}
                          loading={loading}
                          position="left center"
                        />
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        <div style={{ display: 'flex' }}>
                          <Popup
                            content="Edit"
                            trigger={
                              <Button
                                as={Link}
                                to={`/practices/${practice.handler}/inventory/${i.id}`}
                                icon="edit"
                                color="teal"
                                size="tiny"
                              />
                            }
                          />

                          <Popup
                            content="Edit History"
                            trigger={
                              <Button
                                icon="retweet"
                                color="teal"
                                size="tiny"
                                onClick={() => {
                                  setInventorySelected({
                                    inventory: i,
                                    vaccine: v,
                                  });
                                }}
                              />
                            }
                          />
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </React.Fragment>
            ))}
          </Table.Body>
        </Table>
      </Container>
      {inventorySelected && (
        <AdjustmentDetails
          data={inventorySelected}
          onModalClose={() => setInventorySelected(undefined)}
        />
      )}
    </MainLayout>
  );
};

export default InventoryScreen;
