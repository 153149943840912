import { useCallback, useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';

import GS1 from '@bluefox/lib/gs1';
import { Inventory, InventoryStatus } from '@bluefox/models/Inventory';
import { usePractice } from '@bluefox/contexts';

const InventoryQuery = gql`
  query InventoryQuery(
    $practiceId: uuid!
    $inventoryStatuses: [inventory_statuses_enum!]
    $inventoryCriterias: [inventories_bool_exp!]!
    $vaccinesCriterias: [vaccines_bool_exp!]!
  ) {
    inventories(
      where: {
        practiceId: { _eq: $practiceId }
        status: { _in: $inventoryStatuses }
        _or: $inventoryCriterias
      }
      order_by: { vaccine: { name: asc } }
    ) {
      id
      lot
      expiration
      doses
      vaccineId
      status
      vfc
      vaccine {
        id
        name
        saleNdc
        manufacturer
        aka
      }
    }
    vaccines(where: { _or: $vaccinesCriterias }) {
      id
      name
      saleNdc
      manufacturer
      aka
    }
  }
`;

export interface InventoryEntry {
  code: string;
  gs1: GS1;
  inventory: Inventory;
}

interface InventoryData {
  inventories: Inventory[];
}

class InventoryCriteria {
  lot: string;
  expiration: Date;
  vfc: boolean;
  code?: string;

  constructor(
    lot: string,
    expiration: string | Date,
    vfc: boolean,
    code?: string
  ) {
    this.lot = lot;
    this.expiration =
      expiration instanceof Date ? expiration : new Date(expiration);
    this.vfc = vfc;
    this.code = code;
  }

  getId() {
    return `${this.lot}-${this.expiration.toISOString().slice(0, 10)}-${
      this.vfc
    }`;
  }

  getGql() {
    return {
      lot: { _eq: this.lot },
      expiration: { _eq: this.expiration.toISOString() },
      vfc: { _eq: this.vfc },
    };
  }

  static fromCode(code: string, vfc: boolean) {
    const gs1 = new GS1(code);
    const lot = gs1.getLot();
    const exp = gs1.getExp();
    return lot && exp ? new InventoryCriteria(lot, exp, vfc, code) : undefined;
  }
}

class VaccineCriteria {
  code?: string;
  saleNdc10: string;

  constructor(saleNdc10: string, code?: string) {
    this.saleNdc10 = saleNdc10;
    this.code = code;
  }

  getId() {
    return this.saleNdc10;
  }

  getGql() {
    return {
      _or: [
        { saleNdc10: { _eq: this.saleNdc10 } },
        { useNdc10: { _eq: this.saleNdc10 } },
      ],
    };
  }

  static fromCode(code: string) {
    const gs1 = new GS1(code);
    const saleNdc10 = gs1.getNdc();
    return saleNdc10 ? new VaccineCriteria(saleNdc10, code) : undefined;
  }
}

interface UseInventoryOptions {
  vfc?: boolean;
  inventoryStatuses?: InventoryStatus[];
}

export const useInventory = ({
  vfc,
  inventoryStatuses = [
    InventoryStatus.ordered,
    InventoryStatus.confirmed,
    InventoryStatus.shipped,
    InventoryStatus.received,
    // InventoryStatus.retired
  ],
}: UseInventoryOptions = {}) => {
  const practice = usePractice();

  const [inventoryCriterias, setInventoryCriterias] = useState<{
    [key: string]: InventoryCriteria;
  }>({});
  const [vaccinesCriterias, setVaccinesCriterias] = useState<{
    [key: string]: VaccineCriteria;
  }>({});

  const [entriesRecord, setEntriesRecord] = useState<
    Record<string, InventoryEntry>
  >({});

  const [entries, setEntries] = useState<InventoryEntry[]>([]);

  const addEntry = useCallback(
    (code) => {
      if (vfc === undefined) {
        const vfcCriteria = InventoryCriteria.fromCode(code, true);
        const nonVfcCriteria = InventoryCriteria.fromCode(code, false);
        const _criterias = {
          ...inventoryCriterias,
          ...(vfcCriteria ? { [vfcCriteria?.getId()]: vfcCriteria } : {}),
          ...(nonVfcCriteria
            ? { [nonVfcCriteria?.getId()]: nonVfcCriteria }
            : {}),
        };
        setInventoryCriterias(_criterias);
      } else {
        const criteria = InventoryCriteria.fromCode(code, vfc);
        if (criteria) {
          setInventoryCriterias({
            ...inventoryCriterias,
            [criteria.getId()]: criteria,
          });
        }
      }
      const vaccineCriteria = VaccineCriteria.fromCode(code);
      if (vaccineCriteria) {
        setVaccinesCriterias({
          ...vaccinesCriterias,
          [vaccineCriteria.getId()]: vaccineCriteria,
        });
      }
    },
    [inventoryCriterias, vfc, vaccinesCriterias]
  );

  const removeCriteria = useCallback(
    (id: string) => {
      if (id in inventoryCriterias) {
        const _criterias = { ...inventoryCriterias };
        delete _criterias[id];
        setInventoryCriterias(_criterias);
      }
    },
    [inventoryCriterias]
  );

  const removeEntry = useCallback(
    (inventoryEntry: InventoryEntry) => {
      const { inventory } = inventoryEntry;
      const criteria = new InventoryCriteria(
        inventory.lot,
        inventory.expiration,
        inventory.vfc
      );
      const _entriesRecord = { ...entriesRecord };

      delete _entriesRecord[inventory.id];
      setEntriesRecord(_entriesRecord);

      removeCriteria(criteria.getId());
    },
    [entriesRecord]
  );

  const cleanEntries = useCallback(() => {
    setInventoryCriterias({});
    setEntriesRecord({});
  }, []);

  const { data, loading, error } = useQuery<InventoryData>(InventoryQuery, {
    variables: {
      practiceId: practice.id,
      inventoryStatuses,
      inventoryCriterias: Object.values(inventoryCriterias).map((c) =>
        c.getGql()
      ),
      vaccinesCriterias: Object.values(vaccinesCriterias).map((v) =>
        v.getGql()
      ),
    },
    skip:
      Object.keys(inventoryCriterias).length === 0 &&
      Object.keys(vaccinesCriterias).length === 0,
  });

  useEffect(() => {
    if (!data || !data?.inventories.length) {
      return;
    }

    const _entries = data.inventories.reduce((acc, inventory) => {
      const criteria = new InventoryCriteria(
        inventory.lot,
        inventory.expiration,
        inventory.vfc
      );

      const code = inventoryCriterias[criteria.getId()]?.code ?? '';

      return {
        ...acc,
        [inventory.id]: {
          inventory,
          code,
          gs1: new GS1(code),
        },
      };
    }, {});

    setEntriesRecord(_entries);
  }, [data]);

  useEffect(() => {
    // prevent infinite loop
    if (
      JSON.stringify(Object.values(entriesRecord)) !== JSON.stringify(entries)
    ) {
      setEntries(Object.values(entriesRecord) as InventoryEntry[]);
    }
  }, [entriesRecord, entries]);

  return {
    addEntry,
    removeEntry,
    entries,
    entriesRecord,
    cleanEntries,
    fetching: loading,
    error,
  };
};
