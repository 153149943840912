import { CptCode } from './Billing';
import { Practice } from '@bluefox/models/Practice';

import { Organization } from './Organization';
/**
 * @deprecated Deprecated in favor of the new Invoice model.
 */
export interface Inovice {
  id: string;
  practiceId: string;
  status: InvoiceStatus;
  paymentDate?: string;
  Practice: Practice;
  claimCptCodes: CptCode;
  billMethod: 'manual' | 'auto';
  amount: number;
  date: Date;
  totalPrivateAdminAmount?: number;
  totalVfcAdminAmount?: number;
}

export enum InvoiceStatus {
  pending = 'pending',
  paid = 'paid',
  error = 'error',
  error_stripe = 'error_stripe',
  processing = 'processing',
  discarded = 'discarded',
  draft = 'draft',
}

export type ClaimTableItemResponse = {
  key: string;
  patient: {
    name: string;
    dateOfBirth: string;
  };
  insurance: {
    name: string;
    memberId: string;
  };
  dateOfService: string;
  cptCodes: string[];
  paidAmount: number;
  eobLink?: string;
};

export type ClaimTableResponse = {
  key: string;
  practice: string;
  table: ClaimTableItemResponse[];
  totalAmount: number;
};

export type FooterProps = {
  logoImg?: string;
};

export type BillingReportResponse = {
  claimTables: ClaimTableResponse[];
  footer?: FooterProps;
};

// INVOICE PDF TYPES

export type InvoiceHeaderResponse = {
  logoImg?: string;
  clientLine1: string;
  clientLine2?: string;
  date: string;
  amountDue: number;
};

export type ItemsByPracticesResponse = {
  key: string;
  practice: string;
  table: InvoiceItemsResponse[];
  totalAmount: number;
};

export type InvoiceItemsResponse = {
  key: string;
  quantity: number;
  vaccineName: string;
  cptCode: string;
  amount: number;
};
export type DiscountResponse = {
  discountPercentage: number;
  discountAmount: number;
};

export type FeeResponse = {
  feeAmount: number;
};

export type ExtraItemResponse = {
  key: string;
  practice: string;
  quantity: number;
  description: string;
  unitPrice: number;
  subtotal: number;
};

export type ExtrasItemsResponse = {
  table: ExtraItemResponse[];
  totalAmount: number;
};

export type InvoiceBodyResponse = {
  claimTables: ItemsByPracticesResponse[];
  totalClaims: number;
  discount?: DiscountResponse;
  fee?: FeeResponse;
  extraTable?: ExtrasItemsResponse;
  totalInvoice: number;
};

export type InvoiceFooterResponse = {
  pcAdminFeeClaimed: number;
  vcfAdminFeeClaimed: number;
  timeRestored: number;
};

// INVOICE PDF TYPES

// BILLING REPORT PDF TYPES

// BILLING REPORT PDF TYPES

export type InvoiceV2Response = {
  header: InvoiceHeaderResponse;
  body: InvoiceBodyResponse;
  footer?: InvoiceFooterResponse;
};

export interface Invoice {
  id?: string;
  organizationId: string;
  status: InvoiceStatus;
  paymentDate?: string;
  description?: string;
  practice?: Practice;
  practiceId?: string;
  organization?: Organization;
  claimCptCodes?: CptCode[];
  billMethod?: 'manual' | 'auto';
  amount: number;
  date: Date;
  currentOrganizationCredit?: number;
  totalPrivateAdminAmount?: number;
  totalVfcAdminAmount?: number;
  pdfData?: {
    billingReport: BillingReportResponse;
    invoice: InvoiceV2Response;
  };
}
